import styled from 'styled-components';

export const Container = styled.div`
  width: 33rem;
  height: 20rem;
  padding: 1.4rem 3rem 2.5rem 2.5rem;
  border-radius: 0.4rem;
  margin: 1rem 0;

  @media screen and (min-width: 600px) {
    margin: 1rem;
  }
`;

export const ItemContainer = styled(Container)`
  width: 330px;
  padding: 1.4rem 2rem 2.3rem 2.5rem;
  border-radius: 0.4rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.13);
  margin: 1rem 0;
  position: relative;
  transition: all 0.125s ease-in-out;
  z-index: 1;
  cursor: pointer;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    border: 1.5px solid rgb(183, 153, 229);
    border-radius: 0 0 0.4rem 0.4rem;
    z-index: 0;
    background-color: rgb(183, 153, 229);
  }

  &:hover {
    cursor: pointer;
    /* box-shadow: 0 0px 2px 1px rgba(183, 153, 229, 1); */
  }

  &:hover::after {
    height: 6px;
  }
`;

export const ItemLink = styled.a`
  color: initial;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:visited {
    color: initial;
  }
`;

export const DateText = styled.header`
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #777;
  letter-spacing: -0.22px;
  text-transform: uppercase;
`;

export const TitleText = styled.h4`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: normal;
  margin: 0.75rem 1rem 0 0;
  color: #222;
  letter-spacing: -0.12px;
`;

export const Footer = styled.footer`
  margin-top: 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.2rem;
`;

export const SourceName = styled.div`
  font-size: 1.8rem;
  line-height: 2.1rem;
  letter-spacing: -0.33px;
  color: #777;
`;

export const SourceLogo = styled.img`
  max-width: 5rem;
`;

export const MoreContainer = styled(Container)`
  border-radius: 0.4rem;
  border: 2.8px solid #6f49f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.125s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const MoreText = styled.div`
  font-size: 2rem;
  line-height: 1.5rem;
  letter-spacing: -0.12px;
  color: #6841fa;
`;
