import * as React from "react";
const NoiseSuspensionIcon = (props) => (
  <svg
    width={30}
    height={36}
    viewBox='0 0 30 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.5404 19.557C25.0334 20.5909 25.7111 22 26.8626 22C27.3336 22 27.7745 21.7562 27.997 21.3411C29.0894 19.3033 29.709 16.974 29.709 14.5C29.709 6.49187 23.2172 0 15.209 0C7.20091 0 0.709039 6.49187 0.709039 14.5C0.709039 16.974 1.32863 19.3033 2.42111 21.3411C2.6436 21.7562 3.08453 22 3.55543 22C4.70695 22 5.38472 20.5909 4.8777 19.557C4.12926 18.0308 3.70904 16.3145 3.70904 14.5C3.70904 8.14873 8.85776 3 15.209 3C21.5603 3 26.709 8.14873 26.709 14.5C26.709 16.3145 26.2888 18.0308 25.5404 19.557ZM15.209 19C17.6943 19 19.709 16.9853 19.709 14.5C19.709 12.0147 17.6943 10 15.209 10C12.7238 10 10.709 12.0147 10.709 14.5C10.709 16.9853 12.7238 19 15.209 19ZM15.209 22C19.3512 22 22.709 18.6421 22.709 14.5C22.709 10.3579 19.3512 7 15.209 7C11.0669 7 7.70904 10.3579 7.70904 14.5C7.70904 18.6421 11.0669 22 15.209 22ZM26.2261 33C24.7827 28.5805 20.2317 25 14.7092 25C9.18662 25 4.63543 28.5805 3.19198 33H26.2261ZM2.70895 33L2.70584 32.9999C2.70499 32.9998 2.70458 32.9997 2.7046 32.9996L2.70526 32.9995C2.70596 32.9995 2.70719 32.9997 2.70895 33ZM29.3509 33.0281C29.7423 34.6381 28.3659 36 26.709 36H2.70904C1.05219 36 -0.324246 34.6381 0.0671713 33.0281C1.55997 26.888 7.54463 22 14.7092 22C21.8737 22 27.8582 26.888 29.3509 33.0281Z'
      fill='url(#paint0_linear_3500_303)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3500_303'
        x1={17.9111}
        y1={12.5887}
        x2={1.0036}
        y2={25.1946}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
    </defs>
  </svg>
);
export default NoiseSuspensionIcon;
