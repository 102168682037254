import React, { useRef, useEffect } from 'react';
import { MainImageAndTextContainer, VideoContainer } from './styles';
// import FeatureParallaxImages from '../Parallax';
// import TransitionContainer from '../../../Shared/TransitionContainer';

import { BASE_IMG_URL } from '../../../Shared/Config';
import SeenInContainer from '../SeenInContainer';

const landingUrl = `${BASE_IMG_URL}/HomeBanner.mp4`;

const MainImageContainer = ({
  distFromTop,
  x,
  y,
  containerRef,
  firstEntered
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const handler = () => {
      ref && ref.current && ref.current.play();
    };
    ref && ref.current.addEventListener('canplaythrough', handler);
  }, [ref]);

  return (
    <MainImageAndTextContainer ref={containerRef}>
      {/* <TransitionContainer>
        <FeatureParallaxImages
          distFromTop={distFromTop}
          x={x}
          y={y}
          firstEntered={firstEntered}
        />
      </TransitionContainer> */}
      <VideoContainer ref={ref} autoplay muted src={landingUrl} />
      <SeenInContainer />
    </MainImageAndTextContainer>
  );
};

export default MainImageContainer;
