import styled from 'styled-components';

export const NumberList = styled.ol`
  list-style-type: decimal;
`;

export const AlphaList = styled.ol`
  list-style-type: lower-alpha;
`;

export const RomanList = styled.ol`
  list-style-type: lower-roman;
`;
