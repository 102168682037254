import styled from 'styled-components';
import BottomBanner from '../../../../BottomBanner';
import { Roboto } from '../../../../Shared/fonts';

export const LeadershipBottomBanner = styled(BottomBanner)`
  background-color: rgb(250, 249, 254);
  justify-content: center;
  height: 30rem;

  .bottom-banner-title {
    color: black;
  }

  .bottom-banner-text {
    margin-top: 2rem;
    color: #282626;
    font: 300 1.8rem ${Roboto};
  }

  .bottom-banner-button {
    border: 1px solid #7b47ef;
    height: 5rem;
    width: 22rem;
    margin-top: 5rem;
    color: #464547;
    font-size: 2rem;
    letter-spacing: -0.35px;
    line-height: 2.4rem;
    background-color: white;
    color: #464547;
    font: 500 1.8rem ${Roboto};
    &:hover {
      background: #7b47ef;
      color: white;
    }
  }
`;
