const base64 = require('base-64');

export const encodeToBase64 = text => {
  const encoded = base64.encode(text);
  return encoded || '';
};

export const decodeFromBase64 = base64String => {
  const decoded = base64.decode(base64String);
  return decoded || '';
};
