import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const SubHeading = styled.div`
  color: #252525;
  font: 1.6rem ${Roboto};
  letter-spacing: 0;
  line-height: 25px;
  padding-left: 10px;
  text-align: none;
`;

export const GetStartedList = styled.ul``;

export const GetStartedTitle = styled.h4`
  color: #252525;
  font: 500 2rem ${Roboto};
  letter-spacing: 0;
  line-height: 29px;
  position: relative;
  filter: saturate(0.5);
  margin: 0;
`;
