import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Roboto } from '../Shared/fonts';

export const FooterContainer = styled.div`
  border-top: 1px solid #d0d0d0;
  padding: 2rem;
  background-color: rgb(245 243 249);
  flex: 1;
  @media screen and (min-width: 800px) {
    padding: 2rem 12rem;
  }
`;

export const FooterDataContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  @media screen and (min-width: 800px) {
    justify-content: space-between;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InidividualFooterLinkColumn = styled.div`
  display: flex;
  overflow-wrap: break-word;
  margin: 0 3rem 3rem 0;
`;

export const FooterDataTitle = styled.div`
  font: 1.2rem ${Roboto};
  color: #0f0f0f;
  margin-bottom: 1rem;
`;

export const FooterLink = styled(Link)`
  && {
    font: 300 1.2rem ${Roboto};
    text-decoration: none;
    color: #0f0f0f;
    margin-bottom: 1rem;
    padding: 1rem;
  }
`;

export const FooterLogoContainer = styled.div`
  width: 100px;
`;

export const FooterLogo = styled.img`
  height: 7rem;
  width: 7rem;
`;

export const FooterBrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 800px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const AllRightsText = styled.div`
  font: 300 1.2rem ${Roboto};
  color: #0f0f0f;
  margin-top: 1.3rem;
`;
