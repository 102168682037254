const numbersData = [
  {
    key: 'global-access-number-1',
    country: 'Brazil',
    number: '+552135002499',
    dialIn: '+55 21 3500 2499',
    vanity: ''
  },
  {
    key: 'global-access-number-2',
    country: 'India (Toll-free)',
    number: '18005994399',
    dialIn: '1800-599-4399',
    vanity: ''
  },
  {
    key: 'global-access-number-4',
    country: 'Israel',
    number: '+972553390220',
    dialIn: '+972 5533 90220',
    vanity: ''
  },
  {
    key: 'global-access-number-5',
    country: 'Spain',
    number: '+34876148335',
    dialIn: '+34 876 148 335',
    vanity: ''
  },
  {
    key: 'global-access-number-6',
    country: 'United States ',
    number: '+16812188335',
    dialIn: '+1-681-218-8335',
    vanity: ''
  },
  {
    key: 'global-access-number-7',
    country: 'United States ',
    number: '+16697709390',
    dialIn: '+1-669-770-9390',
    vanity: ''
  },
  {
    key: 'global-access-number-8',
    country: 'United States (Toll-free)',
    number: '18444648335',
    dialIn: '1-844-GOHUDDL',
    vanity: ''
  },
  {
    key: 'global-access-number-9',
    country: 'United States (Toll-free)',
    number: '18882377578',
    dialIn: '1-888-237-7578',
    vanity: ''
  },
  {
    key: 'global-access-number-10',
    country: 'UK ',
    number: '+447520649335',
    dialIn: '+44 752 064 9335',
    vanity: ''
  },
  {
    key: 'global-access-number-11',
    country: 'Australia',
    number: '+61488884458',
    dialIn: '+61 488 884 458',
    vanity: ''
  }
];
export default numbersData;
