import styled from 'styled-components';
import Banner from '../../Banner';
import { Roboto } from '../../Shared/fonts';
import { huddlBrandPurple, huddlGray } from '../../Shared/colors';

export const MobileJoinBanner = styled(Banner)`
  height: 100%;
  .banner-circle {
    display: none;
  }
`;

export const MobileDescription = styled.div`
  font: 300 1.2rem ${Roboto};
  text-align: center;
  color: ${huddlGray};
  margin-top: 2rem;
`;

export const IconContainer = styled.div`
  margin-top: 0.67rem;
  &:hover {
    cursor: pointer;
  }
`;

export const OneTapJoin = styled.a`
  margin-top: 0.67rem;
  font: 300 1.4rem ${Roboto};
  color: ${huddlBrandPurple};
  &:hover,
  &:focus {
    color: ${huddlBrandPurple};
  }
`;

export const OpenInAppContainer = styled.a`
  margin-top: 0.67rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${huddlBrandPurple};
  text-decoration: none;
  min-width: 132px;
  padding: 0.5rem;
  background-color: white;
  color: ${huddlGray};
  font-size: 1.5rem;
  & svg {
    margin-right: 0.5rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const MobilePhoneImage = styled.img`
  width: 75%;
  max-width: 35rem;
  margin-top: 5rem;
`;
