import React from 'react';
import CameraIcon from "../../Shared/Icons/Platform/camera"
import CalendarIcon from '../../Shared/Icons/Platform/calendar';
import ChatIcon from '../../Shared/Icons/Platform/chat';
import SpeechToTextIcon from '../../Shared/Icons/Platform/speechToText';
import NoiseSuspensionIcon from '../../Shared/Icons/Platform/noiseSuspension';
import SpeakerDetectionIcon from '../../Shared/Icons/Platform/speakerDetection';
import DiarizationIcon from '../../Shared/Icons/Platform/diarization';
import EmotionIcon from '../../Shared/Icons/Platform/emotion';
import SummarizeIcon from '../../Shared/Icons/Platform/Summarization';
import ConversionalIcon from '../../Shared/Icons/Platform/conversional';
import SpeechNlpModelIcon from '../../Shared/Icons/Platform/speechNlpModel';

export const Capabilities = [
  {
    title: 'RTC Capabilities',
    description: 'The RTC capabilities offered by the platform are perfect for businesses that need to connect with remote workers, communicate with customers in different time zones, or provide a more immersive user experience. ',
  },
  {
    title: 'AI Capabilities',
    description: 'The AI capabilities are designed to help automate routine tasks, make better data-driven decisions, and provide a more personalized experience for users.',
  }
]

export const RtcCapabilities = [
  {
    title: 'Audio/Video communication stack',
    description: 'Provides a platform that can be integrated directly and can be used for streaming, meetings, virtual conferences, and virtual events.',
    icon: <CameraIcon/>
  },
  {
    title: 'Cloud calendaring and scheduling',
    description: 'Provides a streamlined scheduling service by offering 2-way calendar sync and smart features like free/busy schedule checker and many more.',
    icon: <CalendarIcon/>
  },
  {
    title: 'Messaging and chat',
    description: 'Provides integrated services for 1 to 1 messaging, group messaging, chat and SMS support as well as a chatbot framework',
    icon: <ChatIcon/>
  }
]

export const aaiCapabilities = [
  {
    title: 'Speech-to-text',
    description: 'The speech is transcribed both live as well as async recordings which are converted into smart insights.',
    icon: <SpeechToTextIcon/>
  },
  {
    title: 'Noise Suppression',
    description: `The speaker's voice is smartly separated from the background noise and the noise is suppressed for clear vocal transmission.`,
    icon: <NoiseSuspensionIcon/>
  },
  {
    title: 'SpeakerID detection',
    description: 'Tracking the speaker ID helps in keeping the audio tracks separate and generates data for specific speakers.',
    icon: <SpeakerDetectionIcon/>
  },
  {
    title: 'Diarization',
    description: `Individual speakers are separated in an audio stream with the help of an automatic speech recognition (ASR) transcript.`,
    icon: <DiarizationIcon/>
  },
  {
    title: 'Emotion detection',
    description: `Detection of the speaker's emotion helps the AI understand the urgency of the discussion`,
    icon: <EmotionIcon/>
  }

]
export const conversionalCapabilities = [
  {
    title: 'Summarization model',
    description: `Helps in summarising meeting transcription using AI to understand different entities, the intents, and the context of the conversation.`,
    icon: <SummarizeIcon/>
  },
  {
    title: 'Conversational Inference models',
    description: `Use of AI to understand user needs with conversations with the users asking them for their requirements.`,
    icon: <ConversionalIcon/>
  },
  {
    title: 'Speech & text based NLP models',
    description: 'The trained model for processing natural language to present consumable data by learning and monitoring potential biases and errors.',
    icon: <SpeechNlpModelIcon/>
  }

]