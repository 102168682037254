import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const FormTextArea = styled.textarea`
  height: 10rem;
  padding: 1rem;
  outline: none;
  border-radius: 3px;
  font: 300 1.5rem ${Roboto};
  color: #535353;
  border: 1px solid #d1d1d1;
  margin-top: 2rem;
  resize: none;
  width: 100%;
  
  ::placeholder {
    overflow-wrap: normal;
  }
`;
