import {
  // RESOURCES_ROUTE,
  ABOUT_US_ROUTE,
  // DEMO_ROUTE,
  SIGN_IN_ROUTE,
  CAREER_ROUTE,
  PRESS_ROUTE,
  BLOG_ROUTE,
  INTEGRATIONS_ROUTE,
  PLATFORM_ROUTE,
  PRODUCT_ROUTE
} from '../../../constants/routes';

export const NavLinksData = {
  main: [

    {
      key: 'product',
      label: 'Product',
      to: `/${PRODUCT_ROUTE}`
    },

    {
      key: 'platform',
      label: 'Platform',
      to: `/${PLATFORM_ROUTE}`
    },
    {
      key: 'integrations',
      label: 'integrations',
      to: `/${INTEGRATIONS_ROUTE}`
    },
    {
      key: 'about',
      label: 'About Us',
      to: `/${ABOUT_US_ROUTE}`
    },
    // {
    //   key: 'resources',
    //   label: 'Resources',
    //   to: `/${RESOURCES_ROUTE}`
    // },
    {
      key: 'careers',
      label: 'Careers',
      to: `/${CAREER_ROUTE}`
    },
    {
      key: 'press',
      label: 'Press',
      to: `/${PRESS_ROUTE}`
    },
    
  
    {
      key: 'blog',
      label: 'Blog',
      to: `/${BLOG_ROUTE}`
    }

    // {
    //   key: 'demo',
    //   label: 'Demo',
    //   to: `/${DEMO_ROUTE}`
    // }
  ],
  careers: [
    {
      key: 'All Jobs',
      label: 'All Jobs',
      to: `/${CAREER_ROUTE}`
    }
  ]
};

export const SignInLinkData = {
  key: 'sign-in',
  label: loggedIn => (loggedIn ? 'Sign Out' : 'Sign In'),
  to: `/${SIGN_IN_ROUTE}`
};

export const externalNavigationLinks = [];
// export const externalNavigationLinks = [
//   {
//     key: 'web-join-meeting',
//     label: 'Join a Meeting',
//     to: `https://web.gohuddl.com/login?join_with=url&redirected_from=web`,
//     isButtonStyle: false
//   },
//   {
//     key: 'web-sign-in',
//     label: 'Sign In',
//     to: `https://web.gohuddl.com/login?join_with=email&redirected_from=web`,
//     isButtonStyle: false
//   },
//   {
//     key: 'web-try-for-free',
//     label: 'Try for free',
//     to: `https://web.gohuddl.com/login?try_for_free=signup&redirected_from=web`,
//     isButtonStyle: true
//   }
// ];
