import React from 'react';
import {
  Body,
  GetStartedWrapper,
  GetStartedListItem,
  GetStartedInner,
  GettingStartedTitle,
  GettingStartedDesc,
  CircleDiv
} from '../../Zoom/ZoomDocs/styles';
import { SubHeading, GetStartedList, GetStartedTitle } from './styles';
import botList from './botList';

const SlackBot = ({ isIntegration }) => {
  return (
    <Body isIntegration={isIntegration}>
      <GetStartedWrapper isIntegration={isIntegration}>
        {isIntegration ? (
          <>
            <GettingStartedTitle>Why add the huddl.ai bot?</GettingStartedTitle>
            <GettingStartedDesc>
              huddl.ai bot sends periodic and relevant updates before the
              meeting on the selected Slack channel of your choice. The
              participants in the selected channel receive reminders before the
              meeting to prepare for it.
            </GettingStartedDesc>
          </>
        ) : null}
        {botList.map(({ heading, subHeading = '', list, image }, index) => (
          <GetStartedInner key={`docsList_${index}`}>
            <GetStartedTitle>
              <CircleDiv />
              {heading}
              <SubHeading>{subHeading}</SubHeading>
            </GetStartedTitle>
            <GetStartedList>
              {list.map((point, inx) => (
                <GetStartedListItem
                  key={`listem_item_${inx}`}
                  dangerouslySetInnerHTML={{ __html: point }}
                />
              ))}
            </GetStartedList>
            {image.length > 0 &&
              image.map(item => {
                return <img key={`${item}`} alt="slack" src={item} />;
              })}
          </GetStartedInner>
        ))}
      </GetStartedWrapper>
    </Body>
  );
};

export default SlackBot;
