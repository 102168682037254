import React from 'react';
import { BASE_IMG_URL } from '../../../Shared/Config';
import {
  Body,
  GetStartedWrapper,
  GetStartedTitle,
  GetStartedList,
  GetStartedListItem,
  GetStartedInner,
  GettingStartedTitle,
  GettingStartedDesc,
  CircleDiv,
  SubSection,
  SubTitle,
  SubCircleDiv,
  VideoText,
  SubListContainer,
  SubHeadingTitle
} from '../../Zoom/ZoomDocs/styles';
import docsList from '../docsList';

const ServiceNowDocs = ({ isIntegration }) => {
  const sNowVideo1 = `${BASE_IMG_URL}/ServiceNowOne.mp4`;
  const sNowVideo2 = `${BASE_IMG_URL}/ServiceNowTwoV2.mp4`;
  return (
    <Body isIntegration={isIntegration}>
      <GetStartedWrapper isIntegration={isIntegration}>
        {isIntegration ? (
          <>
            <GettingStartedTitle>Getting Started</GettingStartedTitle>
            <GettingStartedDesc>
              An overview about how huddl.ai works and how to get started
            </GettingStartedDesc>
          </>
        ) : null}
        {docsList.map(({ heading, list }, index) => (
          <GetStartedInner key={`docsList_${index}`}>
            <GetStartedTitle>
              <CircleDiv />
              {heading}
            </GetStartedTitle>
            <GetStartedList>
              {list.map(({ title, subSections }, inx) => (
                <>
                  <SubHeadingTitle key={`listem_item_${inx}`}>
                    <CircleDiv />
                    {title}
                  </SubHeadingTitle>
                  {subSections && subSections.length ? (
                    <SubSection>
                      {subSections.map(
                        ({ subHeading, subList, filter, video }, sIndex) => (
                          <SubListContainer key={`Sublist-${sIndex}`}>
                            <SubTitle>
                              <SubCircleDiv />
                              {subHeading}
                            </SubTitle>
                            <GetStartedList>
                              {subList.map((subpoint, subInx) => (
                                <GetStartedListItem
                                  key={`sub_list_item_${subInx}`}
                                  dangerouslySetInnerHTML={{ __html: subpoint }}
                                />
                              ))}
                            </GetStartedList>
                            {filter === 'service' && (
                              <VideoText>
                                <i>"Still Confused?" watch video</i>
                                <video
                                  width="500"
                                  height="320"
                                  controls
                                  type="video/mp4"
                                  alt={`service now video ${index}`}
                                  src={video === 1 ? sNowVideo1 : sNowVideo2}
                                />
                              </VideoText>
                            )}
                          </SubListContainer>
                        )
                      )}
                    </SubSection>
                  ) : null}
                </>
              ))}
            </GetStartedList>
          </GetStartedInner>
        ))}
      </GetStartedWrapper>
    </Body>
  );
};

export default ServiceNowDocs;
