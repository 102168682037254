import React from 'react';
import { GalleryContainer } from './styles';
import IndividualGalleryContainer from './IndividualContainer';
import TransitionContainer from '../../Shared/TransitionContainer';

const Gallery = ({ data, className }) => (
  <GalleryContainer className={className}>
    {data.map(({ imageUrl, name, role, linkedIn }) => (
      <TransitionContainer key={name}>
        <IndividualGalleryContainer
          linkedIn={linkedIn}
          imageUrl={imageUrl}
          name={name}
          role={role}
        />
      </TransitionContainer>
    ))}
  </GalleryContainer>
);

export default Gallery;
