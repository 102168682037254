import * as React from 'react';

const LocationIcon = props => (
  <svg width={20} height={23} fill="none" {...props}>
    <path
      d="M8.889 11v5.666a1.111 1.111 0 1 0 2.222 0v-5.667a5.556 5.556 0 1 0-2.222 0ZM10 2.22a3.333 3.333 0 1 1 0 6.666 3.333 3.333 0 0 1 0-6.666Zm4.678 11.578a1.136 1.136 0 0 0-.467 2.222c2.522.5 3.567 1.4 3.567 1.755C17.778 18.421 15.055 20 10 20c-5.056 0-7.777-1.578-7.777-2.223 0-.355 1.044-1.255 3.566-1.8a1.135 1.135 0 0 0-.467-2.222C1.945 14.532 0 15.988 0 17.776c0 2.923 5.033 4.445 10 4.445s10-1.522 10-4.445c0-1.788-1.944-3.244-5.322-3.977Z"
      fill="gray"
    />
  </svg>
);

export default LocationIcon;
