import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font: 3rem ${Roboto};
  margin-bottom: 3rem;
  color: #252525;
  text-align: center;
  line-height: 2.5rem;
`;

export const Description = styled.div`
  font: 300 1.6rem ${Roboto};
  color: #252525;
  text-align: center;
  width: 75%;
  padding: 0 4rem 0 4rem;
  line-height: 2.5rem;
  @media screen and (min-width: 800px) {
    width: 75%;
  }
  @media screen and (min-width: 1100px) {
    width: 50%;
  }
`;
