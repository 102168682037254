import React from 'react';
import {
  IndividualContainer,
  GalleryImageLink,
  GalleryImage,
  Icon,
  GalleryTextContainer,
  NameText,
  RoleText
} from './styles';

const IndividualGalleryContainer = ({
  linkedIn,
  imageUrl,
  name,
  role,
  containerRef,
  ratio
}) => {
  const multiplier = Math.min(ratio * (ratio / 0.8), 1);
  const opacityAmount = multiplier * 1;
  const opacity = `${opacityAmount}`;
  return (
    <IndividualContainer
      style={{ opacity }}
      ref={containerRef}
      key={`${name}_i`}
    >
      <div style={{ position: 'relative' }}>
        <GalleryImageLink href={linkedIn} target="_blank">
          <GalleryImage src={imageUrl} />
          {linkedIn && <Icon className="fab fa-linkedin-in" />}
        </GalleryImageLink>
      </div>
      <GalleryTextContainer>
        <NameText>{name}</NameText>
        <RoleText>{role}</RoleText>
      </GalleryTextContainer>
    </IndividualContainer>
  );
};

export default IndividualGalleryContainer;
