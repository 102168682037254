import styled from 'styled-components';
import Form from '../../Form';
import { Roboto } from '../../Shared/fonts';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export const FormTitle = styled.div`
  font: 300 2rem ${Roboto};
  color: #535353;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const PrivateBetaForm = styled(Form)`
  .form-submit {
    transition: all 0.2s ease-in;
    height: 4rem;
    width: 13.8rem;
    font: 500 1.4rem ${Roboto};
    letter-spacing: -0.25px;
    line-height: 1.6rem;
    transition: background-color 0.3s;
    &:hover {
      background-color: #693fc6;
    }
  }
`;

export const RegisterNowText = styled.div`
  font: 300 3rem ${Roboto};
  color: #565656;
`;

export const BetaTextContainer = styled.div`
  font: 300 1.3rem ${Roboto};
  color: '#535353';
  border-bottom: '1px solid black';
  padding-bottom: 1rem;
  text-align: center;
  @media screen and (min-width: 675px) {
    text-align: left;
  }
`;

export const UnderlineBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #d0d0d0;
  margin: 1rem 0 2rem;
`;

export const TextAndFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0 2rem;
  @media screen and (min-width: 760px) {
    width: 60rem;
    padding-bottom: 30rem;
  }
  @media screen and (min-width: 630px) {
    align-items: flex-start;
  }
`;

export const TextAndFormWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  width: 100%;
  padding-top: 5rem;
`;

export const CustomSnackbarContent = styled(SnackbarContent)`
  && {
    background-color: ${({ mode }) => {
      switch (mode) {
        case 'error':
          return 'white';
        case 'success':
          return 'rgb(151,57,222)';
        default:
          return 'white';
      }
    }};
    color: ${({ mode }) => {
      switch (mode) {
        case 'error':
          return 'black';
        case 'success':
          return 'white';
        default:
          return 'black';
      }
    }};
    font-size: 1.5rem;
  }
`;
