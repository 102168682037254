import styled from 'styled-components';

export const DescriptionPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 3rem;
  margin: 5rem 0;
  @media screen and (min-width: 680px) {
    padding: 0 13rem 0 10rem;
  }
`;

export const BannerContainerBuffer = styled.div`
  height: 20rem;
  padding: 3rem;
  width: 100%;
  @media screen and (min-width: 680px) {
    padding: 6rem 13rem 0 10rem;
    height: 20rem;
  }
`;
