import React from 'react';
import {
  InfoContainer,
  EmailAndTitleContainer,
  EmailTitle,
  Email
} from './styles';
import { emailsData } from '../config';

export const Info = props => (
  <InfoContainer>
    {emailsData.map(({ title, email }) => (
      <EmailAndTitleContainer key={email}>
        <EmailTitle>{title}</EmailTitle>
        <Email>{email}</Email>
      </EmailAndTitleContainer>
    ))}
  </InfoContainer>
);

export default Info;
