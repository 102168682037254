import React from 'react';
import {
  MobileScreenDownloadContainer,
  MobileScreenDownloadBanner,
  VersionText,
  MobileScreenDescription,
  AppsIcon,
  IconContainer,
  MobileLink,
  MobilePhoneImage
} from './styles';
import { BASE_IMG_URL } from '../../../Shared/Config';
import { MobileLogos } from '../config';

const PHONE_DISPLAY_SRC = 'mobile_display.png';

const MobileScreenDownloadPage = ({ version }) => (
  <MobileScreenDownloadContainer>
    <MobileScreenDownloadBanner title="Mobile Apps">
      {version ? <VersionText>{`Version ${version}`}</VersionText> : null}
      <MobileScreenDescription>
        Start, join and schedule meetings; send group text, images and
        push-to-talk messages on mobile devices.
      </MobileScreenDescription>
      <IconContainer>
        {MobileLogos.map(({ src, url }) => (
          <MobileLink key={src} target="_blank" href={url}>
            <AppsIcon src={`${BASE_IMG_URL}/${src}`} />
          </MobileLink>
        ))}
      </IconContainer>
      <MobilePhoneImage src={`${BASE_IMG_URL}/${PHONE_DISPLAY_SRC}`} />
    </MobileScreenDownloadBanner>
  </MobileScreenDownloadContainer>
);

export default MobileScreenDownloadPage;
