import React from 'react';
import { BASE_IMG_URL } from '../../../Shared/Config';
import { DEFAULT } from '../index';
import Bowser from 'bowser';
import {
  MainContainer,
  MainImage,
  DownloadContainer,
  DownloadsLinkContainer,
  DownloadsLinkTextContainer,
  DownloadIconsContainer,
  HurryText,
  HurryLink,
  AppsIcon,
  DownloadButton,
  DownloadLink,
  SeeAllPlatformsText
} from './styles';
import { IconContainer, MobileLink } from '../MobileScreenDownloadPage/styles';
import { MobileLogos } from '../config';

const SCREEN_IMG_SRC = 'downloads_page/Screen@2x.png';

const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName();

const SUPPORTED_BROWSER = 'Chrome';

const LargeScreenDownloadPage = ({
  version,
  osFamily,
  downloadUrl,
  defaultDownloadLinks,
  setOsToDefault
}) => {
  return (
    <DownloadContainer>
      <MainContainer>
        <DownloadsLinkContainer>
          <DownloadsLinkTextContainer
            title="huddl.ai Client for Meetings"
            description={`Version ${version}`}
          />
          <DownloadIconsContainer>
            {osFamily === DEFAULT ? (
              <IconContainer>
                {MobileLogos.map(({ src, url }) => (
                  <MobileLink key={src} target="_blank" href={url}>
                    <AppsIcon src={`${BASE_IMG_URL}/${src}`} />
                  </MobileLink>
                ))}
              </IconContainer>
            ) : null}
            {osFamily === DEFAULT ? (
              Object.values(defaultDownloadLinks).map(({ url, fullName }) => {
                return (
                  <DownloadLink key={fullName} href={url}>
                    <DownloadButton>{`Download huddl.ai for ${fullName}`}</DownloadButton>
                  </DownloadLink>
                );
              })
            ) : (
              <DownloadLink href={defaultDownloadLinks[osFamily].url}>
                <DownloadButton>{`Download huddl.ai for ${defaultDownloadLinks[osFamily].fullName}`}</DownloadButton>
              </DownloadLink>
            )}
            <DownloadLink href={downloadUrl} />
          </DownloadIconsContainer>
          {browser === SUPPORTED_BROWSER && (
            <HurryText>
              <HurryLink href="https://web.gohuddl.com" target="_blank">
                Join via Web
              </HurryLink>
            </HurryText>
          )}
          {osFamily === DEFAULT ? null : (
            <SeeAllPlatformsText onClick={setOsToDefault}>
              See all platforms
            </SeeAllPlatformsText>
          )}
          <MainImage
            src={`${BASE_IMG_URL}/${SCREEN_IMG_SRC}`}
            alt="Preview of the huddl.ai app's landing page"
          />
        </DownloadsLinkContainer>
      </MainContainer>
    </DownloadContainer>
  );
};

export default LargeScreenDownloadPage;
