import * as React from "react";
const ConversionalIcon = (props) => (
  <svg
    width={34}
    height={38}
    viewBox='0 0 34 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.8478 3.33776C15.2344 3.33746 12.6151 4.33054 10.9735 5.23519C10.1916 5.66605 9.22261 5.3548 8.80917 4.54001C8.39573 3.72521 8.6944 2.71541 9.47626 2.28456C11.5181 1.15938 14.6476 -0.00035579 18.8481 8.18805e-08C22.853 0.000340062 26.5766 1.2588 29.3364 3.34873C32.0894 5.43353 34 8.45474 34 11.94C34 13.2665 33.7299 14.3424 33.3997 15.1567C33.0554 16.0057 32.1159 16.4031 31.3012 16.0443C30.4865 15.6856 30.1052 14.7065 30.4494 13.8575C30.6273 13.4187 30.7971 12.7832 30.7971 11.94C30.7971 9.80958 29.628 7.69555 27.4536 6.04887C25.2859 4.40732 22.2341 3.33805 18.8478 3.33776Z'
      fill='url(#paint0_linear_3500_124)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.3839 8.98722C14.1388 8.98722 12.3187 10.8839 12.3187 13.2236C12.3187 15.5633 14.1388 17.46 16.3839 17.46H25.007C27.2521 17.46 29.0722 15.5633 29.0722 13.2236C29.0722 10.8839 27.2521 8.98722 25.007 8.98722H16.3839ZM15.5216 13.2236C15.5216 12.7273 15.9077 12.325 16.3839 12.325H25.007C25.4832 12.325 25.8693 12.7273 25.8693 13.2236C25.8693 13.7199 25.4832 14.1222 25.007 14.1222H16.3839C15.9077 14.1222 15.5216 13.7199 15.5216 13.2236Z'
      fill='url(#paint1_linear_3500_124)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.6905 19.2572C10.9359 19.2571 9.22106 20.1637 8.15423 21.722C7.0579 23.3234 6.71772 25.4941 7.47902 27.873C8.20362 30.1372 9.92665 32.5988 11.7136 34.462C12.6215 35.4086 13.5994 36.2575 14.5531 36.8818C15.4562 37.473 16.5423 38 17.6178 38C19.3311 38 20.8901 37.1332 21.8938 36.436C21.9458 36.3999 21.9951 36.3611 22.0416 36.3201C22.8648 35.7425 23.696 35.0154 24.4756 34.2175C26.2625 32.3885 27.9855 29.9721 28.7102 27.7495C29.4714 25.4143 29.1313 23.2835 28.0349 21.7115C26.9681 20.1818 25.2533 19.2919 23.4987 19.292H20.9451C20.8395 19.2692 20.7301 19.2572 20.618 19.2572H12.6905ZM20.9057 22.5684C20.813 22.5858 20.7175 22.5949 20.62 22.5949H12.6903C11.9803 22.5949 11.2328 22.9721 10.7616 23.6605C10.3198 24.3058 10.0462 25.3446 10.5174 26.817C11.0253 28.4041 12.3802 30.4355 13.979 32.1025C14.349 32.4883 14.7206 32.8424 15.0834 33.1569C15.0834 33.1569 16.4461 33.9616 16.7495 34.1685C17.5936 34.7442 18.5713 34.414 18.5713 34.414C18.6443 34.414 18.782 34.3894 18.9846 34.3141C19.3616 34.1426 19.7453 33.9171 20.1172 33.6588C20.2018 33.5999 20.2899 33.5506 20.3803 33.5106C20.9621 33.0862 21.5883 32.5378 22.2102 31.9013C23.809 30.2649 25.1639 28.2708 25.6718 26.7129C26.143 25.2675 25.8694 24.2478 25.4276 23.6144C24.9564 22.9387 24.2088 22.5684 23.4988 22.5684H20.9057Z'
      fill='url(#paint2_linear_3500_124)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.29705 6.41924C3.65946 6.41924 2.299 7.34542 1.40271 8.59081C0.506788 9.83568 0 11.479 0 13.2231C0 14.9673 0.506788 16.6106 1.40271 17.8555C2.299 19.1009 3.65946 20.027 5.29705 20.027C6.93464 20.027 8.2951 19.1009 9.19139 17.8555C10.0873 16.6106 10.5941 14.9673 10.5941 13.2231C10.5941 11.479 10.0873 9.83568 9.19139 8.59081C8.2951 7.34542 6.93464 6.41924 5.29705 6.41924ZM3.20287 13.2231C3.20287 12.1313 3.52337 11.2071 3.965 10.5935C4.40626 9.98034 4.89361 9.757 5.29705 9.757C5.70049 9.757 6.18784 9.98034 6.6291 10.5935C7.07073 11.2071 7.39123 12.1313 7.39123 13.2231C7.39123 14.315 7.07073 15.2392 6.6291 15.8528C6.18784 16.466 5.70049 16.6893 5.29705 16.6893C4.89361 16.6893 4.40626 16.466 3.965 15.8528C3.52337 15.2392 3.20287 14.315 3.20287 13.2231Z'
      fill='url(#paint3_linear_3500_124)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3500_124'
        x1={24.3667}
        y1={9}
        x2={5.74731}
        y2={38.2181}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3500_124'
        x1={24.3667}
        y1={9}
        x2={5.74731}
        y2={38.2181}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_3500_124'
        x1={24.3667}
        y1={9}
        x2={5.74731}
        y2={38.2181}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_3500_124'
        x1={24.3667}
        y1={9}
        x2={5.74731}
        y2={38.2181}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
    </defs>
  </svg>
);
export default ConversionalIcon;
