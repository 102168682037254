import { BASE_IMG_URL } from '../../../Shared/Config';
import AvImage from '../../../../assets/AvImage.svg';
const outcomesUrl = `${BASE_IMG_URL}/CaptureInfo.mp4`;
const captureInfoUrl = `${BASE_IMG_URL}/Outcomes.mp4`;
const saveTimeUrl = `${BASE_IMG_URL}/SaveTime.mp4`;
const notesCollaborateUrl = `${BASE_IMG_URL}/NotesCollaborate.mp4`;
// const soundCancellationUrl = `${BASE_IMG_URL}/SoundCancellation.mp4`;
const searchUrl = `${BASE_IMG_URL}/Search.mp4`;

export const descriptionsData = [
  {
    title: 'Capture critical information in every meeting',
    description: [
      'With one-click, capture & save important meeting moments in real-time for better decision making but without the hassle of manual documentation. Best part is you can now search images and the data it holds.'
    ],
    imageUrl: captureInfoUrl
  },
  {
    title: 'Save Time',
    description: [
      `huddl.ai Integrations connect Moments, Action Items, Notes & other Meeting content with Enterprise systems, eliminating manually intensive workflows. If you can't take all this manually, we got you covered.Huddl automatically identifies tasks, decisions and other important meeting data and sends them to you automatically.`
    ],
    imageUrl: saveTimeUrl
  },
  {
    title: 'Get things done!',
    description: [
      'Good meeting agendas help achieve outcomes.',
      "Starting with templated agendas for different types of meetings, huddl.ai's AI recommendation system learns from meetings memory to suggest agendas, updates & followups for future meetings across various enterprise applications. You can either build the agenda yourself or collaborate with your team to create an agenda together."
    ],
    imageUrl: outcomesUrl
  },
  {
    title: 'Collaborate as a team using our canvas',
    description: [
      'Co-authored, co-edited Note taking & Action Items promote participant engagement by turning talking meetings into collaborative working sessions without ever leaving your meeting. You can easily connect to any of your favourite apps and have the latest information in a single place. Our notes editor works with all the popular enterprise tools.'
    ],
    imageUrl: notesCollaborateUrl
  },
  {
    title: 'Meet anywhere with huddl',
    description: [
      'huddl.ai works both as an independent and as an extended app with Zoom, Webex, Microsoft Teams, and Google Meet (Coming soon). huddl.ai removes the need for people to jump across tools and screenshares to access information. Everything can be accessed right from the huddl.ai app. No matter which product you use you will get a consistent experience.'
    ],
    // imageUrl: soundCancellationUrl,
    backgroundImageUrl: AvImage
  },
  {
    title: 'Find everything instantly',
    description: [
      'Multi-media Search for instant access through huddl.ai memory for important decisions, followups & action items whether they were said, screen-shared or typed in notes. Our AI driven search suggestions makes finding content simple.'
    ],
    imageUrl: searchUrl
  }
];
