import React, { Component, createRef } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Cookie from 'js-cookie';
import { OverflowContainer } from './styles';
// import Demo from '../Pages/Demo';
import Features from '../Pages/Features';
// import Resources from '../Pages/Resources';
// import Signin from '../Pages/Signin';
import Leadership from '../Pages/Leadership';
import Careers from '../Pages/Careers';
import Press from '../Pages/Press';
import Contact from '../Pages/Contact';
import JobDescription from '../Pages/JobDescripton';
import MobileJoin from '../Pages/MobileJoin';
import Beta from '../Pages/Beta';
import Download from '../Pages/Download';
import Navbar from '../Navbar';
import Footer from '../Footer';
import LicenceAgreement from '../Pages/Policies/Eula';
import PrivacyPolicy from '../Pages/Policies/Privacy';
// import LoginGate from '../LoginGate';
// import VideoCategory from '../Pages/VideoCategory';
import { footerData } from '../Shared/BannerAndFooterData';
import GlobalAccessNumbers from '../Pages/GlobalAccessNumbers';
import numbersData from '../Pages/GlobalAccessNumbers/numbersData';
import numbersDatav2 from '../Pages/GlobalAccessNumbers/numbersDatav2';
import WebinarRegistration from '../Pages/WebinarRegistration';
import Webex from '../Pages/Webex';
import ZoomFaqs from '../Pages/Zoom/ZoomFaqs';
import ZoomDocs from '../Pages/Zoom/ZoomDocs';
import ZoomSupport from '../Pages/Zoom/ZoomSupport';
import Blog from '../Pages/Blog';
import HuddlApp from '../Pages/HuddlApp';
import Integration from '../Pages/Integration';
import IntegrationTabs from '../Pages/Integration/IntegrationTabs';

import { COOKIE_NAME, COOKIE_DOMAIN } from '../Shared/Config';
import {
  // RESOURCES_ROUTE,
  // DEMO_ROUTE,
  // SIGN_IN_ROUTE,
  ABOUT_US_ROUTE,
  CAREER_ROUTE,
  PRESS_ROUTE,
  CONTACT_ROUTE,
  BETA_ROUTE,
  JOB_DESCRIPTION_ROUTE,
  DOWNLOAD_ROUTE,
  MOBILE_JOIN_ROUTE,
  GLOBAL_ACCESS_NUMBERS_ROUTE,
  GLOBAL_ACCESS_NUMBERS_V2_ROUTE,
  INTEGRATIONS_ROUTE,
  EULA_ROUTE,
  PRIVACY_POLICY_ROUTE,
  WEBEX_ROUTE,
  BLOG_ROUTE,
  ZOOM_DOCS_ROUTE,
  ZOOM_FAQS_ROUTE,
  ZOOM_SUPPORT_ROUTE,
  HUDDL_ROUTE,
  PLATFORM_ROUTE,
  PRODUCT_ROUTE,
  TAVANT_ROUTE
  // VIDEO_CATEGORY_ROUTE
} from '../../constants/routes';
import Tavant from '../Pages/Tavant';
import PlatformPage from '../Pages/Platform';

//Authenticated Routes
// const authenticatedRoutes = [
//   {
//     key: 'resources',
//     path: `/${RESOURCES_ROUTE}`,
//     component: Resources,
//     exact: true
//   },
//   {
//     key: '/resources/:category',
//     path: `/${VIDEO_CATEGORY_ROUTE}`,
//     exact: true,
//     component: VideoCategory
//   }
// ];

// Main routes to display
const mainRoutesToRender = [
  // {
  //   key: 'features',
  //   path: `/${FEATURES_ROUTE}`,
  //   component: Features
  // },
  // {
  //   key: 'demo',
  //   path: `/${DEMO_ROUTE}`,
  //   component: Demo,
  //   exact: true
  // },
  // {
  //   key: 'about-us',
  //   path: `/${ABOUT_US_ROUTE}`,
  //   component: Leadership,
  //   exact: true
  // },
  {
    key: 'careers',
    path: `/${CAREER_ROUTE}`,
    exact: true,
    component: Careers
  },
  {
    key: 'contact',
    path: `/${CONTACT_ROUTE}`,
    component: Contact,
    exact: true
  },
  {
    key: 'beta',
    path: `/${BETA_ROUTE}`,
    component: Beta,
    exact: true
  },
  {
    key: `careers-jobId`,
    path: `/${JOB_DESCRIPTION_ROUTE}`,
    exact: true,
    component: JobDescription
  },
  {
    key: `mobile-join`,
    path: `/${MOBILE_JOIN_ROUTE}`,
    exact: true,
    component: MobileJoin
  },
  {
    key: `global-access-numbers`,
    path: `/${GLOBAL_ACCESS_NUMBERS_ROUTE}/:meetingId?`,
    exact: true,
    render: props => (
      <GlobalAccessNumbers numbersData={numbersData} {...props} showOneClick />
    )
  },
  {
    key: `global-access-numbers-v2`,
    path: `/${GLOBAL_ACCESS_NUMBERS_V2_ROUTE}/:meetingId?`,
    exact: true,
    render: props => (
      <GlobalAccessNumbers numbersData={numbersDatav2} {...props} />
    )
  },
  {
    key: `webinar`,
    path: `/techmahindra-warranty-aftermarket-virtual-summit`,
    exact: true,
    render: props => <WebinarRegistration {...props} />
  },
  {
    key: `webex`,
    path: `/${WEBEX_ROUTE}`,
    exact: true,
    render: props => <Webex {...props} />
  },
  {
    key: `zoom-docs`,
    path: `/${ZOOM_DOCS_ROUTE}`,
    exact: true,
    render: props => <ZoomDocs {...props} />
  },
  {
    key: `zoom-faqs`,
    path: `/${ZOOM_FAQS_ROUTE}`,
    exact: true,
    render: props => <ZoomFaqs {...props} />
  },
  {
    key: `zoom-support`,
    path: `/${ZOOM_SUPPORT_ROUTE}`,
    exact: true,
    render: props => <ZoomSupport {...props} />
  },
  {
    key: `huddl-app`,
    path: `/${HUDDL_ROUTE}`,
    exact: true,
    render: props => <HuddlApp {...props} />
  },
  {
    key: `tavant-app`,
    path: `/${TAVANT_ROUTE}`,
    exact: true,
    render: props => <Tavant {...props} />
  }
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginChecked: false,
      loggedIn: false,
      error: null,
      scrollY: 0,
      windowHeight: 0,
      scrolledToBotttom: false,
      overflowHidden: false
      // redirect: false
    };
    this.containerRef = createRef();
  }

  componentDidMount() {
    this.checkIfLoggedIn();
    const windowHeight = window.innerHeight;
    this.setState({ windowHeight });
  }

  toggleOverflow = () =>
    this.setState({ overflowHidden: !this.state.overflowHidden });

  scrollToTop = () => (this.containerRef.current.scrollTop = 0);

  componentDidUpdate(prevProps) {
    // this.checkUserHasLoggedOut(prevProps);
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.scrollToTop();
    }
  }

  setLastOffsets = (y, callback) => {
    this.setState({ scrollY: y });
  };

  handleScroll = e => {
    const {
      currentTarget: { scrollTop }
    } = e;
    const isAtBottom =
      Math.abs(e.currentTarget.scrollHeight - scrollTop - window.innerHeight) <=
      1;
    this.setState({ scrollY: scrollTop, scrolledToBotttom: isAtBottom });
  };

  /**
   * Checks if user has logged out
   * @param  {Boolean} options.loggedIn: prevLoggedIn
   */
  checkUserHasLoggedOut = ({ loggedIn: prevLoggedIn }) => {
    const { loggedIn } = this.props;
    if (loggedIn && !prevLoggedIn) {
      this.checkIfLoggedIn();
    }
  };

  /**
   * Set the logged in flag
   * @param  {Boolean}  loggedIn
   * @param  {Function} cb
   */
  userSetLoggedIn = (loggedIn, cb) => {
    this.setState({ loggedIn }, cb);
  };

  /**
   * Set the login checked status
   * @param  {Boolean}  loggedIn
   * @param  {Function} cb
   */
  loginSetChecked = (loginChecked, cb) => {
    this.setState({ loginChecked }, cb);
  };

  loginSetError = (error, cb) => {
    this.setState({ error });
  };

  /**
   * Gets stored JWT value to see if user is still logged in
   * and sets loginChecked flag to true
   */
  checkIfLoggedIn = () => {
    const token = Cookie.get(COOKIE_NAME);
    if (token) {
      this.handleParsedJWT(token);
    }
    this.loginSetChecked(true);
  };

  /**
   * Saves the parsed JWT in state
   * and sets it as a default header for all session API requests
   * @param  {String} token
   */
  handleParsedJWT = token => {
    this.userSetLoggedIn(Boolean(token));
  };

  /**
   * Clears the jwt and logs the user out
   */
  logout = () => {
    Cookie.remove(COOKIE_NAME, { path: '/', domain: COOKIE_DOMAIN });
    this.userSetLoggedIn(false, () => this.loginSetChecked(false));
    this.setState({ redirect: true }, () => this.setState({ redirect: false }));
  };

  render() {
    // const { loginChecked, loggedIn, redirect } = this.state;
    const { scrollY, windowHeight, scrolledToBotttom, overflowHidden } =
      this.state;
    return (
      <OverflowContainer
        overflowHidden={overflowHidden}
        onScroll={this.handleScroll}
        ref={this.containerRef}
        id="app-container"
      >
        <Route
          path="/"
          render={routeProps => (
            <Navbar
              toggleOverflow={this.toggleOverflow}
              {...routeProps}
              // loggedIn={loggedIn}
              // logOut={this.logout}
            />
          )}
        />

        <Switch>
          <Redirect exact={true} from="/" to={PRODUCT_ROUTE} />
          {/* {redirect ? <Redirect to="/sign-in" /> : null} */}
          {/* <Route
              key="sign-in"
              path={`/${SIGN_IN_ROUTE}`}
              exact={true}
              render={routeProps => (
                <Signin loggedIn={loggedIn} {...routeProps} />
              )}
            /> */}
          <Route
            key="features"
            path={`/${PRODUCT_ROUTE}`}
            exact
            render={routeProps => (
              <Features
                {...routeProps}
                distFromTop={scrollY}
                scrollY={-scrollY}
                windowHeight={windowHeight}
                scrolledToBotttom={scrolledToBotttom}
              />
            )}
          />
          <Route
            key="about-us"
            path={`/${ABOUT_US_ROUTE}`}
            exact={true}
            render={routeProps => (
              <Leadership
                {...routeProps}
                scrolledToBotttom={scrolledToBotttom}
              />
            )}
          />
          <Route
            key="press"
            path={`/${PRESS_ROUTE}`}
            exact
            render={routeProps => (
              <Press {...routeProps} scrolledToBotttom={scrolledToBotttom} />
            )}
          />
          <Route
            key="blog"
            path={`/${BLOG_ROUTE}`}
            exact
            render={routeProps => (
              <Blog {...routeProps} scrolledToBotttom={scrolledToBotttom} />
            )}
          />
          <Route
            exact={true}
            key="download"
            path={`/${DOWNLOAD_ROUTE}`}
            render={routeProps => (
              <Download {...routeProps} scrollToTop={this.scrollToTop} />
            )}
          />
          <Route
            exact={true}
            key="eula"
            path={`/${EULA_ROUTE}`}
            render={routeProps => (
              <LicenceAgreement
                {...routeProps}
                scrollToTop={this.scrollToTop}
              />
            )}
          />
          <Route
            exact={true}
            key="privacy-policy"
            path={`/${PRIVACY_POLICY_ROUTE}`}
            render={routeProps => (
              <PrivacyPolicy {...routeProps} scrollToTop={this.scrollToTop} />
            )}
          />
          <Route
            exact={true}
            key="integrations"
            path={`/${INTEGRATIONS_ROUTE}`}
            render={routeProps => <Integration {...routeProps} />}
          />
          <Route
            key="integration-tabs"
            path={`/${INTEGRATIONS_ROUTE}/:name?/:tab?`}
            render={routeProps => (
              <IntegrationTabs
                {...routeProps}
                scrollY={scrollY}
                scrolledToBotttom={scrolledToBotttom}
                scrollToTop={this.scrollToTop}
              />
            )}
          />
          <Route
            key="platform"
            path={`/${PLATFORM_ROUTE}`}
            exact
            render={routeProps => (
              <PlatformPage
                {...routeProps}
                scrollY={scrollY}
                scrolledToBotttom={scrolledToBotttom}
              />
            )}
          />
          {mainRoutesToRender.map(mainRouteProps => (
            <Route {...mainRouteProps} />
          ))}
          {/* <LoginGate loggedIn={loggedIn} loginChecked={loginChecked}>
              {authenticatedRoutes.map(authRouteProps => (
                <Route {...authRouteProps} />
              ))}
            </LoginGate> */}
          <Redirect exact from="/jobs" to="/careers" />
          <Redirect exact from="*" to="/features" />
        </Switch>

        <Route exact path="/web">
          <Redirect push to={'/web/index.html'} />
        </Route>

        <Route exact path="/ivr">
          <Redirect push to={'/ivr/index.html'} />
        </Route>

        <Route
          path="/"
          render={routeProps => (
            <Footer
              footerData={footerData}
              {...routeProps}
              // loggedIn={loggedIn}
              // logOut={this.logout}
            />
          )}
        />
      </OverflowContainer>
    );
  }
}

export default withRouter(App);
