import React, { useState } from 'react';
import { QaBlock, Question, QuestionBlock, Answer, ToggleIcon } from './styles';
import ExpandIcon from '../../../../Shared/Icons/ExpandIcon';
import CollapseIcon from '../../../../Shared/Icons/CollapseIcon';

const FaqsComponent = ({ id, answer, question }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <QaBlock id={`faq_${id}`}>
      <QuestionBlock onClick={toggleShowAnswer}>
        <Question showAnswer={showAnswer}>{question}</Question>
        <ToggleIcon>
          {showAnswer ? <CollapseIcon /> : <ExpandIcon />}
        </ToggleIcon>
      </QuestionBlock>
      <Answer
        showAnswer={showAnswer}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </QaBlock>
  );
};

export default FaqsComponent;
