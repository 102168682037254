import React from 'react';
import Banner from '../../Banner';
import PressItem from './Item';
import {
  PressContainer,
  PressItemsContainer,
  PressInfo,
  InfoTitle,
  InfoText,
  InfoLink
  // Rule
} from './styles';
import { data } from '../../Shared/BannerAndFooterData';
import { pressData } from './config';

const {
  press: {
    banner: { title, description }
  }
} = data;

const PressPage = () => (
  <PressContainer>
    <Banner title={title} description={description} />
    <PressItemsContainer>
      {pressData.map(({ date, title, link, source: { name, logo } }, i) => (
        <PressItem
          key={i}
          link={link}
          date={date}
          title={title}
          name={name}
          logo={logo}
        />
      ))}
    </PressItemsContainer>
    <PressInfo>
      <InfoTitle>Press inquiries</InfoTitle>
      <InfoText>
        For inquiries, contact{' '}
        <InfoLink href="mailto:press@gohuddl.com">press@gohuddl.com</InfoLink>
      </InfoText>

      {/* Commenting for now */}
      {/* <Rule /> */}
      {/* <InfoTitle>Brand assets</InfoTitle>
      <InfoText>
        Our brand in a nutshell: logos, colors, and brand guidelines.
        <InfoLink href={downloadLink}>Download</InfoLink>
      </InfoText> */}
    </PressInfo>
  </PressContainer>
);

export default PressPage;
