import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container } from '../styles';
import {
  TabsHeader,
  Tab,
  Banner,
  LightText,
  BoldText,
  TabLabel,
  BannerText,
  BannerHeading,
  BannerInfo,
  GetHuddlBtn,
  BannerImg,
  BannerImgButton,
  BackIconAndHeading,
  BackIconText,
  TopHeading,
  BackHeader,
  Content
} from './styles';
import BackIcon from '../Icons/BackIcon';
import {
  bannerIcons,
  redirectLink,
  tabMapping,
  bannerInfo,
  heading,
  TabsIndexAndUrl,
  contentMap,
  slackTabMapping
} from './constants';

const IntegrationTabs = ({ scrollY, scrolledToBotttom, scrollToTop }) => {
  const { name, tab } = useParams();
  const [activeTab, setActiveTab] = useState(null);
  const [content, setContent] = useState(null);
  const [urlTab, setUrlTab] = useState('');
  const history = useHistory();
  const handleTabChange = (newValue, path) => {
    setActiveTab(newValue);
    setUrlTab(path);
    setShrinkHeader(false);
    scrollToTop && scrollToTop();
  };
  const [shrinkHeader, setShrinkHeader] = useState(false);
  useEffect(() => {
    if (!scrolledToBotttom) {
      setShrinkHeader(scrollY >= 318);
    }
  }, [scrolledToBotttom, scrollY]);

  useEffect(() => {
    if (!content && name) {
      setContent(name);
      setUrlTab(name === 'slack' ? 'description' : 'docs');
      setActiveTab(name === 'slack' ? 0 : tabMapping.get('docs'));
    }
    if (!urlTab && tab) {
      setUrlTab(tab);
      if (name === 'slack') {
        setActiveTab(slackTabMapping.get(tab));
        return;
      }
      setActiveTab(tabMapping.get(tab));
    }
  }, [name, urlTab, tab, content]);

  const backToIntegration = () => {
    history && history.push('/integrations');
  };
  return (
    <Container>
      <BackHeader shrinkHeader={shrinkHeader}>
        <BackIconAndHeading onClick={backToIntegration}>
          <BackIcon />
          <BackIconText>Back</BackIconText>
        </BackIconAndHeading>
        {shrinkHeader && (
          <BannerImg
            shrinkHeader={shrinkHeader}
            size={3}
            src={bannerIcons.get(name)}
            alt="integration"
          />
        )}
        <TopHeading shrinkHeader={shrinkHeader}>{`huddl.ai + ${heading.get(
          name
        )}`}</TopHeading>
      </BackHeader>
      <Banner id="header" shrinkHeader={shrinkHeader}>
        <BannerImgButton>
          <BannerImg size={10} src={bannerIcons.get(name)} alt="integration" />
          {name !== 'service-now' && (
            <GetHuddlBtn href={redirectLink.get(name)} target="_blank">
              Get huddl.ai
            </GetHuddlBtn>
          )}
        </BannerImgButton>
        <BannerText>
          <BannerHeading>
            {name !== 'service-now'
              ? 'Collaborative space for note taking, task management'
              : 'Push your meeting data directly into ServiceNow'}
          </BannerHeading>
          <BannerInfo>{bannerInfo.get(name)}</BannerInfo>
        </BannerText>
      </Banner>
      <TabsHeader shrinkHeader={shrinkHeader}>
        {TabsIndexAndUrl.get(name === 'slack' ? name : 'others').map(
          ({ tab, url, tabText: { lightText, boldText } }) => {
            return (
              <Tab
                active={activeTab === tab}
                onClick={() => handleTabChange(tab, url)}
                key={`${tab}-${url}`}
              >
                <TabLabel>
                  <LightText>{lightText}</LightText>
                  <BoldText>{boldText}</BoldText>
                </TabLabel>
              </Tab>
            );
          }
        )}
      </TabsHeader>
      <Content isWebex={content === 'webex'} shrinkHeader={shrinkHeader}>
        {contentMap(shrinkHeader).get(name) ? (
          contentMap(shrinkHeader)
            .get(name)
            .map(({ content: integration, tab, url }) => {
              return (
                <div key={`${content}-${tab}-${url}`}>
                  {(activeTab === tab || urlTab === url) && integration}
                </div>
              );
            })
        ) : (
          <></>
        )}
      </Content>
    </Container>
  );
};

export default IntegrationTabs;
