import React from 'react';
import {
  PositionsContainer,
  PositionsRowContainer,
  IndividualPositionContainer,
  Country,
  PositionLink,
  PositionLocation
} from './styles';

const Positions = ({ country, data, className }) => {
  return <PositionsContainer className={className}>
    <Country>{country}</Country>
    <PositionsRowContainer>
      {data.map(({ id, title, locations }) => {
        const location = locations[0];
        return (
          <IndividualPositionContainer key={id}>
            <PositionLink
              to={{
                pathname: `careers/${id}`
              }}
            >
              {title}
            </PositionLink>
            <PositionLocation>{location}</PositionLocation>
          </IndividualPositionContainer>
        );
      })}
    </PositionsRowContainer>
  </PositionsContainer>
};

export default Positions;
