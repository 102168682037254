import React, { Component, createRef } from 'react';

class TransitionContainer extends Component {
  constructor(props) {
    super(props);
    this.transitionRef = createRef();
  }

  calculateRatio = (threshold, elementDistFromTop) => {
    const diff = elementDistFromTop - threshold;
    const ratio = (threshold - diff) / threshold;
    return ratio;
  };

  childWithProps = (
    isVisible,
    ratio,
    elementDistFromTop,
    threshold,
  ) =>
    React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        containerRef: this.transitionRef,
        isVisible,
        ratio,
        elementDistFromTop,
        threshold
      });
    });
  render() {
    const { scrolledToBotttom } = this.props;
    let isVisible = scrolledToBotttom;
    let ratio;
    let elementDistFromTop;
    let threshold = window.innerHeight * 0.6;
    if (this.transitionRef.current !== null) {
      elementDistFromTop = this.transitionRef.current.getBoundingClientRect()
        .top;
      ratio = this.calculateRatio(
        threshold,
        elementDistFromTop,
        window.innerHeight
      );
    }
    return this.childWithProps(isVisible, ratio, elementDistFromTop, threshold);
  }
}

export default TransitionContainer;
