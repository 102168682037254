import styled from 'styled-components';

export const PressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PressItemsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 2rem 2rem 3rem;
  align-self: center;
  justify-content: center;

  @media screen and (min-width: 600px) and (max-width: 1280px) {
    padding: 5.2rem 12rem 3rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 5.2rem 10rem 3rem;
    width: 126rem;
    justify-content: flex-start;
  }
`;

export const PressInfo = styled.div`
  margin: 5rem 0 5rem 2rem;
  max-width: 100%;

  @media screen and (max-width: 600px) {
    text-align: center;
  }

  @media screen and (min-width: 600px) {
    margin-left: 14rem;
  }
  @media screen and (min-width: 1280px) {
    width: 103rem;
    margin: 5rem auto;
  }
`;

export const InfoTitle = styled.h4`
  margin: 1rem 0;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -0.27px;
  color: #252525;
`;

export const InfoText = styled.div`
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.27px;
  color: #777;
`;

export const InfoLink = styled.a`
  color: #6f40f7;
`;

export const Rule = styled.hr`
  margin: 3.8rem 0 3.3rem;
  background-color: #979797;
`;
