import React, { Component } from 'react';
import {
  Container,
  Header,
  Category,
  Description,
  List,
  HeaderLarge
} from '../Privacy/styles';
import { NumberList, AlphaList, RomanList } from './styles';

const LicenceObject = {
  heading: {
    title: 'End User License Agreement',
    date: 'Last modified: December 17, 2018',
    description:
      'This license ("License") is a binding legal document between you and Huddl, Inc. It governs your use of the huddl.ai licensed software application for mobile devices ("Licensed Application") to access the huddl.ai services (the "huddl.ai Services"). The terms of this License are accepted when you download and use the Licensed Application. If you do not agree with to abide by the terms of this License, you are not permitted to use the Licensed Application or the huddl.ai Services'
  },
  Restrictions: {
    title: 'Restrictions On Use:',
    para: [
      'In some countries there are restrictions on the use of the Licensed Application. It is your responsibility to ensure that you are legally allowed to use the Licensed Application or the huddl.ai Services where you are located.',
      'The Licensed Application is licensed, not sold, to you for use only under the terms of this license, unless a product or service is accompanied by a separate license agreement, in which case the terms of that separate license agreement will govern, subject to your prior acceptance of that separate license agreement. We reserve all rights not expressly granted to you.'
    ]
  },
  LicenceScope: {
    title: 'Scope of License',
    para: [
      'This License is a limited, non-exclusive, non-transferable, non-sublicensable license to use the Licensed Application on any mobile device that you own or control, acting as an individual and not a business entity (although this does not preclude the use of the Licensed Application by individuals at work or IT administrators of companies downloading and installing the Licensed Application onto devices used by personnel of such companies).',
      'You may not distribute or make the Licensed Application available over a network where it could be used by multiple mobile devices at the same time. You may not rent, lease, lend, sell, redistribute or sublicense the Licensed Application. You may not copy (except as expressly permitted by this License), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof (unless the foregoing restriction is prohibited by applicable law, or permitted by the licensing terms governing the use of any open sourced components of the Licensed Application, if any). In addition, any commercial copying or distribution, publication or exploitation of the Licensed Application, or any content, software, code, data or materials on or from the Licensed Application, are strictly prohibited unless you have received express prior written permission from us or the applicable rights holder. Any attempt to do so is a violation of our rights.',
      'If you breach these restrictions, you may be subject to prosecution and damages.',
      'The terms of the License will govern any upgrades provided by us that replace and/or supplement the huddl.ai Services, unless such upgrade is accompanied by a separate license in which case the terms of that license will govern.',
      'Use of enhanced-functionality or paid-for versions of the huddl.ai Services ("Paid-For Services") are governed by separate terms and conditions of service, which shall form a part of this License, to the extent that they are applicable.'
    ]
  },
  DataConsent: {
    title: 'Consent to Use of Data',
    para: [
      'Our collection, use and storage of information about you from your use of the Licensed Application and/or huddl.ai Service in general, including any personal data, is described in our privacy policy.',
      'By using the Licensed Application, you understand and agree that we may collect and use technical data and related information, including but not limited to technical information about your device, system and application software, and any peripherals that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Licensed Application. We may also use this information to improve the huddl.ai Service.'
    ]
  },
  LicenceChanges: {
    title: 'Changes to this License',
    para: [
      'We may make changes to the terms of this License from time to time and publish the changes. We will inform you of any changes, either via an on-screen prompt the next time you use the Licensed Application or via e-mail (sent to the e-mail address associated with your user account). The changes will take effect immediately upon being notified to you. You should review this License on a regular basis.',
      'You understand and agree that your acceptance of the terms of this License or your use of the Licensed Application after the date of publication of any updated terms shall constitute your agreement to such updated terms. If you do not agree with the updated terms, you may end your use of the Licensed Application and huddl.ai Services as described in this License.'
    ]
  },
  ApplicationUse: {
    title: 'Use of the Licensed Application',
    parafirst: [
      'The huddl.ai Service governed by this License is provided to you for free. Once you have downloaded the Licensed Application, you can use it to call any other huddl.ai user for free (subject to any charges imposed by your internet service provider, mobile phone data service provider, or device supplier, for which such charges you are fully responsible). You may disable the service at any time by changing the application settings. As stated above, enhanced functionality, paid-for versions of the huddl.ai Service are governed by separate terms and conditions which are not set out in this License.',
      'We make no representation that the huddl.ai Service is available for use in any particular location. To the extent you choose to access the huddl.ai Service, you do so at your own initiative and are responsible for compliance with any applicable laws, including but not limited to applicable local laws. We reserve the right to change, suspend, remove, or disable access to the huddl.ai Service at any time without notice. In no event will we be liable for the removal of or disabling of access to the huddl.ai Service. We may also impose limits on the use of or access to the huddl.ai Service. To the extent that we are able, we will endeavor to publish the time and date of any such suspensions or limitations on our website in advance, but we reserve the right to impose any limits on access to the Licensed Application or the huddl.ai Service without notice or liability to you or any other third party.',
      'We may automatically check your version of the Licensed Application and your agreement to this License constitutes your express consent to permit us to do so. huddl.ai has no obligation to make available any updates. However, huddl.ai may'
    ],
    subPoints: [
      'require you to download and install updates; or',
      'automatically download and install updates to your device.'
    ],
    paraEnd: [
      'Any proposals or ideas including, without limitation, ideas for new products, technologies, promotions, product names, product feedback and product improvements (together "Feedback") are not considered confidential information. If you send any Feedback to us (whether via our websites, social networks or otherwise) you acknowledge and agree that we shall not be under any obligation of confidentiality with respect to the Feedback and nothing in this License limits or restricts our right to freely and independently use, develop, evaluate, or market products, whether incorporating the Feedback or otherwise.',
      'You may link to our websites from another website owned by you, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement by us where none exists. You may not frame our websites on any other website. We may revoke this permission to link to our websites at any time at our sole discretion.',
      'The Licensed Application and the huddl.ai Service may use the processing capabilities, memory and bandwidth of the device that you are using, for the limited purpose of facilitating the communication and establishing a connection between other huddl.ai Service users. If your use of the Licensed Application or huddl.ai Services is dependent upon the processor or bandwidth own or controlled by a third party, you acknowledge and agree that you are responsible for obtaining the consent of such third party for such use, and by accepting the terms of this License, you represent and warranty that you have obtained such consent.',
      'We cannot guarantee that the Licensed Application or the huddl.ai Service will always function without disruptions, delay or errors. A number of factors may impact the quality of your communications and use of the Licensed Application or huddl.ai Service, and may result in the failure of your communications including but not limited to: your local network, firewall, internet service provider, the public internet, the public switched telephone network or your power supply. We take no responsibility for any disruption, interruption or delay causes by any failure of or inadequacy of those items or any other items that are outside of our control.'
    ]
  },
  Obligation: {
    title: 'Your Obligations',
    description: 'You shall:',
    para: [
      'be responsible for the UGC (defined in section 6.1) and indemnify us in respect of any and all liability in respect of third party claims anywhere in the world in respect of UGC contributed by you or via your account;',
      `maintain keypad/password protection on your device using your device's standard protection mechanisms`,
      `ensure that your account is kept secure and that any passwords you create are hard to guess, comprise at least 8 characters and include a combination of letters, numbers and symbols. If you know or suspect that someone else has access to your password then you shall immediately change your password.`,
      `use the Licensed Application and the huddl.ai Service via password-protected, secure internet access points that require, as a minimum, an 8-digit/character access code. Any use of un-secured internet access in order to use the Licensed Application and/or the huddl.ai Service is entirely at your own risk;`,
      `not intercept or monitor, damage or modify any communication which is not intended for you;`,
      `not use any type of spider, virus, worm, trojan-horse or any other codes or instructions that are designed to distort, delete, damage, emulate or disassemble the huddl.ai Service;`,
      `not send unsolicited communications (also referred to as "SPAM", "SPIM" or "SPIT") or any communication not permitted by applicable law or use the Service for the purposes of phishing or pharming or impersonating or misrepresenting affiliation with another person or entity;`,
      `not expose any third party to material which is offensive, harmful to minors, indecent or otherwise objectionable in any way;`,
      `not use the Service to cause or intend to cause embarrassment or distress to, or to threaten, harass or invade the privacy of, any third party;`,
      `not use (including as part of your name and/or profile picture) any material or content that is subject to any third party proprietary rights, unless you have a license or permission from the owner of such rights;`,
      `not collect or harvest any personally identifiable information, including account names, from the Service; or`,
      `not impact or attempt to impact the availability of the Service, with a denial of service (DOS) or distributed denial of service (DDoS) attack`,
      `not permit a third party to carry out any of the actions listed in [5.1.1] to [5.1.12] above.`
    ]
  },
  contentGenerated: {
    title: 'User Generated Content',
    parafirst: [
      `The Licensed Application allows you to submit status text, photos, videos and other communications (collectively, the "User Generated Content" or "UGC"). UGC may be hosted, shared, and/or published as part of the huddl.ai Service, and may be visible to other users of the huddl.ai Service who have your mobile phone number in their mobile phone and which you have not blocked. You acknowledge and agree that any of your UGC may be viewed by other users that have your mobile phone number.`,
      `You retain your ownership rights in your UGC. You understand that whether or not such UGC is published, we do not guarantee any confidentiality with respect to any UGC. You shall be solely responsible for your own UGC and any UGC created by or submitted via your account of the Licensed Application on your device, including the consequences of posting or publishing such UGC. UGC does not necessarily represent the views or opinions of us, and we make no guarantees as to the validity, accuracy or legal status of any UGC. In connection with your UGC, you affirm, represent, and/or warrant that:`
    ],
    subPoints: [
      'You own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use all patent, trademark, trade secret, copyright or other proprietary rights in and to your UGC in the manner contemplated by the huddl.ai Service and this License; and',
      `You have the written consent, release, and/or permission of each identifiable individual person referenced in your UGC to use their name and likeness in your UGC.`
    ],
    notAllowed: [
      'do anything illegal;',
      `contribute, publish or disseminate anything that is defamatory, is offensive or inappropriate (including but not limited to pornography, nudity, bestiality, images of activities that exploit or harm children or non-consenting adults);`,
      `violate the privacy or confidentiality of others (including distribution or publication of images of persons who have not consented to such publication or distribution), or impersonate someone else;`,
      `post UGC that is harassing, bullying, abusive, hateful, obscene, racist, offensive, harmful, illegal or otherwise in breach of third party intellectual property rights; or`,
      `solicit funds or services, or advertise a product or service.`
    ],
    paramiddle: [
      `By submitting the UGC, you hereby grant us a worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform your UGC in connection with the huddl.ai Service and our business (and that of any successor), including without limitation for promoting and redistributing part or all of the huddl.ai Service (and derivative works thereof) in any media formats and through any media channels. You also hereby grant every user of the huddl.ai Service a non-exclusive license to access your UGC through the huddl.ai Service.`,
      `You must refrain from using the Licensed Application or huddl.ai Service to`
    ],
    paraEnd: [
      `We do not endorse any UGC or any opinion, recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with UGC. We do not permit copyright infringing activities and infringement of intellectual property rights via the huddl.ai Service, and we reserve the right to remove all UGC if properly notified that such UGC infringes another's intellectual property rights or is otherwise in breach of this License. We do not accept any liability of any nature in respect of UGC that is removed pursuant to this License (even where such UGC is irretrievable as a result of such removal). We shall be under no obligation to re-instate any UGC at any time.`,
      `In the course of using the huddl.ai Service or the Licensed Application, you may encounter content that may be deemed offensive, indecent, or objectionable. Nevertheless, you agree to use the huddl.ai Service at your sole risk and we shall not have any liability to you for content that may be found to be offensive, indecent, or objectionable. You agree not to exploit the huddl.ai Service in any unauthorized way whatsoever, including but not limited to, trespassing or burdening network capacity.`
    ]
  },
  Disclaimer: {
    title: 'Disclaimer of Warranties',
    para: [
      'You expressly acknowledge and agree that use of the service, including licensed application are at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with you. To the maximum extent permitted by applicable law, our service is provided "as is" and "as available", with all faults and without warranty of any kind, and we hereby disclaim all warranties and conditions with respect to the service, either express, implied or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and non-infringement of third party rights. We do not warrant against interference with your enjoyment of the service or that the service will be uninterrupted or error-free, or that defects in the service will be corrected. No oral or written information or advice given by us shall create a warranty. should the service cause any defects, you assume the entire cost of all necessary servicing, repair or correction.'
    ]
  },
  Limitation: {
    title: 'Limitation of Liability',
    para: [
      'To the extent not prohibited by law, in no event shall we be liable for personal injury, or any incidental, special, indirect or consequential damages whatsoever, including, without limitation, damages for loss of profits, loss or misappropriation of data, business interruption or any other commercial damages or losses, arising out of or related to your use or inability to use the service, however caused, regardless of the theory of liability (contract, tort or otherwise) and even if we have been advised of the possibility of such damages. In no event shall our total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.'
    ]
  },
  Laws: {
    title: 'Export Laws and Regulations',
    para: [
      'The Licensed Application may be subject to international rules that govern the export of software. You shall comply with all applicable international and national laws that apply to the Licensed Application as well as end-user, end-use, destination restrictions issued by national governments or similar bodies, and restrictions on embargoed nations.'
    ]
  },
  Termination: {
    title: 'Termination',
    para: [
      'The License is effective until terminated by you or us. You may terminate your relationship with us at any time. If you are using our free Service, you may simply cease to use the Service or delete our Licensed Application. If you registered for the huddl.ai Service, you can request that we delete your user account.',
      `We have the right to terminate your rights under this License or suspend your account, without notice, if in our reasonable opinion you have failed to comply with any terms of this License. Upon termination of the License, you shall cease all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.`,
      `We may also terminate this License immediately if required due to a change in laws/regulation by a regulator or authority with a lawful mandate in any particular territory, or by any of our partners;`,
      `We shall effect such termination by preventing your access to the Service, where applicable. We reserve the right to cancel accounts that have been inactive for more than one (1) year.`
    ]
  },
  Miscellaneous: {
    title: 'Miscellaneous',
    para: [
      'You agree to the use of electronic communication in order to enter into contracts, place orders, and create other records and to the electronic delivery of notices, policies and changes thereto and records of transactions with us.',
      `For your convenience, we may provide a translation of the English language version of these Terms. In the event of any inconsistency between a non-English version of these Terms and the English version, the English version shall govern your relationship with us.`,
      `This License (together with the huddl.ai Standard Terms and Conditions) constitutes the entire agreement between you and us with respect to your use of the Licensed Application and the huddl.ai Service and replaces any prior agreements between you and us with respect to the Licensed Application and the huddl.ai Service.`,
      `If any provision of this License (or part of it), is found by any court or administrative body of competent jurisdiction or an arbitrator to be illegal, invalid or unenforceable, then such provision (or part of it) shall be removed from the License without affecting the legality, validity or enforceability of the remainder of the License.`,
      `The failure by us to exercise, or delay in exercising, a legal right or remedy provided by this License or by law shall not constitute a waiver of our right or remedy. If we waive a breach of this License, this shall not operate as a waiver of a subsequent breach of the License.`,
      `You may not assign this License or any rights or obligations contained in them. We may, without prior notice, assign this License or any rights or obligations contained in them to any third party.`,
      `This paragraph 11, along with any other provisions, which are expressed or clearly intended to survive or operate in the event of termination, shall survive termination of the License.`,
      `You acknowledge and agree that if we are unable to provide the Licensed Application or the huddl.ai Service as a result of a force majeure event, we will not be in breach of any of its obligations towards you under this License. A force majeure event means any event beyond the control of us.`,
      `This License shall be governed by and interpreted in accordance with laws of the State of California and shall be subject to the jurisdiction of the courts of State of California.
`
    ]
  }
};

class LicenceAgreement extends Component {
  render() {
    return (
      <Container>
        <Category>
          <HeaderLarge>{LicenceObject.heading.title}</HeaderLarge>
          <Description>{LicenceObject.heading.description}</Description>
        </Category>
        <Category>
          <Header>{LicenceObject.Restrictions.title}</Header>
          {LicenceObject.Restrictions.para.map((line, index) => (
            <Description key={`restrictions-${index}`}>{line}</Description>
          ))}
        </Category>
        <NumberList>
          <List>
            <Category>
              <Header>{LicenceObject.LicenceScope.title}</Header>
              <AlphaList>
                {LicenceObject.LicenceScope.para.map((line, index) => (
                  <List key={`LicenceScope-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.DataConsent.title}</Header>
              <AlphaList>
                {LicenceObject.DataConsent.para.map((line, index) => (
                  <List key={`DataConsent-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.LicenceChanges.title}</Header>
              <AlphaList>
                {LicenceObject.LicenceChanges.para.map((line, index) => (
                  <List key={`LicenceChanges-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.ApplicationUse.title}</Header>
              <AlphaList>
                {LicenceObject.ApplicationUse.parafirst.map((line, index) => (
                  <List key={`parafirst-${index}`}>{line}</List>
                ))}
                <RomanList>
                  {LicenceObject.ApplicationUse.subPoints.map((line, index) => (
                    <List key={`subPoints-${index}`}>{line}</List>
                  ))}
                </RomanList>
                {LicenceObject.ApplicationUse.paraEnd.map((line, index) => (
                  <List key={`paraEnd-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.Obligation.title}</Header>
              <AlphaList>
                <List>{LicenceObject.Obligation.description}</List>
                <RomanList>
                  {LicenceObject.Obligation.para.map((line, index) => (
                    <List key={`subPoints-${index}`}>{line}</List>
                  ))}
                </RomanList>
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.contentGenerated.title}</Header>
              <AlphaList>
                {LicenceObject.contentGenerated.parafirst.map((line, index) => (
                  <List key={`contentGeneratedparafirst-${index}`}>{line}</List>
                ))}
                <RomanList>
                  {LicenceObject.contentGenerated.subPoints.map(
                    (line, index) => (
                      <List key={`contentGeneratedsubPoints-${index}`}>
                        {line}
                      </List>
                    )
                  )}
                </RomanList>
                {LicenceObject.contentGenerated.paramiddle.map(
                  (line, index) => (
                    <List key={`contentGeneratedparamiddle-${index}`}>
                      {line}
                    </List>
                  )
                )}
                <RomanList>
                  {LicenceObject.contentGenerated.notAllowed.map(
                    (line, index) => (
                      <List key={`contentGeneratednotAllowed-${index}`}>
                        {line}
                      </List>
                    )
                  )}
                </RomanList>
                {LicenceObject.ApplicationUse.paraEnd.map((line, index) => (
                  <List key={`paraEnd-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.Disclaimer.title}</Header>
              <AlphaList>
                {LicenceObject.Disclaimer.para.map((line, index) => (
                  <List key={`Disclaimer-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.Limitation.title}</Header>
              <AlphaList>
                {LicenceObject.Limitation.para.map((line, index) => (
                  <List key={`Limitation-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.Laws.title}</Header>
              <AlphaList>
                {LicenceObject.Laws.para.map((line, index) => (
                  <List key={`Laws-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.Termination.title}</Header>
              <AlphaList>
                {LicenceObject.Termination.para.map((line, index) => (
                  <List key={`Termination-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>

          <List>
            <Category>
              <Header>{LicenceObject.Miscellaneous.title}</Header>
              <AlphaList>
                {LicenceObject.Miscellaneous.para.map((line, index) => (
                  <List key={`Miscellaneous-${index}`}>{line}</List>
                ))}
              </AlphaList>
            </Category>
          </List>
        </NumberList>
      </Container>
    );
  }
}

export default LicenceAgreement;
