import React from 'react';
import {
  Body,
  GetStartedWrapper,
  GetStartedTitle,
  GetStartedList,
  GetStartedListItem,
  GetStartedInner,
  GettingStartedTitle,
  GettingStartedDesc,
  CircleDiv,
  SubSection,
  SubTitle,
  SubCircleDiv
} from '../../Zoom/ZoomDocs/styles';
import docsList from '../docsList';

const DocsContent = ({ isIntegration }) => {
  const origin = window.location.origin;
  const docs = docsList(origin);
  return (
    <Body isIntegration={isIntegration}>
      <GetStartedWrapper isIntegration={isIntegration}>
        {isIntegration ? (
          <>
            <GettingStartedTitle>Getting Started</GettingStartedTitle>
            <GettingStartedDesc>
              An overview about how huddl.ai works and how to get started
            </GettingStartedDesc>
          </>
        ) : null}
        {docs.map(({ heading, list, subSections }, index) => (
          <GetStartedInner key={`docsList_${index}`}>
            <GetStartedTitle>
              <CircleDiv />
              {heading}
            </GetStartedTitle>
            <GetStartedList>
              {list.map((point, inx) => (
                <GetStartedListItem
                  key={`listem_item_${inx}`}
                  dangerouslySetInnerHTML={{ __html: point }}
                />
              ))}
            </GetStartedList>
            {subSections && subSections.length ? (
              <SubSection>
                {subSections.map(({ subHeading, subList }, sIndex) => (
                  <div key={`Sublist-${sIndex}`}>
                    <SubTitle>
                      <SubCircleDiv />
                      {subHeading}
                    </SubTitle>
                    <GetStartedList>
                      {subList.map((subpoint, subInx) => (
                        <GetStartedListItem
                          key={`sub_list_item_${subInx}`}
                          dangerouslySetInnerHTML={{ __html: subpoint }}
                        />
                      ))}
                    </GetStartedList>
                  </div>
                ))}
              </SubSection>
            ) : null}
          </GetStartedInner>
        ))}
      </GetStartedWrapper>
    </Body>
  );
};

export default DocsContent;
