import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';

export const Container = styled.div`
  padding-top: 5rem;

  background-size: contain;

  @media screen and (min-width: 1500px) {
  }
`;

export const BannerHeader = styled.div`
  margin: 0rem 0 6rem 0;
  min-height: 26rem;
  padding: 8rem 2rem 6rem 2rem;
  background: #f6f4ff;

  @media screen and (min-width: 1100px) {
    margin: 0rem 0 6rem 0;
    padding: 8rem 10rem 6rem 10rem;
  }
`;

export const BannerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerHeading = styled.div`
  color: #202020;
  padding: 0 0 3rem 1.5rem;
  letter-spacing: -0.69px;
  font: 500 5rem/5.8rem ${Roboto};
`;

export const BannerInfo = styled.div`
  color: #000000;
  letter-spacing: 0;
  font: 300 2rem/3rem ${Roboto};
  padding-left: 2rem;
`;

export const Body = styled.div`
  margin: 0 0 6rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const IntegrationContent = styled.div`
  display: flex;
  margin: 4rem 1rem;
  cursor: pointer;
`;
export const IntegrationImg = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 8rem;
  margin-right: 4rem;
`;
export const IntegrationDesc = styled.div`
  flex: 1 1 75%;
`;

export const IntegrationHeading = styled.div`
  line-height: 28px;
  color: #000000;
  font: 500 1.8rem ${Roboto};
`;

export const IntegrationDetail = styled.div`
  font: 400 14px ${Roboto};
  color: #202020;
  max-width: 40rem;
  line-height: 20px;
`;
