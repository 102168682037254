import React from 'react';
import { useHistory } from 'react-router-dom';
import integrationsList from './integrationsList';
import {
  Container,
  BannerHeader,
  BannerBlock,
  BannerHeading,
  BannerInfo,
  Body,
  IntegrationContent,
  IntegrationImg,
  IntegrationDetail,
  IntegrationHeading,
  IntegrationDesc
} from './styles';

const Integration = () => {
  const history = useHistory();
  const redirectTo = name => {
    name === 'slack'
      ? history && history.push(`/integrations/${name}/description`)
      : history && history.push(`/integrations/${name}/docs`);
  };
  return (
    <Container>
      <BannerHeader>
        <BannerBlock>
          <BannerHeading>Integrations</BannerHeading>
        </BannerBlock>
        <BannerInfo>
          Discover popular integrations that extend the value of huddl.ai and
          align your meetings
        </BannerInfo>
      </BannerHeader>
      <Body>
        {integrationsList.map(({ icon, name, desc, path }) => {
          return (
            <IntegrationContent
              key={`${name}`}
              onClick={() => redirectTo(path)}
            >
              <IntegrationImg>
                <img src={icon} alt={name} />
              </IntegrationImg>
              <IntegrationDesc>
                <IntegrationHeading>{name}</IntegrationHeading>
                <IntegrationDetail>{desc}</IntegrationDetail>
              </IntegrationDesc>
            </IntegrationContent>
          );
        })}
      </Body>
    </Container>
  );
};

export default Integration;
