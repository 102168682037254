export const getFilteredList = (input, numbersData) => {
  if (!input || !input.length) {
    return numbersData;
  }

  const searchInput = input.trim();
  const filteredList = numbersData.filter(item =>
    item.country.toLowerCase().includes(searchInput)
  );

  return filteredList;
};
