import React, { useState } from 'react';
import FormContent from '../../../Form';
import { formFields } from './constants';
import { Body, FormBody, SupportTitle, SupportDesc } from './styles';
import { postSupportData } from './config';
import { validateEmail } from '../../../Shared/utils';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import { CustomSnackbarContent } from '../../Demo/Form/styles';

const getInitialState = () => ({
  data: {
    subject: '',
    email: '',
    description: ''
  }
});

const Support = ({ integration }) => {
  const origin = window.location.origin;
  const [supportData, setSupportData] = useState(getInitialState());
  const [formStatus, setFormStatus] = useState({
    err: null,
    successMessage: null
  });
  const [open, setOpen] = useState(false);
  const handleFormChange = (field, val) => {
    const { data } = supportData;
    data[field] = val;
    setSupportData({ data });
  };

  const handleSubmit = async e => {
    e && e.preventDefault();
    const { subject, email, description } = supportData.data;
    const isValid = validateEmail(email);
    setFormStatus({ err: null, successMessage: null });
    if (isValid) {
      // try {
      if (!Boolean(description)) {
        setFormStatus({
          err: 'Description is required',
          successMessage: null
        });
        setOpen(true);
        return;
      }
      const res = await postSupportData(subject, email, description, origin);
      if (res.status === 200) {
        const {
          data: { message }
        } = res;
        console.log('Mail sent', res);
        setFormStatus({ err: null, successMessage: message });
        setOpen(true);
        setSupportData(getInitialState());
      }
      setFormStatus({
        err: res.data.message,
        successMessage: null
      });
      setOpen(true);
      console.error('Support form submission failed', res.data.message);
    } else {
      setFormStatus({
        err: 'Email is required',
        successMessage: null
      });
      setOpen(true);
    }
  };

  const setError = err => {
    setFormStatus({ err: err, successMessage: null });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Body>
      <SupportTitle>Submit your issue</SupportTitle>
      <SupportDesc>
        You can help improve huddl.ai by giving us feedback about any problems
        you're having.
      </SupportDesc>
      <FormBody>
        <FormContent
          formId="Support"
          formFields={formFields(integration)}
          onChange={handleFormChange}
          formValues={supportData.data}
          formSubmitContent="Submit"
          onClickSubmit
          handleSubmit={handleSubmit}
          setError={setError}
        />
      </FormBody>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handleClose}
        autoHideDuration={10000}
        TransitionComponent={Fade}
      >
        <CustomSnackbarContent
          mode={formStatus.err ? 'error' : 'success'}
          message={formStatus.err || formStatus.successMessage}
        />
      </Snackbar>
    </Body>
  );
};

export default Support;
