import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

export const MenuLink = styled(Link)`
  && {
    text-decoration: none;
    color: #7b47ef;
    font-size: 2rem;
    text-transform: capitalize;
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
  }
`;
