import axios from 'axios';

const baseUrl = new Map([
  [
    `http://localhost:3000`,
    `https://development.huddldev.com/apps/support/email`
  ],
  [
    `https://website-dev.huddldev.com`,
    `https://development.huddldev.com/apps/support/email`
  ],
  [`https://huddl.ai`, `https://production.gohuddl.com/apps/support/email`]
]);

export const postSupportData = (subject, email, description, origin) => {
  const supportUrl =
    baseUrl.get(origin) || `https://production.gohuddl.com/apps/support/email`;
  const postToSupportUrl = supportUrl;
  const headers = {
    'Content-Type': 'application/json'
  };
  const dataToSend = { subject, email, description };
  return axios
    .post(postToSupportUrl, dataToSend, {
      headers: headers
    })
    .then(response => response)
    .catch(error => {
      if (error.response) {
        return error.response;
      }
    });
};
