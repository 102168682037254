import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import { BannerBlock } from '../styles';

export const Banner = styled.div`
  display: ${({ shrinkHeader }) => (shrinkHeader ? 'none' : 'flex')};
  transition: display 2s;
  transition-timing-function: ease-in-out;
  margin: 0;
  position: relative;
  background: #f6f4ff;
  min-height: 20rem;
  @media screen and (min-width: 1100px) {
    margin: 0;
    padding: 0 10rem 6rem 11rem;
  }
`;
export const BackHeader = styled.div`
  display: flex;
  position: ${({ shrinkHeader }) => (shrinkHeader ? 'fixed' : 'relative')};
  top: ${({ shrinkHeader }) => (shrinkHeader ? '5%' : null)};
  background: #f6f4ff;
  width: 100%;
  z-index: 9;
  min-height: 8rem;
  align-items: center;
  @media screen and (max-height: 800px) {
    top: 6%;
  }
  @media screen and (max-height: 750px) {
    top: 8%;
  }
`;
export const BackIconAndHeading = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 12em;
  @media screen and (max-width: 600px) {
    margin-left: 2em;
  }
`;
export const BackIconText = styled.div`
  color: #808080;
  font: 400 1.6rem ${Roboto};
  line-height: 13px;
  margin-left: 1em;
`;
export const TopHeading = styled.div`
  font: 400 2rem ${Roboto};
  line-height: 15px;
  color: #202020;
  margin-left: ${({ shrinkHeader }) => (shrinkHeader ? '0.5em' : '4.2em')};
`;
export const BannerImg = styled.img`
  height: ${({ size }) => `${size}rem`};
  margin-bottom: ${({ shrinkHeader }) => (shrinkHeader ? null : '2em')};
  margin-left: ${({ shrinkHeader }) => (shrinkHeader ? '9em' : null)};
`;
export const BannerText = styled(BannerBlock)`
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  justify-content: center;
`;
export const BannerImgButton = styled(BannerText)`
  @media screen and (max-width: 600px) {
    padding: 0 1em;
  }
`;
export const BannerHeading = styled.div`
  color: #202020;
  line-height: 44px;
  font: 400 4rem ${Roboto};
  padding-left: 1em;
  @media screen and (max-width: 600px) {
    font: 400 3rem ${Roboto};
  }
`;
export const BannerInfo = styled.div`
  color: #000000;
  letter-spacing: 0;
  font: 300 2rem/3rem ${Roboto};
  padding-left: 4rem;
  @media screen and (max-width: 600px) {
    padding-left: 3rem;
    font: 300 2rem ${Roboto};
  }
`;
export const Content = styled.div`
  margin-top: ${({ shrinkHeader, isWebex }) =>
    shrinkHeader ? (isWebex ? '55em' : '22em') : null};
  @media screen and (max-height: 800px) {
    margin-top: ${({ shrinkHeader, isWebex }) =>
      shrinkHeader ? (isWebex ? '55em' : '55em') : null};
  }
`;
export const TabsHeader = styled.div`
  display: flex;
  gap: 1em;
  position: ${({ shrinkHeader }) => (shrinkHeader ? 'fixed' : 'relative')};
  top: ${({ shrinkHeader }) => (shrinkHeader ? '13%' : null)};
  width: 100%;
  background: #ffffff;
  z-index: 10;
  height: 6.2rem;
  border-bottom: 3px solid #f6f4ff;
  padding: ${({ shrinkHeader }) => (shrinkHeader ? '0 9em' : '0 8rem')};
  margin: ${({ shrinkHeader }) => (shrinkHeader ? '0' : '0 2rem')};

  @media screen and (max-height: 800px) {
    top: 15%;
  }
  @media screen and (max-height: 750px) {
    top: 18%;
  }
  @media screen and (max-width: 600px) {
    height: 10.2rem;
    padding: 0 2rem;
    gap: 3em;
  }
`;
export const Tab = styled.div`
  display: flex;
  border-bottom: ${({ active }) => (active ? '2px solid #705ddc' : null)};
  width: fit-content;
  margin: 0 2rem;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    width: 16em;
    margin: 0;
  }
`;
export const TabLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;
export const LightText = styled.div`
  font: 500 1rem ${Roboto};
  line-height: 19px;
  color: #444444;
`;
export const BoldText = styled(LightText)`
  font: 400 1.5rem ${Roboto};
  color: #705ddc;
`;
export const GetHuddlBtn = styled.a`
  text-decoration: none;
  padding: 1rem 1.5rem;
  border: 1px solid #7b47ef;
  color: #7b47ef;
  background: transparent;
  font: 500 1.4rem/1.8rem ${Roboto};
  width: 11rem;
  border-radius: 0.3rem;
  text-align: center;
  display: block;
`;
