const docsList = [
  {
    heading: 'Getting huddl.ai installed with Zoom from Zoom Marketplace',
    list: [
      `Go to <a href="https://marketplace.zoom.us/" target="_blank" rel="noopener noreferrer">Zoom Marketplace</a>`,
      'Search for huddl.ai app',
      'Click on huddl.ai',
      'Confirm permissions and authorize with Zoom',
      'Open Zoom, navigate to ‘Apps’ and find huddl.ai',
      'Give huddl.ai the calendar permissions'
    ],
    subSections: []
  },
  {
    heading: 'Using huddl.ai in the Zoom call',
    list: [
      'Open Zoom',
      'Join the meeting',
      'huddl.ai will process the meeting for analysis if ‘Zoom recording on cloud’ is enabled. This feature is available for pro/business Zoom accounts',
      'To access other huddl.ai features, click on the ‘Apps’ icon and find huddl.ai',
      'As soon as you click on it, it will redirect you to the huddl.ai app where you can take the notes, capture moments, take tasks, etc.'
    ],
    subSections: [
      // Sub section arr of objs
      {
        subHeading: 'Taking collaborative notes',
        subList: [
          'Once in the meeting, open the huddl.ai app',
          'Turn on the Zoom recording on cloud. (Notes can be taken without turning on the recording also)',
          `Navigate to the notes app from the side panel by clicking on  
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.9037 0C1.41786 0 1.024 0.389945 1.024 0.870968V2.60417C1.024 3.09002 1.41786 3.48387 1.9037 3.48387C2.38954 3.48387 2.7834 3.09002 2.7834 2.60417V1.74194H6.30219V16.2581H2.7834C2.7834 16.2581 2.79757 15.2901 2.7834 14.1652C2.7834 14.1652 2.78311 15.4286 2.78311 14.1429C2.78311 12.8571 1.02429 12.8571 1.02429 14.1429C1.02429 15.4286 1.024 14.1652 1.024 14.1652V17.129C1.024 17.6101 1.41786 18 1.9037 18H15.9789C16.4647 18 16.8586 17.6101 16.8586 17.129V0.870968C16.8586 0.389945 16.4647 0 15.9789 0H1.9037ZM8.06159 1.74194V16.2581H15.0992V1.74194H8.06159Z"
                fill="#808080"
              />
              <path
                d="M1.02399 7.54839C0.538142 7.54839 0.144288 7.93833 0.144288 8.41936C0.144288 8.90038 0.538142 9.29032 1.02399 9.29032C1.67517 9.29032 2.13193 9.29032 2.78311 9.29032C3.26896 9.29032 3.66281 8.90038 3.66281 8.41936C3.66281 7.93833 3.26896 7.54839 2.78311 7.54839C2.13193 7.54839 1.67517 7.54839 1.02399 7.54839Z"
                fill="#808080"
              />
              <path
                d="M1.02399 10.4723C0.538142 10.4723 0.144288 10.8623 0.144288 11.3433C0.144288 11.8243 0.538142 12.2143 1.02399 12.2143C1.67517 12.2143 2.13193 12.2143 2.78311 12.2143C3.26896 12.2143 3.66281 11.8243 3.66281 11.3433C3.66281 10.8623 3.26896 10.4723 2.78311 10.4723C2.13193 10.4723 1.67517 10.4723 1.02399 10.4723Z"
                fill="#808080"
              />
              <path
                d="M1.02399 4.5C0.538142 4.5 0.144287 4.88995 0.144287 5.37097C0.144287 5.85199 0.538142 6.24194 1.02399 6.24194C1.67517 6.24194 2.13193 6.24194 2.78311 6.24194C3.26896 6.24194 3.66281 5.85199 3.66281 5.37097C3.66281 4.88995 3.26896 4.5 2.78311 4.5C2.13193 4.5 1.67517 4.5 1.02399 4.5Z"
                fill="#808080"
              />
            </svg>
           icon`,
          'You are all set to take collaborative notes'
        ]
      },
      {
        subHeading: 'Capturing moments',
        subList: [
          'Once in the meeting, start Zoom recording on cloud and open the huddl.ai app',
          `As soon as the Zoom cloud recording is on, you will see a floating ‘capture moment’ CTA at the bottom of the apps panel`,
          `Clicking on it will highlight the conversation starting from <b>15 seconds in the past</b>. By default it captures for a 3 mins span`,
          `Each moment can be named, tagged, shared and assigned as a task`
        ]
      },
      {
        subHeading: 'Assign Tasks',
        subList: [
          `Tasks can be assigned to any of the invited participants of the meeting through huddl.ai. Users can add a moment as a task or create a task independently`,
          `To add a moment as a task, just tag the assignee in the tags field while capturing moment or click on ‘Assign as task’ from the overflow menu`
        ]
      }
    ]
  },
  {
    heading: 'Using huddl.ai once the call has ended',
    list: [
      `Once the meeting ends, you can go to the apps section and navigate to the meeting to access the recording, moments, agendas, notes and tasks related to it`
    ],
    subSections: [
      {
        subHeading: 'Sharing meeting notes',
        subList: [
          'Click on the ‘mail’ icon on top right of the meetings summary page',
          'Enter the email address of users you want to share the meeting summary with',
          'Click on send'
        ]
      },
      {
        subHeading: 'Sharing Moments',
        subList: [
          'Hover on the moment you wish to share',
          'Click on the share icon',
          'You can either copy the moment link/moment link with info and share it with other users',
          `Or enter contacts/email addresses in the text field below and click on 'share'`
        ]
      },
      {
        subHeading: 'Sending meeting notes to Servicenow',
        subList: [
          'Your meeting notes can directly be pushed to your Servicenow account by clicking on the service-now icon on top right of the post meeting '
        ]
      }
    ]
  },
  {
    heading: 'Uninstalling huddl.ai ',
    list: [
      'Login to your Zoom Account and navigate to the Zoom App Marketplace',
      'Click Manage > Installed Apps or search for the huddl.ai app',
      'Click on the huddl.ai app',
      'Click on uninstall'
    ],
    subSections: []
  }
];

export default docsList;
