import imageSarahSmith from '../../../../assets/Sarah_Smith.png';
import imageJensMeggers from '../../../../assets/Jens_Meggers.png';
import imageJamesCham from '../../../../assets/James_Cham.png';
import imageJayReinemann from '../../../../assets/Jay_Reinemann.png';
import imageSanjayJha from '../../../../assets/Sanjay_Jha.png';
import imagePradeepSindhu from '../../../../assets/Pradeep_Sindhu.png';
import imageAmarjitGill from '../../../../assets/Amarjit_Gill.png';
import imageSudhakarRamakrishna from '../../../../assets/Sudhakar_Ramakrishna.png';
import imageYoungSohn from '../../../../assets/Young_Sohn.png';
import imageJohnLagerling from '../../../../assets/John_Lagerling.png';
import imageHosseinYassaie from '../../../../assets/Hossein_Yassaie.png';
// import imageRonnieLott from '../../../../assets/Ronnie_pic.jpg';
// import imageSarah from '../../../../assets/sarah_pic.jpeg';
import imageAchin from '../../../../assets/Achin_Pic.png';
import imageBrian from '../../../../assets/Brian_Pic.png';
import imageEric from '../../../../assets/Eric_Pic.jpg';
// import imageJames from '../../../../assets/James_pic.jpg';
import imageLipBu from '../../../../assets/Lip-Bu-Pic.jpg';
import imageVenk from '../../../../assets/Venk_pic.jpg';
// import imageJay from '../../../../assets/Jay_pic.jpeg';

const linkedInSarahSmith = 'https://www.linkedin.com/in/sasmith4';
const linkedInJensMeggers = 'https://www.linkedin.com/in/jensmeggers/';
const linkedInJamesCham = 'https://www.linkedin.com/in/jcham/';
const linkedInJayReinemann = 'https://www.linkedin.com/in/jayreinemann/';
const linkedInSanjayJha = 'https://www.linkedin.com/in/sanjay-jha-a91240b9/';
// TODO: Missing Pradeep's - add linkedin
const linkedInAmarjitGill = 'https://www.linkedin.com/in/gillamarjit/';
const linkedInSudhakarRamakrishna =
  'https://www.linkedin.com/in/sudhakar-ramakrishna-a58223/';
const linkedInYoungSohn = 'https://www.linkedin.com/in/youngsohn/';
const linkedInJohnLagerling =
  'https://www.linkedin.com/in/john-lagerling-a0a461/';
const linkedInHosseinYassaie =
  'https://www.linkedin.com/in/sir-hossein-yassaie-freng-fiet-55685012/';

const linkedInLipBu = 'https://www.linkedin.com/in/lip-bu-tan-284a7846/';
const linkedInAchin = 'https://www.linkedin.com/in/achinbhowmik/';
// const linkedInRonnie = 'https://www.linkedin.com/in/ronnie-lott-51a553/';
const linkedInBrian = 'https://www.linkedin.com/in/brian-long-85221238/';
const linkedInEric = 'https://www.linkedin.com/in/ericbuatois/';
const linkedInVenk = 'https://www.linkedin.com/in/vnshukla/';
// const linkedInJay = 'https://www.linkedin.com/in/jayreinemann/';
// const linkedInSarah = 'https://www.linkedin.com/in/sasmith4/';
// const linkedInJames = 'https://www.linkedin.com/in/jcham/';

export const AdvisorsData = [
  {
    imageUrl: imageSarahSmith,
    name: 'Sarah Smith',
    role: 'Bain Capital',
    linkedIn: linkedInSarahSmith
  },
  {
    imageUrl: imageJensMeggers,
    name: 'Jens Meggers',
    role: 'Webex, Symantec',
    linkedIn: linkedInJensMeggers
  },
  {
    imageUrl: imageJamesCham,
    name: 'James Cham',
    role: 'Bloomberg Beta',
    linkedIn: linkedInJamesCham
  },
  {
    imageUrl: imageJayReinemann,
    name: 'Jay Reinemann',
    role: 'Propel VC',
    linkedIn: linkedInJayReinemann
  },
  {
    imageUrl: imageYoungSohn,
    name: 'Young Sohn',
    role: 'Samsung, Intel',
    linkedIn: linkedInYoungSohn
  },
  {
    imageUrl: imageSanjayJha,
    name: 'Sanjay Jha',
    role: 'Global Foundaries, Motorola, Qualcomm',
    linkedIn: linkedInSanjayJha
  },
  {
    imageUrl: imageJohnLagerling,
    name: 'John Lagerling',
    role: 'Mercari, Facebook, Google',
    linkedIn: linkedInJohnLagerling
  },
  {
    imageUrl: imagePradeepSindhu,
    name: 'Pradeep Sindhu',
    role: 'Fungible, Juniper Networks',
    linkedIn: null
  },
  {
    imageUrl: imageAmarjitGill,
    name: 'Amarjit Gill',
    role: 'Maginatics, PASemi, SiByte',
    linkedIn: linkedInAmarjitGill
  },
  {
    imageUrl: imageLipBu,
    name: 'Lip-Bu Tan',
    role: 'Cadence, Walden International',
    linkedIn: linkedInLipBu
  },
  {
    imageUrl: imageSudhakarRamakrishna,
    name: 'Sudhakar Ramakrishna',
    role: 'PulseSecure, Citrix, Polycom',
    linkedIn: linkedInSudhakarRamakrishna
  },
  {
    imageUrl: imageHosseinYassaie,
    name: 'Hossein Yassaie',
    role: 'Imagination, STMicroelectronics',
    linkedIn: linkedInHosseinYassaie
  },
  {
    imageUrl: imageAchin,
    name: 'Achin Bhowmik',
    role: 'Starkey Hearing, Intel',
    linkedIn: linkedInAchin
  },
  // {
  //   imageUrl: imageRonnieLott,
  //   name: 'Ronnie Lott',
  //   role: 'Lott Investments, 49ers',
  //   linkedIn: linkedInRonnie
  // },
  {
    imageUrl: imageBrian,
    name: 'Brian Long',
    role: 'Atlantic Bridge, CEVA',
    linkedIn: linkedInBrian
  },
  {
    imageUrl: imageEric,
    name: 'Eric Buatois',
    role: 'Benhamou Global Ventures, HP',
    linkedIn: linkedInEric
  },
  {
    imageUrl: imageVenk,
    name: 'Venktesh Shukla',
    role: 'Monta Vista Capital, TIE Global',
    linkedIn: linkedInVenk
  }
  // {
  //   imageUrl: imageJay,
  //   name: 'Jay Reinemann',
  //   role: 'Propel Venture Partners',
  //   linkedIn: linkedInJay
  // },
  // {
  //   imageUrl: imageSarah,
  //   name: 'Sarah Smith',
  //   role: 'Bain Capital Ventures',
  //   linkedIn: linkedInSarah
  // },
  // {
  //   imageUrl: imageJames,
  //   name: 'James Cham',
  //   role: 'Bloomberg Beta',
  //   linkedIn: linkedInJames
  // }
];
