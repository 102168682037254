import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Roboto } from '../../../Shared/fonts';

export const PositionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;

  @media screen and (min-width: 525px) {
    padding: 3rem 0;
  }
`;

export const PositionsRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 525px) {
    justify-content: flex-start;
  }
`;

export const IndividualPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.3rem;
  flex-basis: 27rem;
  text-overflow: wrap;
`;

export const Country = styled.div`
  font: 300 2.5rem ${Roboto};
  margin: 1.3rem;
  color: #252525;
  text-align: center;
  @media screen and (min-width: 525px) {
    text-align: left;
  }
`;

export const PositionLink = styled(Link)`
  && {
    color: #7239e2;
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (min-width: 525px) {
      text-align: left;
    }
  }
`;

export const PositionLocation = styled.div`
  font: 300 1.4rem ${Roboto};
  color: #4a4a4a;
  text-align: center;
  @media screen and (min-width: 525px) {
    text-align: left;
  }
`;
