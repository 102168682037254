import React from 'react';
import {
  RadioButtonLabel,
  RadioButtonContainer,
  RadioFormControlLabel,
  StyledRadioCheckBox,
  OtherInput,
  OtherInputContainer,
  RadioButtonLabelText
} from './styles';

const labelClassName = 'custom-label-text';

export const RadioInput = ({
  onChange,
  handleTouchedState,
  options,
  touched,
  value,
  labelText,
  customInputLabelColor,
  status,
  focus,
  isFileType,
  setIsFormFresh,
  showOtherInput,
  openOtherInput,
  closeOtherInput,
  field
}) => (
  <RadioButtonLabel status={status}>
    <RadioButtonContainer>
      {options.map(({ name, textInput }) =>
        textInput ? (
          <React.Fragment key={name}>
            <RadioFormControlLabel
              control={
                <StyledRadioCheckBox
                  checked={showOtherInput || value === name}
                  value={name}
                  onClick={() => openOtherInput(field)}
                  onChange={onChange}
                />
              }
              classes={{ label: labelClassName }}
              label={name}
            />

            {showOtherInput ? (
              <OtherInputContainer>
                <OtherInput
                  autoFocus
                  onChange={onChange}
                  value={value === 'Other' ? '' : value}
                />
              </OtherInputContainer>
            ) : null}
          </React.Fragment>
        ) : (
          <div key={name}>
            <RadioFormControlLabel
              value={name}
              control={
                <StyledRadioCheckBox
                  checked={value === name}
                  onClick={() => closeOtherInput(field)}
                  onChange={onChange}
                />
              }
              classes={{ label: labelClassName }}
              label={name}
            />
          </div>
        )
      )}
    </RadioButtonContainer>
    <RadioButtonLabelText
      customInputLabelColor={customInputLabelColor}
      focus={focus || value.length}
      isFileType={isFileType}
    >
      {labelText}
    </RadioButtonLabelText>
  </RadioButtonLabel>
);
