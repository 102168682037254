export const env =
  process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';

export const PORT = process.env.REACT_APP_PORT || 3000;

export const baseUrls = {
  development: {
    base: 'https://dev.huddldev.com',
    redirect: `http://local.huddldev.com:${PORT}/features`
  },
  dev: {
    base: 'https://dev.huddldev.com',
    redirect: 'https://website-dev.huddldev.com/features'
  }
};

export const COOKIE_NAME = 'jwt';
export const COOKIE_DOMAIN = 'huddldev.com';

export const BASE_IMG_URL = 'https://static.gohuddl.com/img/website';
export const BASE_ASSETS_ROOT =
  'https://s3.us-east-2.amazonaws.com/huddl-help-videos';

export const BASE_WEBCLIENT_URL = 'https://web.gohuddl.com';
