import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Form from '../../../Form';
import { Input } from '../../../Form/FormField/styles';

export const FormPageOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const FormPageAndInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2rem 0rem 0rem 0rem;
  border-bottom: 1px solid #d0d0d0;
`;

export const Headline = styled.div`
  position: relative;
  font: 2rem ${Roboto};
  color: #535353;
  width: 50%;
  margin-bottom: 2rem;
`;

export const FormInformationText = styled.div`
  font: 1.3rem ${Roboto};
  color: #535353;
  width: 50%;
  margin-bottom: 2rem;
`;

export const DemoFormContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const DemoFormComponent = styled(Form)`
  width: 100%;
  .form-text-input,
  .form-text-area {
    background-color: #fff;
    border-color: rgba(151, 151, 151, 0.74);
    border-radius: 3px;
  }

  .form-submit {
    transition: background-color 0.3s;
    &:hover {
      background-color: #693fc6;
    }
  }
`;

export const FormInputsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0rem 2rem 0rem;
`;

export const FormTextArea = styled.textarea`
  height: 10rem;
  padding: 1rem;
  outline: none;
  border-radius: 3px;
  font: 300 1.5rem ${Roboto};
  color: #535353;
  border: 1px solid #d1d1d1;
  margin-top: 2rem;
  resize: none;
  width: 100%;
`;

export const DemoFormInput = styled(Input)`
  height: ${({ large }) => (large ? '10rem' : '4rem')};
  padding-left: 1rem;
  outline: none;
  border-radius: 3px;
  font: 300 1.5rem ${Roboto};
  color: #535353;
  border: 1px solid #d1d1d1;
  margin-top: 2rem;

  ::placeholder {
    overflow-wrap: normal;
  }
`;

export const DemoFormSubmitButton = styled.button`
  @media screen and (max-width: 700px) {
    margin: 0 auto;
    margin-top: 2rem;
  }

  width: 13rem;
  height: 4rem;
  border-radius: 5px;
  margin-top: 2rem;
  background-color: #7b47ef;
  font: 500 1.4rem ${Roboto};
  letter-spacing: -0.25px;
  line-height: 1.6rem;
  color: #ffffff;
`;

export const CustomSnackbarContent = styled(SnackbarContent)`
  && {
    background-color: ${({ mode }) => {
      switch (mode) {
        case 'error':
          return 'white';
        case 'success':
          return 'rgb(151,57,222)';
        default:
          return 'white';
      }
    }};
    color: ${({ mode }) => {
      switch (mode) {
        case 'error':
          return 'black';
        case 'success':
          return 'white';
        default:
          return 'black';
      }
    }};
    font-size: 1.5rem;
  }
`;
