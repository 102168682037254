const docsList = [
  {
    heading:
      'Getting huddl.ai installed with Microsoft Teams from Apps directory',
    list: [
      `Go to <a href='https://appsource.microsoft.com/en-us/marketplace/apps?product=teams' target="_blank" rel="noopener noreferrer">Microsoft Teams app directory</a>`,
      'Click on Search and type huddl.ai',
      'Once you find huddl.ai app select it',
      `On the modal window click the Add button to install huddl.ai`
    ],
    subSections: []
  },
  {
    heading: 'Adding huddl.ai to a meeting',
    list: [],
    subHeading: 'There are 3 ways to add huddl.ai to a meeting',
    subSections: [
      {
        title: 'Way 1',
        subTitle: `Go to <a href='https://appsource.microsoft.com/en-us/marketplace/apps?product=teams' target="_blank" rel="noopener noreferrer">Microsoft Teams app directory</a>`,
        subList: [
          'Click on Search and type huddl.ai',
          'Once you find huddl.ai app select it',
          'On the modal window click the down arrow and select Add to a meeting',
          'Search and select the meeting and click Set up a tab.'
        ]
      },
      {
        title: 'Way 2',
        subTitle: 'Go to the Microsoft Teams calendar',
        subList: [
          'Click the existing meeting and choose edit option',
          'Click the “ + ” button on the top',
          'In the modal window search for huddl.ai, select it and save.'
        ]
      },
      {
        title: 'Way 3',
        subTitle: 'During your Microsoft Teams meeting',
        subList: [
          'Click the “ + ” button on the top hear of the meeting section',
          'In the drop down window search for huddl.ai, select it and save it.'
        ]
      }
    ]
  }
];

export default docsList;
