import React, { Component, createRef } from 'react';
import { FeaturesBanner, BannerAndImagesContainer } from './styles';
import TextContainer from './TextContainer';
import TransitionContainer from '../../Shared/TransitionContainer';
import MainImageContainer from './MainImageAndTextContainer';
import Descriptions from './Descriptions';
import { data } from '../../Shared/BannerAndFooterData';
// import DownloadSection from './DownloadSection';
import TrustedByContainer from './TrustedByContainer';

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      firstEntered: false,
      perspectiveXFromCenter: 0,
      windowSize: 0
    };
    this.textContainerRef = createRef();
    this.imageContainerRef = createRef();
  }

  render() {
    const {
      features: {
        banner: { title, description, highlight }
      }
    } = data;
    const { x, y, firstEntered } = this.state;
    const {
      scrollY,
      distFromTop,
      windowHeight,
      scrolledToBotttom
    } = this.props;
    let newYDistance;
    if (scrollY === 0) {
      newYDistance = y;
    } else {
      newYDistance = y;
    }
    return (
      <div>
        <BannerAndImagesContainer ref={this.imageContainerRef}>
          <FeaturesBanner
            title={title}
            description={description}
            highlight={highlight}
            features
          />
          <TransitionContainer>
            <MainImageContainer
              y={newYDistance}
              x={x}
              distFromTop={distFromTop}
              firstEntered={firstEntered}
            />
          </TransitionContainer>
        </BannerAndImagesContainer>
        <TransitionContainer>
          <TextContainer />
        </TransitionContainer>
        <TransitionContainer>
          <TrustedByContainer />
        </TransitionContainer>
        <Descriptions
          windowHeight={windowHeight}
          distFromTop={distFromTop}
          scrollY={scrollY}
          scrolledToBotttom={scrolledToBotttom}
        />

        {/* <DownloadSection /> */}
      </div>
    );
  }
}

export default Features;
