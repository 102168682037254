import styled from 'styled-components';
import Banner from '../../../Banner';
import { Roboto } from '../../../Shared/fonts';

export const MobileScreenDownloadContainer = styled.div``;

export const MobileScreenDownloadBanner = styled(Banner)`
  height: 100%;
  .banner-circle {
    display: none;
  }
`;

export const VersionText = styled.div`
  color: #535353;
  font-size: 1.3rem;
`;

export const MobileScreenDescription = styled.div`
  font: 300 1.2rem ${Roboto};
  text-align: center;
  color: #535353;
  margin-top: 2rem;
`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
`;

export const MobilePhoneImage = styled.img`
  width: 75%;
  max-width: 35rem;
  margin-top: 5rem;
`;

export const MobileLink = styled.a`
  text-decoration: none;
  &:first-of-type {
    margin-right: 0.5rem;
  }

  &:nth-of-type(2) {
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 353px) {
    &:first-of-type {
      margin-right: 2rem;
    }

    &:nth-of-type(2) {
      margin-left: 2rem;
    }
  }
`;

export const AppsIcon = styled.img`
  cursor: pointer;
`;
