import styled from 'styled-components';

export const MainImageAndTextContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (min-width: 800px) {
  }
`;

export const VideoContainer = styled.video`
  max-width: 80%;
`;
