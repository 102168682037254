import React from 'react';

import {
  Body,
  GetStartedTitle,
  GetStartedInner,
  CircleDiv,
  FaqsWrapper,
  FaqsOrederList,
  FaqsInfo,
  AnchorLink,
  GettingStartedTitle
} from '../styles';
import faqsList from '../Faqs/faqsList.js';
import FaqsComponent from '../Faqs';

const ZoomFaqsContent = ({ isIntegration, shrinkHeader }) => {
  return (
    <Body isIntegration={isIntegration} shrinkHeader={shrinkHeader}>
      {isIntegration ? (
        <>
          <GettingStartedTitle shrinkHeader={shrinkHeader}>
            Frequently Asked Questions
          </GettingStartedTitle>
        </>
      ) : null}
      <FaqsWrapper isIntegration={isIntegration}>
        <GetStartedInner>
          <GetStartedTitle>
            <CircleDiv />
            Here is everything you need to know about huddl.ai
            <FaqsInfo>
              Can't find the answer or have something else to ask? Send us an
              email at{' '}
              <AnchorLink href="mailto:support@huddl.ai">
                support@huddl.ai
              </AnchorLink>
            </FaqsInfo>
          </GetStartedTitle>
        </GetStartedInner>
        <FaqsOrederList>
          {faqsList.map(({ id, ...list }) => (
            <FaqsComponent key={`faq-list-${id}`} id={id} {...list} />
          ))}
        </FaqsOrederList>
      </FaqsWrapper>
    </Body>
  );
};

export default ZoomFaqsContent;
