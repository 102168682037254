import React from 'react';
import {
  JobDescriptionBannerContainer,
  JobDescriptionTextContainer,
  JobDescriptionTitle,
  JobDescriptionLocation,
  ApplyNowButton,
  ViewAllJobsContainer,
  ViewAllJobsText,
  ViewAllJobsLink
} from './styles';
import { LeftArrow } from '../../../Shared/Icons/LeftArrow';

const JobDescriptionBanner = ({
  jobDescriptionData: { title, locations },
  openEmailLink
}) => {
  const location = locations[0];
  return (
    <JobDescriptionBannerContainer>
      <JobDescriptionTextContainer>
        <ViewAllJobsLink to="/careers">
          <ViewAllJobsContainer>
            <LeftArrow />
            <ViewAllJobsText>View All Jobs</ViewAllJobsText>
          </ViewAllJobsContainer>
        </ViewAllJobsLink>
        <JobDescriptionTitle>{title}</JobDescriptionTitle>
        <JobDescriptionLocation>{location}</JobDescriptionLocation>
      </JobDescriptionTextContainer>
      <ApplyNowButton onClick={openEmailLink}>Apply Now</ApplyNowButton>
    </JobDescriptionBannerContainer>
  );
};

export default JobDescriptionBanner;
