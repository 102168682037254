import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import BannerImage from '../BannerImage';

export const Container = styled.div`
  padding-top: 5rem;
  background-size: contain;
  @media screen and (min-width: 1500px) {
  }
`;

export const BannerHeader = styled.div`
  margin: 0rem 0 6rem 0;
  min-height: 26rem;
  padding: 8rem 2rem 6rem 2rem;
  background: #f6f4ff;

  @media screen and (min-width: 1100px) {
    margin: 0rem 0 6rem 0;
    padding: 8rem 10rem 6rem 10rem;
  }
`;

export const BannerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerHeading = styled.div`
  color: #202020;
  padding: 0 0 3rem 1.5rem;
  letter-spacing: -0.69px;
  font: 500 5rem/5.8rem ${Roboto};
`;

export const BannerInfo = styled.div`
  color: #000000;
  letter-spacing: 0;
  font: 300 2rem/3rem ${Roboto};
  padding-left: 2rem;
  a {
    text-decoration: none;
  }
`;

export const ContentImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 0.7rem;
  max-width: 80rem;
  margin: 0 auto;
`;

export const Body = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 6rem 0;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const SubHeader = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 1rem;
  margin-bottom: 0;
  @media screen and (max-width: 750px) {
  }
`;

export const AnchorLink = styled.a`
  font-weight: 1.8rem;
  text-decoration: none;
  @media screen and (max-width: 750px) {
  }
`;

export const GetStartedWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 72.5rem;
  margin: 0 auto;
  margin-top: -5rem;
  padding: 0 1rem;
  @media screen and (min-width: 1100px) {
    max-width: 110rem;
  }
`;

export const GetStartedInner = styled.div`
  max-width: 60rem;
  @media screen and (min-width: 1100px) {
    width: 100rem;
    max-width: 110rem;
  }
`;

export const GetStartedTitle = styled.h4`
  color: #252525;
  font: 500 2.5rem ${Roboto};
  letter-spacing: 0;
  line-height: 29px;
  position: relative;
  filter: saturate(0.5);
`;
export const CircleDiv = styled.div`
  height: 70px;
  width: 70px;
  opacity: 0.55;
  background-color: #eae6ff;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: -22px;
  left: -28px;
`;
export const GetStartedList = styled.ol`
  list-style: number;
  margin: 0;

  padding-left: 20px;
`;
export const GetStartedListItem = styled.li`
  color: #252525;
  font: 1.6rem ${Roboto};
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 1rem;
  padding-left: 10px;
`;

export const AppSupportWrapper = styled(GetStartedWrapper)`
  margin-top: 1rem;
`;

export const HuddlText = styled.div`
  width: 60rem;
  font: 500 1.8rem/2.8rem ${Roboto};
  @media screen and (min-width: 1100px) {
    width: 80rem;
  }
`;

export const BannerBg = styled(BannerImage)`
  transform: scale(0.5) translate(-300px, -20px);
  @media screen and (min-width: 1100px) {
    transform: scale(1) translate(0, 0);
  }
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f7f7ff;
  padding: 4rem 0;
  align-items: center;
`;

export const ContactHeading = styled.div`
  font: 500 5rem/5.8rem ${Roboto};
`;

export const ContactSub = styled.div`
  letter-spacing: 0px;
  text-align: left;
  font: 400 2rem/2.8rem ${Roboto};
  color: #808080;
  padding: 1.4rem 0 3rem 0;
`;

export const SupportBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SupportDiv = styled.div`
  padding: 0 3rem;
  &:nth-child(2) {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  @media screen and (max-width: 750px) {
    padding: 0 2rem;
  }
`;

export const SupportTitle = styled.div`
  letter-spacing: 0px;
  text-align: center;
  font: 400 1.6rem/2.6rem ${Roboto};
  color: #818181;
  @media screen and (max-width: 750px) {
    font: 400 1.4rem/2.2rem ${Roboto};
  }
`;

export const SupportMail = styled.a`
  text-decoration: none;
  font: 400 2rem/2.8rem ${Roboto};
  color: #705ddc;
  @media screen and (max-width: 750px) {
    font: 400 1.6rem/2.6rem ${Roboto};
  }
`;

export const AddressDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;

export const AddressText = styled.div`
  margin-left: 1rem;
  font: 400 1.6rem/2.6rem ${Roboto};
  color: #252525;
`;

export const SupportForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: flex-start;
  justify-content: left;
`;

export const EmailInput = styled.input`
  width: 50%;
  min-width: 25rem;
  margin: 1rem 0;
  height: 5rem;
  border: 1px solid #bbb;
  padding: 2rem;
  outline: none;
  &:active,
  &:hover {
    border-color: #ddd;
  }
  font: 400 1.4rem/2rem ${Roboto};
`;
export const DescriptionArea = styled.textarea`
  width: 50%;
  min-width: 25rem;
  margin: 1rem 0;
  min-height: 20rem;
  border: 1px solid #bbb;
  padding: 2rem;
  outline: none;
  font: 400 1.4rem/2rem ${Roboto};
  &:active,
  &:hover {
    border-color: #ddd;
  }
`;
export const SubmitBtn = styled.button`
  padding: 1rem;
  border-radius: 0%.3rem;
  width: 20rem;
  background-color: #7b47ef;
  color: #fff;
  font: 400 1.6rem/2.6rem ${Roboto};
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 1rem;
  ${({ disabled }) => disabled && `pointer-events: none; opacity: 0.5;`}
  &:hover {
    opacity: 0.8;
  }
`;

export const Hint = styled.div`
  color: #ff0000;
  font: 1.2rem/1.6rem ${Roboto};
`;

export const MailSent = styled.div`
  font: 400 2rem/2.8rem ${Roboto};
  color: #1d8102;
`;
