import React from 'react';
import MobileNav from './MobileNav';
import LargeScreenNav from './LargeScreenNav';
import { NavLinksData, SignInLinkData } from './Shared';

const Navbar = ({
  location: { pathname },
  loggedIn,
  logOut,
  toggleOverflow
}) => {
  const { main } = NavLinksData;
  const filterNavElements = pathname.includes('techmahindra');
  let links = main;
  const commonProps = {
    links,
    signInLink: SignInLinkData,
    pathname,
    logOut,
    loggedIn
  };
  return (
    <>
      <MobileNav
        toggleOverflow={toggleOverflow}
        filterNavElements={filterNavElements}
        {...commonProps}
      />
      <LargeScreenNav
        showBetaButton={true}
        filterNavElements={filterNavElements}
        {...commonProps}
      />
    </>
  );
};

export default Navbar;
