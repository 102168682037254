import React from 'react';
import Banner from '../../Banner';
import jobDetails from '../../../data';
import {
  CareersContainer,
  // USPositions,
  CareersTextContainer,
  GalleriesContainer,
  IndiaPositions
} from './styles';
import { data } from '../../Shared/BannerAndFooterData';

/* const USData = jobDetails.filter(({ locations }) => {
  return locations[0].trim().includes('CA');
}); */

const IndiaData = jobDetails.filter(({ locations }) => {
  return locations[0].trim().includes('Hyderabad');
});
const Careers = () => {
  const {
    careers: {
      banner: { title, description }
    }
  } = data;
  return (
    <CareersContainer>
      <Banner title={title} description={description} />

      <GalleriesContainer>
        <CareersTextContainer title="Open Positions" description="" />
        {/* <USPositions country="United States" data={USData} /> */}

        <IndiaPositions country="India" data={IndiaData} />
      </GalleriesContainer>
    </CareersContainer>
  );
};

export default Careers;
