import styled from 'styled-components';
import { Roboto } from '../fonts';

export const BrandSection = styled.div`
  color: #252525;
  text-align: center;
  width: 100%;
  padding: 0 4rem 0 4rem;
  line-height: 2.5rem;
  @media screen and (min-width: 800px) {
  }
`;

export const LogosDiv = styled.div`
  color: #252525;
  text-align: center;
  width: 100%;
  padding: 0 4rem 0 4rem;
  line-height: 2.5rem;
  @media screen and (min-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.div`
  font: 1.2rem ${Roboto};
  margin-bottom: 1rem;
  color: #8c8c8c;
  text-align: center;
  line-height: 2.5rem;
  font-weight: 500;
`;

export const BrandLogoBlock = styled.div`
  margin: 2rem 1rem;
  margin-right: 1.5rem;
`;
