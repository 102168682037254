import * as React from "react";
const ChatIcon = (props) => (
  <svg
  width={44}
  height={38}
  viewBox='0 0 44 38'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41 3.00098L15 3.00097V9.02129H27C28.6569 9.02129 30 10.3644 30 12.0213V21.0551L41 21.0551V3.00098ZM30 24.0551V30.0755C30 31.7323 28.6569 33.0755 27 33.0755H10.0312C9.36291 33.0755 8.71378 33.2986 8.18675 33.7094L3.2296 37.5737C1.91621 38.5975 0 37.6616 0 35.9963V12.0213C0 10.3644 1.34315 9.02129 3 9.02129H12V3.00097C12 1.34412 13.3431 0.000976562 15 0.000976562H41C42.6569 0.000976562 44 1.34412 44 3.00098V21.0551C44 22.712 42.6569 24.0551 41 24.0551H30ZM3 33.9488V12.0213H27V30.0755L10.0312 30.0755C8.69465 30.0755 7.39641 30.5217 6.34234 31.3434L3 33.9488Z'
      fill='url(#paint0_linear_3489_185)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3489_185'
        x1={22}
        y1={0.000976563}
        x2={22}
        y2={38}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF9358' />
        <stop offset={1} stopColor='#D457FF' />
      </linearGradient>
    </defs>
  </svg>
);
export default ChatIcon;
