import styled from 'styled-components';
import Banner from '../../Banner';

export const BannerAndImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background: #f2f1ff;
  height: 23rem;
`;

export const ContactBannerContainer = styled.div`
  position: relative;
  background: #f2f1ff;
`;

export const ContactBanner = styled(Banner)`
  border-bottom: none;
  position: absolute;
  max-height: fit-content;
  padding-top: 0;
  background-color: transparent;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 3rem;
  padding-top: 4rem;
  .banner-circle {
    display: none;
  }
  @media screen and (min-width: 675px) {
    padding-top: 6rem;
    top: 4rem;
  }
`;

export const ContactImageContainer = styled.div`
  padding-top: 17rem;
  background: #f2f1ff;
  @media screen and (min-width: 837px) {
    padding-top: 23rem;
  }
`;

export const ContactImage = styled.img`
  object-fit: scale-down;
  width: 100%;
  background: #f2f1ff;
  @media screen and (min-width: 701px) {
    object-fit: fill;
    height: 55rem;
  }
  @media screen and (min-width: 1081px) {
    height: 30rem;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StreetViewImage = styled.img`
  width: 100%;
`;

export const AddressAndContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    align-items: start;
  }
`;

export const ImageAndAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 24px solid white;
  background: white;
  z-index: 1;
  @media screen and (min-width: 800px) {
    margin: -10rem 0 4rem 15rem;
  }
`;
