import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './styles';

const Submit = ({ content, formId, className }) => (
  <Button form={formId} className={className}>
    {content}
  </Button>
);

Submit.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default Submit;
