import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';
import BottomBanner from '../../BottomBanner';

export const LeadershipContainer = styled.div``;

export const LeadershipImage = styled.img`
  height: 80%;
  width: 100%;
`;

export const LeadershipTextAndMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeadershipTextTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 75%;
  align-self: center;
  font: 300 3rem ${Roboto};
  color: #252525;
  text-align: center;

  @media screen and (min-width: 880px) {
    width: 60rem;
  }
`;

export const InvestorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 5rem;
`;

export const InvestorImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    height: 20rem;
  }
`;

export const InvestorImg = styled.img`
  width: 20rem;
  filter: invert(0.2);
  margin-top: 2rem;
  @media screen and (min-width: 900px) {
    margin-right: 5rem;
    margin-top: 0;
  }
`;

export const PropelInvestorImg = styled(InvestorImg)`
  width: 14rem;
  filter: invert(0.2);
`;

export const LeadershipBottomBanner = styled(BottomBanner)`
  background-color: rgb(250, 249, 254);
  justify-content: center;
  height: 30rem;

  .bottom-banner-title {
    color: black;
  }

  .bottom-banner-text {
    margin-top: 2rem;
    color: #282626;
    font: 300 1.8rem ${Roboto};
    transition: all 0.2s ease-in;
    &:hover {
      background: #7b47ef;
      color: white;
    }
  }

  .bottom-banner-button {
    border: 1px solid #7b47ef;
    height: 4.4rem;
    width: 19.7rem;
    margin-top: 5rem;
    color: #464547;
    font-size: 2rem;
    letter-spacing: -0.35px;
    line-height: 2.4rem;
    background-color: white;
    color: #464547;
  }
`;

export const TeamPhoto = styled.img`
  object-fit: contain;
`;
