import React, { Component } from 'react';
import {
  JoinHuddl,
  Steps,
  SubHeader,
  AnchorLink,
  Slide,
  WebexSteps,
  GetStartedWrapper,
  GetStartedTitle,
  GetStartedList,
  GetStartedListItem,
  // WebexVideo,
  // WebexAllStepsWrapper,
  GetStartedInner,
  CircleDiv
} from '../styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import StepOne from '../images/step_one.svg';
import StepTwo from '../images/step_two.svg';
import StepThree from '../images/step_three.svg';
import StepFour from '../images/step_four.svg';
import StepFive from '../images/step_five.svg';
import StepSix from '../images/step_six.svg';
import StepSeven from '../images/step_seven.svg';
// import StepSeven from '../images/huddl_tool_tip.mp4';
// import onex from './images/img-apps/img-apps@1x.png';
// import twox from './images/img-apps/img-apps@2x.png';
// import threex from './images/img-apps/img-apps@3x.png';
import NextArrow from '../images/nextArrow.svg';
import PrevArrow from '../images/prevArrow.svg';

import ButtonBase from '@material-ui/core/ButtonBase';

class WebexContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      slideIndex: 0,
      slides: 0
    };
  }

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => this.setState({ currentSlide: next })
    // adaptiveHeight: true
  };

  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <ButtonBase
          className="arrow-btn prev"
          onClick={() => this.slider.slickPrev()}
        >
          <img alt="next" src={PrevArrow} />
        </ButtonBase>
        <ButtonBase
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        >
          <img alt="next" src={NextArrow} />
        </ButtonBase>
      </div>
    );
  };

  componentDidMount() {
    // const password = prompt('Please enter password.', '');
    // if (password != null) {
    //   if (password === 'Huddl@12!%#') {
    //     this.setState({ isAuthanticated: true });
    //   }
    // }

    this.showSlides();
  }
  componentDidUpdate(prev, next) {
    var slides = document.getElementsByClassName('mySlides');
    if (slides.length > 0) {
      if (next.slides === 0) {
        this.setState({
          slides: slides.length
        });
        this.showSlides();
      }
    }
    console.log(prev, next, slides);
  }

  prev = () => {
    if (this.state.slideIndex > 0) {
      this.setState({ currentSlide: this.state.slideIndex - 1 });
      this.setState(
        {
          slideIndex: this.state.slideIndex - 1
        },
        this.showSlides()
      );
    }
  };
  next = () => {
    if (this.state.slideIndex === this.state.slides) {
      return;
    } else {
      this.setState({ currentSlide: this.state.slideIndex + 1 });
      this.setState(
        {
          slideIndex: this.state.slideIndex + 1
        },
        this.showSlides()
      );
    }
  };
  showSlides = () => {
    const { slideIndex } = this.state;
    let i;
    const slides = document.getElementsByClassName('mySlides');
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      slides[slideIndex].style.display = 'block';
    }
  };
  render() {
    const { currentSlide } = this.state;
    return (
      <>
        <JoinHuddl>
          <SubHeader>
            {currentSlide === 0 && 'Install huddl.ai from Webex App Hub'}
            {currentSlide === 1 && 'Access huddl.ai with Webex'}
            {currentSlide === 2 && 'Signup with huddl.ai'}
            {currentSlide === 3 && 'Authenticate with Webex'}
            {currentSlide === 4 && 'If asked, paste Webex meeting link'}
            {currentSlide === 5 && 'Take real-time meeting Notes'}
            {currentSlide === 6 && 'You are good to go'}
          </SubHeader>
          <Steps>
            Step {currentSlide + 1} of 7{' '}
            {currentSlide === 6 && (
              <>
                {' '}
                : Experience the next generation online collaborative platform.
              </>
            )}
          </Steps>
        </JoinHuddl>

        <WebexSteps>
          {/* <ContentImage src={MacBook} /> */}
          {/* For fallback slider */}
          {/* <div className="slideshow-container">
  <div className="mySlides fade">
    <img src={StepOne} style={{width:'100%'}} alt="slide1"/>
  </div>

  <div className="mySlides fade">
    <img src={StepTwo} style={{width:'100%'}} alt="slide2"/>
  </div>
  <div className="mySlides fade">
    <img src={StepThree} style={{width:'100%'}} alt="slide2"/>
    
  </div>
  <div className="mySlides fade">
    <img src={StepFour} style={{width:'100%'}} alt="slide2"/>
    
  </div>
  <div className="mySlides fade">
    <img src={StepFive} style={{width:'100%'}} alt="slide2"/>
    
  </div>
  <div className="mySlides fade">
      <WebexAllStepsWrapper>
        <WebexVideo
          autoPlay
          muted
          loop
          src={StepSix}
          alt={'Feature Webex with huddl.ai'}
        />
       </WebexAllStepsWrapper>
    <div className="text">Caption Two</div>
  </div>

  <p className="prev" onClick={this.prev}>&#10094;</p>
  <p className="next" onClick={this.next}>&#10095;</p>
</div> */}
          {this.renderArrows()}
          <Slider ref={c => (this.slider = c)} {...this.settings}>
            <div>
              <Slide src={StepOne}></Slide>
            </div>
            <div>
              <Slide src={StepTwo}></Slide>
            </div>
            <div>
              <Slide src={StepThree}></Slide>
            </div>
            <div>
              <Slide src={StepFour}></Slide>
            </div>
            <div>
              <Slide src={StepFive}></Slide>
            </div>
            <div>
              <Slide src={StepSix}></Slide>
            </div>
            <div>
              <Slide src={StepSeven}></Slide>
            </div>
            {/* <div style={{ display: 'none' }}>
              <WebexAllStepsWrapper>
                <WebexVideo
                  autoPlay
                  muted
                  loop
                  src={StepSeven}
                  alt={'Feature Webex with huddl.ai'}
                />
              </WebexAllStepsWrapper>
            </div> */}
          </Slider>
        </WebexSteps>
        <GetStartedWrapper>
          <GetStartedInner>
            <GetStartedTitle>
              <CircleDiv />
              Get Started
            </GetStartedTitle>
            <GetStartedList>
              <GetStartedListItem>
                Get huddl.ai app from Webex{' '}
                <AnchorLink
                  target="_blank"
                  href="https://apphub.webex.com/applications/huddl-ai-huddl-inc"
                >
                  App Hub
                </AnchorLink>{' '}
              </GetStartedListItem>
              <GetStartedListItem>
                During the Webex meeting, open huddl.ai application from the
                +Apps section
              </GetStartedListItem>
              <GetStartedListItem>
                Login into your huddl.ai account. Signup, if new to huddl.ai
              </GetStartedListItem>
              <GetStartedListItem>
                Authenticate Webex to link it with your huddl.ai account
              </GetStartedListItem>
              <GetStartedListItem>
                Paste Webex meeting URL, if requested (when permissions are not
                given)
              </GetStartedListItem>
              <GetStartedListItem>
                huddl.ai joins your meeting on the side to give flexibility of
                quick note taking, moment capture
              </GetStartedListItem>
              <GetStartedListItem>
                huddl.ai generates the meeting summary and also sends it to
                participants through email. The meeting summary can also be
                accessed by the participants from the huddl.ai app
              </GetStartedListItem>
            </GetStartedList>
          </GetStartedInner>
        </GetStartedWrapper>
      </>
    );
  }
}

export default WebexContent;
