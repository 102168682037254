import React from 'react';

import {
  Body,
  GetStartedTitle,
  GetStartedInner,
  CircleDiv,
  FaqsWrapper,
  FaqsOrederList,
  GetStartedDesc,
  GettingStartedTitle
} from '../../Zoom/ZoomFaqs/styles';
import FaqsComponent from '../../Zoom/ZoomFaqs/Faqs';

const Faqs = ({ faqsList, isIntegration, shrinkHeader }) => {
  return (
    <Body isIntegration={isIntegration} shrinkHeader={shrinkHeader}>
      {isIntegration ? (
        <>
          <GettingStartedTitle shrinkHeader={shrinkHeader}>
            Frequently Asked Questions
          </GettingStartedTitle>
          <GetStartedDesc>
            Here is everything you need to know about huddl.ai
          </GetStartedDesc>
        </>
      ) : null}
      <FaqsWrapper isIntegration={isIntegration}>
        <GetStartedInner>
          <GetStartedTitle>
            <CircleDiv />
          </GetStartedTitle>
        </GetStartedInner>
        <FaqsOrederList>
          {faqsList.map(({ id, ...list }) => (
            <FaqsComponent key={`faq-list-${id}`} id={id} {...list} />
          ))}
        </FaqsOrederList>
      </FaqsWrapper>
    </Body>
  );
};

export default Faqs;
