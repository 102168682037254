const docsList = [
  {
    heading: 'huddl.ai provides two methods of integration with ServiceNow.',
    list: [
      {
        title: 'Service Level Authorization',
        subSections: [
          {
            subHeading:
              'Getting huddl.ai service account ready from ServiceNow side',
            filter: 'service',
            video: 1,
            subList: [
              'Login to your ServiceNow instance through the admin credentials',
              'Search for <b>users</b> in the left menu',
              'Create a new user for this integration',
              'Create a new ServiceNow service account',
              'Fill out the necessary details',
              'Make sure to check the "Internal integration user"checkbox before submitting.'
            ]
          },
          {
            subHeading:
              'Getting huddl.ai service account ready from huddl side',
            filter: 'service',
            video: 2,
            subList: [
              'Go to <a href="https://web.gohuddl.com" target="_blank" rel="noopener noreferrer">web.gohuddl.com</a> and login to the huddl.ai account',
              'From the profile menu click on Admin Dashboard. (Only users with admin rights will see this option)',
              'Go to the connected apps section from the left panel and click on CONNECT AN APP on the right pane',
              'To get the ServiceNow instance details, log in into your ServiceNow instance with the admin account credentials',
              'Search for <b>Application Registry</b> and create a new registry for OAuth API endpoint for external clients',
              'The Redirect URL and Logo URL can be found in the huddl connect an app form at the bottom ',
              'On submission, the client ID and client secret will be generated which can be pasted on huddl form',
              `The instance URL asked on huddl page is the URL you are accessing over the ServiceNow portal<br>
            Ex - https://url_instance_here.service-now.com`,
              'Select Authorization Type as Service Level Authorization and submit the app.',
              'Click on the app to expand it and click on Authorize button and authorize the app using service account credentials created in above step.'
            ]
          }
        ]
      },

      {
        title: 'User Level Authorization',
        subSections: [
          {
            subHeading:
              'Setting up account for user level integration from huddl side',
            filter: 'user',
            subList: [
              'Go to <a href="https://web.gohuddl.com" target="_blank" rel="noopener noreferrer">web.gohuddl.com</a> and login to the huddl.ai account',
              'From the profile menu click on Admin Dashboard. (Only users with admin rights will see this option)',
              'Go to the connected apps section from the left panel and click on CONNECT AN APP on the right pane',
              'To get the ServiceNow instance details, log in into your Servicenow instance with admin credentials.',
              'Search for <b>Application Registry</b> and create a new registry for OAuth API endpoint for external clients',
              'The Redirect URL and Logo URL can be found in the huddl connect an app form at the bottom',
              'On submission, the client ID and client secret will be generated which can be pasted on huddl form',
              `The instance URL asked on huddl page is the URL you are accessing over the ServiceNow portal<br>
              Ex - https://url_instance_here.service-now.com`,
              'Select authentication type as User Level Authorization and submit the app.',
              'Now each user can go to their integrations menu in huddl.ai and integrate ServiceNow.'
            ]
          }
        ]
      }
    ]
  }
];

export default docsList;
