import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const FormBody = styled.div`
  max-width: 60rem;
  margin: 0 12rem;
  @media screen and (max-width: 600px) {
    margin: 0 -4em;
  }
`;

export const Body = styled.div`
  margin: 10em 0 16em 0;
`;

export const SupportTitle = styled.div`
  color: #000000;
  font: 400 3rem ${Roboto};
  line-height: 28px;
  margin: 0 0 0 12rem;
  @media screen and (max-width: 600px) {
    margin: 0 0 0 2rem;
  }
`;

export const SupportDesc = styled.div`
  color: #808080;
  font: 500 1.2rem ${Roboto};
  line-height: 28px;
  margin-left: 12rem;
  @media screen and (max-width: 600px) {
    margin: 0 0 0 2rem;
  }
`;
