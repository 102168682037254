import InviteBot from './assets/InviteBot.svg';
import testPrivateChannel from './assets/testPrivateChannel.svg';
import addApps from './assets/addApps.svg';
const botList = [
  {
    heading: 'Procedure',
    subHeading:
      'huddl.ai bot can be added to the Slack channel in 2 ways, either mention user directly in the message field or click the link "Add an App" at the beginning of your channel.',
    list: [],
    image: []
  },
  {
    heading: 'Adding from the channel',
    subHeading: 'Mention huddl.ai bot user directly in the message field.',
    list: [
      'Select the channel in the Slack app where the huddl.ai bot has to be invited.',
      'In the message field, type @ and select the bot name, here mention @huddl.ai and press enter.',
      'Send the message to the channel. A dialog will come with the option to invite the bot to the channel. Click "Invite Them".'
    ],
    image: [InviteBot]
  },
  {
    heading: 'Add from “Add an App” link',
    subHeading:
      'Go to the channel where the huddl.ai bot is needed to be added.',
    list: [
      'Click on the channel name on the top of the Slack app.',
      'In the modal window, go to the “Integrations” tab.',
      'Click on “Add an App” button.',
      'Search for the huddl.ai and click the “Add” button.'
    ],
    image: [testPrivateChannel, addApps]
  }
];

export default botList;
