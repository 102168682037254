import React from 'react';
import Banner from '../../Banner';
import {
  LeadershipTextTitle,
  LeadershipTextAndMainWrapper,
  TeamPhoto
} from './styles';
import Main from './Main';
import TransitionContainer from '../../Shared/TransitionContainer';
import BottomPortion from './BottomPortion';
import { data } from '../../Shared/BannerAndFooterData';
import { BASE_IMG_URL } from '../../Shared/Config';

const teamUrl = `${BASE_IMG_URL}/team.png`;

const Leadership = ({ scrolledToBotttom }) => {
  const {
    company: {
      banner: { title, description }
    }
  } = data;
  return (
    <div>
      <Banner title={title} description={description} />
      <LeadershipTextAndMainWrapper>
        <LeadershipTextTitle>
          huddl.ai provides effective meetings with state of the art technology.
        </LeadershipTextTitle>
        <TeamPhoto src={teamUrl} alt="Team photo" />
        <Main scrolledToBotttom={scrolledToBotttom} />
      </LeadershipTextAndMainWrapper>
      <TransitionContainer>
        <BottomPortion scrolledToBotttom={scrolledToBotttom} />
      </TransitionContainer>
    </div>
  );
};

export default Leadership;
