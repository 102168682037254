import React from 'react';
import JobDescriptionBanner from './Banner';
import { DescriptionPageContainer, BannerContainerBuffer } from './styles';
import Section from './Section';
import jobDetails from '../../../data';

const openEmailLink = (title, location) => {
  const emailLink = document.createElement('a');
  emailLink.setAttribute(
    'href',
    `mailto:careers@huddl.ai?subject=${title} in ${location}`
  );
  emailLink.click();
};

const JobDescription = ({
  match: {
    params: { jobId }
  }
}) => {
  const job = jobDetails.find(({ id }) => jobId === id);
  const {
    title,
    locations,
    description,
    responsibilities,
    requiredSkills,
    preferredSkills,
    perks
  } = job;
  const location = locations[0];
  return (
    <div>
      <JobDescriptionBanner
        openEmailLink={() => openEmailLink(title, location)}
        jobDescriptionData={{ title, locations }}
      />
      <BannerContainerBuffer />
      <DescriptionPageContainer>
        <Section title="Job Description" data={description} />
        <Section title="Responsibilities" data={responsibilities} />
        <Section title={'Required Skills'} data={requiredSkills} />
        <Section title="Preferred Skills" data={preferredSkills} />
        <Section title="Perks" data={perks} />
      </DescriptionPageContainer>
    </div>
  );
};

export default JobDescription;
