const faqs = [
  {
    id: 1,
    question: 'How do I sign-up with huddl.ai for Zoom?',
    answer: `Signing up with huddl.ai for Zoom is very easy. Go to the Zoom Marketplace and search for the 'huddl.ai' app. Once you find the app, click on it. You will be redirected to the app description page, where you must click on 'install'. 
      Upon authorization, you will just need to integrate with your calendar to start using huddl.ai with Zoom.`
  },
  {
    id: 2,
    question: 'Where do I find the previous meeting summaries?',
    answer: `You can find records of all your meetings by clicking on the 'huddl.ai' app inside the apps section in Zoom . All your meeting summaries are stored sequentially and you can access them anytime!`
  },
  {
    id: 4,
    question: 'Can I add more collaborators to the notes that I am taking?',
    answer:
      'Yes! Everyone who is attending the meeting can contribute towards taking collaborative notes on huddl.ai regardless of whether they have a huddl.ai. Once the meeting starts, Amy (the huddl.ai assistant) sends a chat message with the link to a secure collaborative space where everyone can contribute to taking notes, assigning tasks and updating agendas real-time.'
  },
  {
    id: 5,
    question: 'If I am not the host of the meeting, how can I access huddl.ai?',
    answer:
      'Today, huddl.ai can be accessed by the participants only when the host is also a huddl.ai user and has synced their calendar. In the subsequent releases, we will be able to let each participant host their individual note- taking sessions.'
  },
  {
    id: 6,
    question:
      'I am not able to use huddl.ai for meetings despite having signed in. What should I do?',
    answer:
      'To be able to use huddl.ai in the meetings with Zoom, users have to make sure that the organiser of the meeting is a huddl.ai user. '
  },
  {
    id: 7,
    question: 'Can I share the moments or meeting summary with others?',
    answer:
      'Yes! You definitely can. Just click on share and either copy link to publicly share the moment or send them privately by entering their email address. Not only this, huddl.ai even lets you share any part of the meeting directly to other enterprise tools such as Jira, Servicenow, Hubspot, etc.'
  }
];

export default faqs;
