import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormField from './FormField';
import FormSubmit from './Submit';
import { Form } from './styles';

class FormContent extends Component {
  state = {
    isFresh: true
  };

  /**
   * Find errors in the form using provided validator
   * @returns the error from the validator function.
   */
  findErrors = () => {
    const { formFields, formValues } = this.props;
    for (let formField of formFields) {
      const { validation, field, labelText } = formField;
      const hasError = validation(formValues[field], labelText).error;
      return hasError;
    }
  };

  validateAndSubmit = e => {
    e.preventDefault();
    const { setError, handleSubmit } = this.props;
    const error = this.findErrors();
    if (error) {
      setError(error);
      return;
    }
    handleSubmit();
  };

  setIsFresh = isFresh => {
    this.setState({
      isFresh
    });
  };
  render() {
    const {
      CustomInput,
      formId,
      formValues,
      formFields,
      formSubmitContent,
      onChange,
      onClickSubmit,
      styles,
      className,
      showOtherInputs,
      openOtherInput,
      closeOtherInput
    } = this.props;
    const { isFresh } = this.state;
    return (
      <Form
        className={className}
        onSubmit={this.validateAndSubmit}
        name={formId}
        id={formId}
        style={styles}
      >
        {formFields.map(
          ({
            type,
            field,
            value,
            labelText,
            required,
            attachment,
            validation,
            options,
            autoFocus
          }) => (
            <FormField
              CustomInput={CustomInput}
              field={field}
              type={type}
              labelText={labelText}
              value={formValues[field]}
              validation={validation}
              required={required}
              options={options}
              autoFocus={autoFocus}
              attachment={attachment}
              onChange={
                onChange
                  ? val => {
                      onChange(field, val);
                    }
                  : null
              }
              isFresh={isFresh}
              setIsFormFresh={this.setIsFresh}
              key={`${formId}-field-${field}`}
              showOtherInput={showOtherInputs ? showOtherInputs[field] : null}
              openOtherInput={openOtherInput}
              closeOtherInput={closeOtherInput}
            />
          )
        )}
        <FormSubmit
          className="form-submit"
          formId={formId}
          handleSubmit={this.validateAndSubmit}
          style={{
            display: onClickSubmit ? 'block' : 'none'
          }}
          content={formSubmitContent}
        />
      </Form>
    );
  }
}

FormContent.propTypes = {
  formId: PropTypes.string.isRequired,
  formValues: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
      PropTypes.bool
    ])
  ).isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      labelText: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.string)
      ]),
      validation: PropTypes.func,
      required: PropTypes.bool,
      attachment: PropTypes.bool
    })
  ).isRequired,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  formSubmitContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  styles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

FormContent.defaultProps = {
  onChange: null,
  styles: {},
  formSubmitContent: 'submit'
};

export default FormContent;
