import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  LargeScreenNavContainer,
  LogoTextContainer,
  IndividualLinkContainer,
  LargeScreenLinksContainer,
  LargeScreenNavLink,
  LinkBorderBottom,
  LeftNavAligner,
  RightNavAligner,
  ExternalLinks
} from './styles';
import { HuddlLogoWithTextSvg } from '../../Shared/Icons/HuddlLogoWithText';
import { externalNavigationLinks } from '../Shared';

const LargeScreenNav = ({
  links,
  pathname,
  showBetaButton,
  loggedIn,
  logOut,
  signInLink,
  filterNavElements
}) => (
  <LargeScreenNavContainer showBetaButton={showBetaButton}>
    <LeftNavAligner>
      <LogoTextContainer to="/">
        <HuddlLogoWithTextSvg id={2} />
      </LogoTextContainer>
      <LargeScreenLinksContainer showBetaButton={showBetaButton}>
        {!filterNavElements &&
          links.map(({ key, to, label }) => (
            <IndividualLinkContainer showBetaButton={showBetaButton} key={key}>
              <LargeScreenNavLink to={to}>{label}</LargeScreenNavLink>
              {pathname.includes(to) ? <LinkBorderBottom /> : null}
            </IndividualLinkContainer>
          ))}
      </LargeScreenLinksContainer>
    </LeftNavAligner>
    <RightNavAligner>
      {!filterNavElements &&
        externalNavigationLinks.map(({ key, to, label, isButtonStyle }) => (
          <ExternalLinks
            key={key}
            href={to}
            target="_blank"
            isButtonStyle={isButtonStyle}
          >
            {label}
          </ExternalLinks>
        ))}
    </RightNavAligner>
  </LargeScreenNavContainer>
);

export default withRouter(LargeScreenNav);
