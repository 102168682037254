import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MobileNavContainer = styled.div`
  height: 100%;
  position: absolute;
  width: ${({ open }) => (open ? '100%' : 'fit-content')};
  @media screen and (min-width: 750px) {
    display: none;
  }
`;

export const NavBar = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  z-index: 10;
  position: fixed;
  border-bottom: 1px solid rgb(207, 206, 207);
`;

export const LogoLink = styled(Link)`
  && {
    text-decoration: none;
    position: absolute;
    height: 5rem;
    width: 15rem;
  }
`;

export const HamburgerContainer = styled.div`
  position: relative;
`;

export const MenuHamburger = styled.img`
  right: 10px;
  position: fixed;
  top: 13px;
  z-index: 4;
`;

export const CloseMenuIconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 13px;
  height: 2rem;
  width: 2rem;
  z-index: 2;
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  z-index: 3;
`;
