import axios from 'axios';

export const postSupportData = (email, description) => {
  try {
    const postToSupportUrl = `https://production.gohuddl.com/apps/support/email`;

    const dataToSend = { email, description };
    return axios.post(postToSupportUrl, dataToSend);
  } catch (err) {
    console.error(`failed to submit form ${err}`);
    return false;
  }
};
