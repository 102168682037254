import CbLogo from '../../../assets/press/cb.png';
import SilconAngleLogo from '../../../assets/press/silconangle.png';
import PrnLogo from '../../../assets/press/prn.png';
import UcLogo from '../../../assets/press/uc.png';
import NewyorkTimes from '../../../assets/press/times.png';
import CnbcLogo from '../../../assets/press/cnbc.png';
import EtLogo from '../../../assets/press/et.png';
import TcLogo from '../../../assets/press/tc.png';

// TODO: replace with actual data
export const pressData = [
  {
    date: '17 FEB, 2021',
    title: 'The Race to Fix Virtual Meetings',
    link: 'https://www.nytimes.com/2021/02/17/magazine/video-conference.html',
    source: {
      name: 'The New York Times',
      logo: NewyorkTimes
    }
  },

  {
    date: '20 NOV, 2020',
    title: 'huddl.ai vs Zoom: Which Should You Use?',
    link:
      'https://www.uctoday.com/reviews/collaboration_reviews/team-collaboration-reviews/huddl-ai-vs-zoom-comparison/',
    source: {
      name: 'UC Today',
      logo: UcLogo
    }
  },
  {
    date: '18 NOV, 2020',
    title: 'huddl.ai vs Microsoft Teams: Take Your Pick',
    link:
      'https://www.uctoday.com/reviews/collaboration_reviews/video-conferencing-reviews/huddl-ai-vs-microsoft-teams-comparison/',
    source: {
      name: 'UC Today',
      logo: UcLogo
    }
  },
  {
    date: '09 NOV, 2020',
    title:
      'Tech Mahindra, huddl.ai announce partnership for remote collaboration solutions',
    link:
      'https://economictimes.indiatimes.com/tech/tech-bytes/tech-mahindra-huddl-ai-announce-partnership-for-remote-collaboration-solutions/articleshow/79129728.cms',
    source: {
      name: 'The Economic Times',
      logo: EtLogo
    }
  },
  {
    date: '22 JULY, 2020',
    title:
      'New $6.25M Seed Round Helps huddl.ai Add Intelligence To Remote Meetings',
    link:
      'https://news.crunchbase.com/news/new-6-25m-seed-round-helps-huddl-ai-add-intelligence-to-remote-meetings/',
    source: {
      name: 'Crunchbase News',
      logo: CbLogo
    }
  },
  {
    date: '22 JULY, 2020',
    title:
      'huddl.ai Launches AI Video Meetings: Slack meets Zoom, meets Google Drive',
    link:
      'https://www.prnewswire.com/news-releases/huddlai-launches-ai-video-meetings-slack-meets-zoom-meets-google-drive-301097913.html',
    source: {
      name: 'PR Newswire',
      logo: PrnLogo
    }
  },
  {
    date: '22 JULY, 2020',
    title: 'huddl.ai launches from stealth mode to take on Zoom',
    link: 'https://siliconangle.com/2020/07/22/huddl-stealth-mode-take-zoom/',
    source: {
      name: 'SiliconAngle',
      logo: SilconAngleLogo
    }
  },
  {
    date: '22 JULY, 2020',
    title: 'huddl.ai Launches New AI Video Meeting Solution',
    link:
      'https://www.uctoday.com/collaboration/video-conferencing/huddl-ai-launches-new-ai-video-meetings-solution/',
    source: {
      name: 'UC Today',
      logo: UcLogo
    }
  },
  {
    date: '22 JULY, 2020',
    title: 'huddl.ai wants to bring more intelligence to online meetings',
    link:
      'https://techcrunch.com/2020/07/22/huddl-ai-wants-to-bring-more-intelligence-to-online-meetings/',
    source: {
      name: 'TechCrunch',
      logo: TcLogo
    }
  },
  {
    date: '08 JULY, 2020',
    title:
      'Why companies need to stay in ‘emergency mode’ during Phase two of remote work',
    link:
      'https://www.cnbc.com/2020/07/08/op-ed-companies-in-emergency-mode-during-phase-2-of-remote-work.html',
    source: {
      name: 'CNBC',
      logo: CnbcLogo
    }
  }
];
