import React from 'react';
import {
  SectionContainer,
  SectionTitle,
  SectionTextContainer,
  SectionTextSentence
} from './styles';

const Section = ({ title, data }) => {
  return data.length ? (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      <SectionTextContainer>
        {data
          ? data.map((text, i) => (
              <SectionTextSentence key={i}>{text}</SectionTextSentence>
            ))
          : null}
      </SectionTextContainer>
    </SectionContainer>
  ) : null;
};

export default Section;
