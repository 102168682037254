export const RESOURCES_ROUTE = 'resources';
export const PRICING_ROUTE = 'pricing';
export const DEMO_ROUTE = 'demo';
export const SIGN_IN_ROUTE = 'sign-in';
export const ABOUT_US_ROUTE = 'about-us';
export const PRESS_ROUTE = 'press';
export const CAREER_ROUTE = 'careers';
export const CONTACT_ROUTE = 'contact';
export const BETA_ROUTE = 'beta';
export const JOB_DESCRIPTION_ROUTE = 'careers/:jobId';
export const VIDEO_CATEGORY_ROUTE = 'resources/:category';
export const DOWNLOAD_ROUTE = 'download';
export const MOBILE_JOIN_ROUTE = 'mobile-join';
export const GLOBAL_ACCESS_NUMBERS_ROUTE = 'global-access-numbers';
export const GLOBAL_ACCESS_NUMBERS_V2_ROUTE = 'global-access-numbers-v2';
export const INTEGRATIONS_ROUTE = 'integrations';
export const EULA_ROUTE = 'eula';
export const PRIVACY_POLICY_ROUTE = 'privacy-policy';
export const WEBINAR_REGISTRATION = 'webinar';
export const WEBEX_ROUTE = 'huddl-with-webex';
export const ZOOM_FAQS_ROUTE = 'huddl-with-zoom-faqs';
export const ZOOM_DOCS_ROUTE = 'huddl-with-zoom';
export const BLOG_ROUTE = 'blog';
export const ZOOM_SUPPORT_ROUTE = 'apps-support';
export const TEAMS_INTEGRATION_ROUTE = 'teams';
export const ZOOM_INTEGRATION_ROUTE = 'zoom';
export const WEBEX_INTEGRATION_ROUTE = 'webex';
export const SLACK_INTEGRATION_ROUTE = 'slack';
export const SERVICE_NOW_INTEGRATION_ROUTE = 'service-now';

export const HUDDL_ROUTE = 'huddl';
export const TAVANT_ROUTE = 'tavant';
export const PLATFORM_ROUTE = 'platform';
export const PRODUCT_ROUTE = 'product';
