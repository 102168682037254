import { PORT } from '../../Shared/Config';
export const slackLinks = new Map([
  [
    `http://localhost:${PORT}`,
    'https://letshuddl.slack.com/apps/ATBGQDQLC-dev-huddl'
  ],
  [
    `https://website-dev.huddldev.com`,
    'https://letshuddl.slack.com/apps/ATBGQDQLC-dev-huddl'
  ],
  [`https://www.huddl.ai`, `https://letshuddl.slack.com/apps/A011UREB7DX-huddl`]
]);
const docsList = origin => [
  {
    heading: 'Getting huddl.ai installed with Slack from Slack app directory',
    list: [
      `Go to <a href=${slackLinks.get(
        origin
      )} target="_blank" rel="noopener noreferrer">Slack app directory</a>`,
      'Click on Add to Slack Button',
      'Confirm Permissions and authorize with Slack',
      `In Slack, scroll down to 'Apps' and find huddl.ai and click on it`
    ],
    subSections: []
  },
  {
    heading: 'Getting huddl.ai installed with Slack from huddl.ai',
    list: [
      `Go to <a href=${'https://www.huddl.ai/integrations/slack'} target="_blank" rel="noopener noreferrer">huddl.ai/slack</a>`,
      'Click on get huddl.ai for Slack',
      'Confirm permissions and authorize with Slack ',
      `Open Slack, scroll down to 'Apps' and find huddl.ai`
    ],
    subSections: []
  },
  {
    heading: 'Getting huddl.ai installed with Slack from the Slack app',
    list: [
      'On the left pane scroll to the apps section at the bottom',
      'Click on Add apps',
      'Search for huddl.ai app',
      'Click on Add button when huddl.ai app is available in search results',
      'Give the permissions'
    ],
    subSections: []
  }
];

export default docsList;
