const faqs = [
  {
    id: 1,
    question: 'How to integrate ServiceNow with huddl.ai?',
    answer: `There are 2 ways you can integrate ServiceNow with huddl. Check our getting started section for ServiceNow. <a href="/integrations/service-now">(Previous tab)</a>
    `
  },
  {
    id: 2,
    question: 'Who can integrate ServiceNow in huddl.ai?',
    answer: `huddl.ai users having admin access can integrate ServiceNow in huddl.ai.`
  },
  {
    id: 3,
    question: `I cannot see the Admin Dashboard option in my profile menu in the huddl.ai?`,
    answer: `If you are not able to see the option for Admin Dashboard from your profile menu in huddl.ai,
    then you are not an admin user from huddl.ai. Please contact your organization admin if you need to get access to the admin portal.`
  },
  {
    id: 4,
    question:
      'What is the role required in ServiceNow to make this integration?',
    answer: `You should have an admin role in ServiceNow to make this integration in huddl.ai.`
  },
  {
    id: 5,
    question: 'What is my instance URL of ServiceNow?',
    answer: `instance URL is the base address of your ServiceNow portal account.<br>
    Example URL: https://url_instance_here.service-now.com`
  },
  {
    id: 6,
    question: `What type of integrations does huddl.ai provides with ServiceNow?`,
    answer: `huddl.ai provides two methods of integration with ServiceNow.<br><br>
    <ul> 
    <li>Service Level Authorization</li><br>
    This method uses one time authorization by the admin user. The teammates can use ServiceNow directly from their huddl.ai accounts.<br>
Note : All contents shared from huddl.ai to ServiceNow will be shared on behalf of the authorized admin user account.<br><br>
<li>User Level Authorization</li><br>
With this method every user in the org will have to authorize individually to use ServiceNow from their huddl.ai accounts.<br>
Note : Users will only get access for the tables they have permission to in ServiceNow.
    </ul>
    `
  },
  {
    id: 7,
    question:
      'Will all my organization users who are using huddl.ai can able to use huddl.ai-ServiceNow features?',
    answer: `It depends upon authorization type you choose for this integration.<br>
    If authorization type is Service Level Authorization, then yes, once the admin integrates and authorizes the huddl.ai app, all the organization users will have access to it.<br>
    If authorization type is User Level Authorization, then each user from your organization need to integrate ServiceNow from huddl.ai integrations menu.<br>`
  },
  {
    id: 8,
    question: `Do normal users need to make any changes to use huddl.ai-ServiceNow integration?`,
    answer: `It depends upon authorization type you choose for this integration.<br>
    If authorization type is Service Level Authorization, then no, they can start using ServiceNow features in huddl.ai once integration from admin is successful<br>
    If authorization type is User Level Authorization, then each user from your organization need to integrate ServiceNow from huddl.ai integrations menu.<br>`
  },
  {
    id: 9,
    question: `How to remove ServiceNow integration with huddl.ai?`,
    answer: `Follow the below steps to remove this integration: <br><br>
    <ul>
    <li>Go to web.gohuddl.com and login to the huddl account</li><br>
    <li>From the profile menu click on Admin Dashboard. (Make sure you have admin rights)</li><br>
    <li>Go to the connected apps section from the left panel</li><br>
    <li>Choose the integration for which you want to delete</li><br>
    <li>And click on the DELETE button on the bottom right</li><br>
    <li>Confirmation will be asked before deletion of integration</li><br>
    <li>Click on Yes to remove this integration, click on No otherwise.</li><br>
    </ul>`
  },
  {
    id: 10,
    question: `What are data/features from huddl.ai, I can push into ServiceNow`,
    answer: `Sync your huddl account with ServiceNow to send all the data generated during your meeting to ServiceNow. You can send: <br><br>
    <ul>
      <li>Notes taken in the meeting</li><br>
      <li>Tasks assigned to you or assigned by you</li><br>
      <li>Moments captured in the meeting</li><br>
      <li>Post-meeting summary (Motes)</li><br>
    </ul>
`
  },
  {
    id: 11,
    question: `How to use ServiceNow in huddl.ai meetings`,
    answer: `You can use ServiceNow during a meeting as well as outside meetings.<br><br>
    <ul>
      <li>Once you are in the meeting, You will see the ServiceNow icon in different sections such as the notes app, overflow menu for each task as well as moments.</li><br>
      <li>Click on this ServiceNow icon and follow the steps to push data from Huddl to ServiceNow.</li><br>
    </ul>`
  },
  {
    id: 12,
    question: `How to disconnect ServiceNow integration with huddl.ai?`,
    answer: `Follow the below steps to disconnect this integration: <br><br>
    <ul>
    <li>Go to web.gohuddl.com and login to the huddl account</li><br>
    <li>From the profile dropdown click the Integrations option</li><br>
    <li>Choose the SeviceNow integration in Productivity integrations list</li><br>
    <li>You will see a disconnect button next to ServiceNow integration icon.</li><br>
    <li>Click on Diconnect button for disconnecting ServiceNow integration with huddl.ai.</li><br>
    To reconnect you can always click the connect button again.
    </ul>
    `
  }
];

export default faqs;
