const faqs = [
  {
    id: 1,
    question: 'How do I sign-up for huddl.ai?',
    answer:
      'Signing up with huddl.ai is very easy. Click on the Sign-up button on the app launch page(you can also click on "Try for free" on huddl.ai. You then can choose to sign-up using an O365 account or Google account. '
  },
  {
    id: 2,
    question: 'Where do I find the previous meeting summaries?',
    answer: `You can find records of all your meetings on <a href="https://web.gohuddl.com" target="_blank">web.gohuddl.com</a>. Once you login, you can navigate to any of the meetings from the past and view the entire meeting summary. Since it’s a web-based application, you can access all your meeting summaries at a single place from anywhere!`
  },
  // {
  //   id: 3,
  //   question: `I have a huddl.ai account and had used Google to create it. I don't see that option on Webex anymore. What should I do?`,
  //   answer: `Currently, Webex doesn’t offer the option to sign in/sign up using G-suite accounts. However, any existing huddl.ai user can still login to the same account by clicking on update/reset password under their profile picture on the web app (<a href="https://web.gohuddl.com" target="_blank">web.gohuddl.com</a>).`
  // },
  {
    id: 4,
    question: 'Can I add more collaborators to the notes that I am taking?',
    answer:
      'Yes! All the participants can contribute in taking notes on huddl.ai for a meeting. Once the organizer turns on the "open together" feature, each participant  can start taking collaborative notes'
  },
  {
    id: 5,
    question: 'If I am not the host of the meeting, how can I access huddl.ai?',
    answer:
      'Today, huddl.ai can be accessed by the participants only when the host has turned on the open-together feature. In the subsequent releases, we will be able to let each participant host their individual note-taking sessions.'
  },
  {
    id: 6,
    question:
      'I am not able to use huddl.ai for meetings despite having signed in. What should I do?',
    answer:
      'To be able to use huddl.ai in the meetings with Webex, users have to make sure that they have authenticated Webex from the account which is hosting that particular meeting. In case you are using multiple Webex accounts, you can always disconnect and reconnect the desired account. Huddl.ai’s interface would guide you through it'
  },
  {
    id: 7,
    question: 'Can I share the moments or meeting summary with others?',
    answer:
      'Yes! You definitely can. Just click on share and either copy link to publicly share the moment or send them privately by entering their email address. Not only this, huddl.ai even lets you share any part of the meeting directly to other enterprise tools such as Jira, ServiceNow, Hubspot, etc.'
  }
];

export default faqs;
