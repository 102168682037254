import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { InputText } from '../styles';
import { Roboto } from '../../../Shared/fonts';

export const RadioButtonLabel = styled.label`
  position: relative;
  display: flex;
  color: black;
  width: 75%;
  ${({ isFileType }) =>
    isFileType
      ? `
      height: 4rem;
      cursor: pointer;
    `
      : 'height: fit-content;'}
  margin: 3rem 0;
  padding: 0.25rem 0;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  margin: 1.5rem 0 0 0.5rem;
`;

export const RadioFormControlLabel = styled(FormControlLabel)`
  && {
    .custom-label-text {
      color: #535353;
      font-size: 1.25rem;
    }
  }
`;

export const StyledRadioCheckBox = styled(Radio)`
  && {
    svg {
      fill: black;
    }
  }
`;

export const RadioButtonLabelText = styled(InputText)`
  left: 0.5rem;
  top: -0.5rem;
  color: #535353;
`;

export const OtherInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OtherInput = styled.input`
  width: 75%;
  font: 300 1.5rem ${Roboto};
  color: rgba(255, 255, 255, 0.75);
  outline: 0;
  border: 0;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: black;
`;
