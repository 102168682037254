import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';

export const GlobalAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  margin-bottom: 10rem;
  min-height: 65rem;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 75%;
  align-self: center;
  font: 300 3rem ${Roboto};
  color: #252525;
  text-align: center;
  @media screen and (min-width: 880px) {
    width: 60rem;
  }
`;

export const TableContainer = styled(Paper)`
  && {
    width: 80%;
    max-width: 100rem;
    margin: 0 auto;
    margin-bottom: 15rem;
    @media screen and (max-width: 767px) {
      width: 90%;
    }
  }
`;

export const TableHeadRow = styled(TableHead)`
  && {
    background-color: #f8ffff;
    th {
      color: #7b47ef;
    }
    td {
      color: #616161;
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font: 300 1.4rem ${Roboto};
    @media screen and (max-width: 767px) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
`;

export const TableCellNotVisibleOnMobile = styled(StyledTableCell)`
  && {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`;

export const DialInNumber = styled(TableCellNotVisibleOnMobile)`
  && {
    min-width: 16rem;
  }
`;
export const SearchInput = styled.input`
  padding: 1rem;
  width: 80%;
  max-width: 100rem;
  margin: 0 auto 4rem auto;
  border-radius: 5px;
  border-style: solid;
  border: 0.5px solid rgba(151, 151, 151, 0.74);
  font: 300 1.6rem ${Roboto};
  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #929292;
    letter-spacing: -0.21px;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;
