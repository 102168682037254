import { supportFormValidator, validateFormEmail } from './validator';

export const formFields = integration => [
  {
    field: 'subject',
    labelText: `A problem with huddl for ${integration}`,
    required: true,
    type: 'text',
    validation: supportFormValidator,
    autoFocus: false
  },
  {
    field: 'email',
    labelText: 'Your email address',
    required: true,
    type: 'text',
    validation: validateFormEmail,
    autoFocus: false
  },
  {
    field: 'description',
    labelText: 'Description',
    required: false,
    type: 'textarea',
    validation: supportFormValidator,
    autoFocus: false
  }
];
