import * as React from "react";
const SpeakerDetectionIcon = (props) => (
  <svg
    width={44}
    height={45}
    viewBox='0 0 44 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8285 0C11.7239 0 10.8285 0.895432 10.8285 2V15.4054C10.8285 16.3065 11.9265 16.7479 12.5502 16.0976L15.4569 13.067C15.8341 12.6737 16.3554 12.4514 16.9003 12.4514H26.7216C27.8262 12.4514 28.7216 11.556 28.7216 10.4514V2C28.7216 0.89543 27.8262 0 26.7216 0H12.8285ZM28.8621 27.8712C32.0663 27.8712 34.5421 25.3524 34.5421 22.3939C34.5421 19.4353 32.0663 16.9166 28.8621 16.9166C25.6578 16.9166 23.182 19.4353 23.182 22.3939C23.182 25.3524 25.6578 27.8712 28.8621 27.8712ZM28.8621 30.8712C33.6559 30.8712 37.5421 27.0758 37.5421 22.3939C37.5421 17.712 33.6559 13.9166 28.8621 13.9166C24.0682 13.9166 20.182 17.712 20.182 22.3939C20.182 27.0758 24.0682 30.8712 28.8621 30.8712ZM11.3251 26.8213C12.6879 26.8213 13.7925 25.7166 13.7925 24.354C13.7925 22.9915 12.6879 21.8868 11.3251 21.8868C9.9624 21.8868 8.85779 22.9915 8.85779 24.354C8.85779 25.7166 9.9624 26.8213 11.3251 26.8213ZM11.3251 29.8213C14.3447 29.8213 16.7925 27.3735 16.7925 24.354C16.7925 21.3346 14.3447 18.8868 11.3251 18.8868C8.3056 18.8868 5.85779 21.3346 5.85779 24.354C5.85779 27.3735 8.3056 29.8213 11.3251 29.8213ZM28.3807 33.8712C34.4152 33.8712 39.2609 37.5815 40.7844 42H15.9768C17.5003 37.5815 22.3463 33.8712 28.3807 33.8712ZM15.4672 42C15.461 42 15.4614 41.9989 15.4673 42H15.4672ZM41.294 45C42.9509 45 44.328 43.6368 43.9217 42.0305C42.3508 35.82 35.9937 30.8712 28.3807 30.8712C25.2642 30.8712 22.3582 31.7005 19.9047 33.1062C17.7654 31.1099 14.711 29.8213 11.3179 29.8213C6.06516 29.8213 1.62415 32.9095 0.132921 36.9303C-0.443217 38.4838 0.941165 39.8666 2.59802 39.8666H13.6008C13.2828 40.5657 13.0271 41.2891 12.8395 42.0305C12.4332 43.6368 13.8103 45 15.4672 45H41.294ZM11.3179 32.8213C13.7409 32.8213 15.8478 33.6548 17.3813 34.889C16.6766 35.4963 16.0338 36.1588 15.4622 36.8666H3.48856C4.86268 34.6315 7.74717 32.8213 11.3179 32.8213Z'
      fill='url(#paint0_linear_3500_40)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3500_40'
        x1={32.9533}
        y1={10.8933}
        x2={11.5537}
        y2={48.4145}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
    </defs>
  </svg>
);
export default SpeakerDetectionIcon;
