import { validateEmail } from '../../../Shared/utils';
export const supportFormValidator = (data, field) => {
  return data && data.length
    ? { valid: true }
    : {
        error: `${field.includes('A problem') ? 'Subject' : field} is required`
      };
};

export const validateFormEmail = (data, field) => {
  return validateEmail(data)
    ? { valid: true }
    : { error: `${field} is invalid` };
};
