import React from 'react';
import { TeamGalleryComponent, TeamGalleryAndTextContainer } from './styles';
import { GalleryTextContainer } from '../styles';
import { TeamData } from '../team';

const TeamGallery = ({ containerRef, ratio, scrolledToBotttom }) => {
  const multiplier = Math.min(ratio * (ratio / 2), 1);
  const opacityAmount = multiplier * 1;
  const opacity = `${opacityAmount}`;
  return (
    <TeamGalleryAndTextContainer
      ref={containerRef}
      style={{ opacity: scrolledToBotttom ? '1' : opacity }}
    >
      <GalleryTextContainer
        title="Team"
        description="Our goal is to ensure that huddl upholds an inclusive environment where all people feel that they are equally respected and valued. We are committed to scaling this culture as we expand."
      />
      <TeamGalleryComponent data={TeamData} />
    </TeamGalleryAndTextContainer>
  );
};

export default TeamGallery;
