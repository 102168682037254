import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import { GetStartedList } from '../../Zoom/ZoomDocs/styles';

export const SubSection = styled.div`
  padding-left: 10px;
`;

export const GetStartedSubList = styled(GetStartedList)`
  padding-left: 3.2em;
`;

export const SubSectionTitle = styled.h4`
  color: #252525;
  font: 500 2rem ${Roboto};
  letter-spacing: 0;
  line-height: 29px;
  position: relative;
  filter: saturate(0.5);
  margin: 0;
`;

export const SubHeading = styled.div`
  color: #252525;
  font: 1.6rem ${Roboto};
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 0.3rem;
  text-align: none;
`;
