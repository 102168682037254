import React from 'react';
import { LeadershipBottomBanner } from './styles';

export const BottomBanner = ({ containerRef, ratio, scrolledToBotttom }) => {
  const multiplier = Math.min(ratio * (ratio / 2), 1);
  const opacityAmount = multiplier * 1;
  const opacity = `${opacityAmount}`;
  return (
    <LeadershipBottomBanner
      containerRef={containerRef}
      style={{ opacity: scrolledToBotttom ? '1' : opacity }}
      title="Join our team"
      text="Interested in working together? We are too!"
      buttonText="See Open Positions"
      toLink="/careers"
    />
  );
};

export default BottomBanner;
