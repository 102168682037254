import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import BannerImage from '../BannerImage';

export const Container = styled.div`
  padding-top: 5rem;

  background-size: contain;

  @media screen and (min-width: 1500px) {
  }
`;

export const BannerHeader = styled.div`
  margin: 0rem 0 6rem 0;
  min-height: 26rem;
  padding: 8rem 2rem 6rem 2rem;
  background: #f6f4ff;

  @media screen and (min-width: 1100px) {
    margin: 0rem 0 6rem 0;
    padding: 8rem 10rem 6rem 10rem;
  }
`;

export const BannerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerHeading = styled.div`
  color: #202020;
  padding: 0 0 3rem 1.5rem;
  letter-spacing: -0.69px;
  font: 500 5rem/5.8rem ${Roboto};
`;

export const WebexAddBtn = styled.div`
  box-sizing: border-box;
  height: 4rem;
  width: 19.4rem;
  border: 1px solid #705ddc;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  color: #705ddc;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  justify-content: center;
  /* cursor: pointer; */
  opacity: 0.2;
  display: none;
`;

export const BannerInfo = styled.div`
  color: #000000;
  letter-spacing: 0;
  font: 300 2rem/3rem ${Roboto};
  padding-left: 2rem;
`;

export const ContentImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 0.7rem;
  max-width: 80rem;
  margin: 0 auto;
`;

export const Body = styled.div`
  width: 100%;
  margin: ${({ isIntegration }) => (isIntegration ? '12rem auto' : '0 auto')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 6rem 0;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const GettingStartedTitle = styled.div`
  color: #000000;
  font: 500 3rem ${Roboto};
  line-height: 28px;
  margin: 0 -0.5em;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
    padding: 0;
  }
`;

export const GettingStartedDesc = styled.div`
  color: #808080;
  font: 500 1.2rem ${Roboto};
  line-height: 28px;
  margin: 0 -1em;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
    padding: 0;
  }
`;

export const JoinHuddl = styled.div`
  width: 78rem;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: 750px) {
  }
`;

export const SubHeader = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 1rem;
  margin-bottom: 0;
  @media screen and (max-width: 750px) {
  }
`;

export const AnchorLink = styled.a`
  font-weight: 1.8rem;
  text-decoration: none;
  @media screen and (max-width: 750px) {
  }
`;

export const Slide = styled.img``;

export const Steps = styled.p`
  color: #202020;
  font: 1.2rem ${Roboto};
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 3rem;
`;

export const GetStartedWrapper = styled.div`
  margin: ${({ isIntegration }) => (isIntegration ? '0 12rem' : '0 auto')};
  position: relative;
  max-width: 72.5rem;
  margin-top: -5rem;
  padding: 0 1rem;
  @media screen and (min-width: 1100px) {
    max-width: 110rem;
  }
  @media screen and (max-width: 600px) {
    margin: 0 auto;
    padding: 0 1rem;
  }
`;

export const GetStartedInner = styled.div`
  max-width: 60rem;
  margin-top: 6rem;
  @media screen and (min-width: 1100px) {
    width: 100rem;
    max-width: 110rem;
  }
`;

export const GetStartedTitle = styled.h4`
  color: #252525;
  font: 500 2rem ${Roboto};
  letter-spacing: 0;
  line-height: 29px;
  position: relative;
  filter: saturate(0.5);
`;

export const SubTitle = styled(GetStartedTitle)`
  margin-bottom: 0.5rem;
`;

export const CircleDiv = styled.div`
  height: 70px;
  width: 70px;
  opacity: 0.55;
  background-color: #eae6ff;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: -22px;
  left: -28px;
`;

export const SubCircleDiv = styled(CircleDiv)`
  height: 12px;
  width: 12px;
  top: 8px;
  left: -22px;
  /* background-color: transparent; */
`;
export const GetStartedList = styled.ol`
  list-style: number;
  margin: 0;
  padding-left: 20px;
  a {
    text-decoration: none;
  }
  span {
    color: #808080;
  }
`;
export const SubSection = styled.div`
  margin-left: 4rem;
`;
export const SubHeading = styled.div``;

export const GetStartedListItem = styled.li`
  color: #252525;
  font: 1.6rem ${Roboto};
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 0.3rem;
  padding-left: 10px;
  text-align: none;
`;

export const SubHeadingTitle = styled(GetStartedListItem)`
  font: 500 2rem ${Roboto};
  margin-top: 3rem;
`;

export const SubListContainer = styled.div``;

export const VideoText = styled.div`
  display: flex;
  flex-direction: column;
  color: #252525;
  font: 1.6rem ${Roboto};
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 0.3rem;
  margin-top: 1rem;
  text-align: none;
`;

export const BannerBg = styled(BannerImage)`
  transform: scale(0.5) translate(-300px, -20px);
  @media screen and (min-width: 1100px) {
    transform: scale(1) translate(0, 0);
  }
`;

export const BottomText = styled.div`
  margin-top: 1rem;
  padding: 5rem 0 1rem 0;
  width: 100%;
  margin: 0 auto;
  margin-top: 5rem;
  font: 400 2rem/2.8rem ${Roboto};
  color: #202020;
  border-top: 1px solid #ddd;
  a {
    color: #5a43d6;
    text-decoration: none;
  }
`;

export const GetHuddlBtn = styled.a`
  text-decoration: none;
  padding: 1rem 1.5rem;
  border: 1px solid #7b47ef;
  color: #7b47ef;
  background: transparent;
  font: 500 1.4rem/1.8rem ${Roboto};
  width: 17rem;
  margin: 2rem 0 0.5rem 1.5rem;
  border-radius: 0.3rem;
  display: block;
`;
