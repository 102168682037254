import React from 'react';

import {
  BannerHeader,
  BannerBlock,
  BannerHeading,
  BannerInfo,
  GetHuddlBtn
} from './styles';

const ZoomHeader = ({ heading, info }) => {
  return (
    <BannerHeader>
      <BannerBlock>
        <BannerHeading>{heading}</BannerHeading>
      </BannerBlock>
      <BannerInfo>{info}</BannerInfo>
      <GetHuddlBtn
        href="https://marketplace.zoom.us/apps/qucidsAtRIuNlQiGJT_XdA"
        target="_blank"
      >
        Get huddl.ai for Zoom
      </GetHuddlBtn>
    </BannerHeader>
  );
};

export default ZoomHeader;
