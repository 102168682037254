const APPLE_STORE = 'icons/App_store.svg';
const CHROME_LOGO = 'icons/google-chrome%400.5x.svg';
const PLAY_STORE = 'icons/Play_Store.svg';

export const AppsData = [
  {
    title: 'Web extension',
    description:
      'The huddl.ai Extension for Browsers allows you to start or schedule your huddl.ai meeting with a single click from your browser or within Google calendar.',
    icons: [
      {
        src: CHROME_LOGO,
        target:
          'https://chrome.google.com/webstore/detail/huddl-web-extension/nogefiedhjehnggloibnlildkdcmmdlp'
      }
    ]
  }
];

export const downloadData = {
  Mac: {
    fullName: 'Mac'
  },
  Linux: {
    fullName: 'Linux'
  },
  Win: {
    fullName: 'Windows'
  }
};

export const MobileLogos = [
  {
    src: APPLE_STORE,
    url: 'https://itunes.apple.com/us/app/huddl-ai/id1324897393?mt=8'
  },
  {
    src: PLAY_STORE,
    url: 'https://play.google.com/store/apps/details?id=com.gohuddl.huddlapp'
  }
];

export const DESKTOP_LATEST_VERSION_API = `https://production.gohuddl.com/apps/versions/desktop?buildType=release`;
