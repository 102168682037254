import styled, {css} from 'styled-components';

export const PlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div``;
export const DescriptionText = styled.div`
 ${({withMargin}) => withMargin && ` margin: 2rem 0;`}
`;
export const ContactButton = styled.a`
  text-decoration: none;
  padding: 1rem 1.5rem;
  border: 1px solid #7b47ef;
  color: #7b47ef;
  background: transparent;
  font: 500 1.4rem/1.8rem 'Roboto';
  width: 11rem;
  border-radius: 0.3rem;
  text-align: center;
  display: block;
`;
export const PlatformDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 2rem 2rem 3rem;
  align-self: center;
  justify-content: center;

  @media screen and (min-width: 600px) and (max-width: 1280px) {
    padding: 5.2rem 12rem 3rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 5.2rem 10rem 3rem;
    width: 126rem;
    justify-content: flex-start;
  }
`;

export const PlatformCapabilities = styled.div`
  display: flex;
  ${({isSticky}) => isSticky && css`
    position: sticky;
    z-index: 2;
    top: 6rem;
    background: #FFFFFF;
    padding-top: 6px;
  `}
`;
export const PlatformCapabilitiesItem = styled.div`
  flex: 1;
  padding: 24px;
  border: 1px solid transparent;
  cursor: pointer;
  
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
    
  ${({isActive}) => isActive && css`
    background: #FFFFFF;
    border: 1px solid #EDEAFB;
    box-shadow: 0px 10px 24px rgba(163, 150, 232, 0.2);
    border-radius: 20px;
  
  `}
  @media screen and (max-width: 600px) {
    padding: 18px;
  }
`;
export const PlatformCapabilitiesItemText = styled.h5`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #202020;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;
export const PlatformCapabilitiesItemDesc = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #808080;
  margin-bottom: 0;

  ${({isHidden}) => isHidden && css`
    height: 0;
    visibility: hidden;
    margin: 0;
  `}

  @media screen and (max-width: 600px) {
    height: 0;
    visibility: hidden;
    margin: 0;
  }
`;



export const PlatformItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 2rem 2rem 3rem;
  align-self: center;
  margin-top: 5rem;
  @media screen and (max-width: 600px) {
    padding: 2rem 0;
    margin-top: 0;
  }


`
export const PlatformItem = styled.div`
  background: #FAF8FD;
  border: 1px solid #EDEAFB;
  border-radius: 20px;
  margin: 4rem 1rem;
  cursor: pointer;
  width: 30rem;
  padding: 3.2rem;
  margin: 2rem;
  position: relative;
  transition: all 0.125s ease-in-out;
  z-index: 1;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    /* box-shadow: 0 0px 2px 1px rgba(183, 153, 229, 1); */
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 33rem;
  }
`
export const PlatformItemIcon = styled.div`
  margin-top: 1.4rem;
`
export const PlatformItemTitle = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.686274px;
  color: #202020;
`
export const PlatformItemDesc = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.686274px;
  color: #808080;
`

export const AISection = styled.div`
  padding: 0 12rem;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding: 0 2rem;
  }
`;
export const AISectionTitle = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: -0.686274px;
  color: #202020;
`;
export const AISectionDesc = styled.p`

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.686274px;

  color: #808080;
  max-width: 80rem;
`;
  
export const ContactUsSection = styled.div`
  border-top: 1px solid #EDEAFB;
  padding: 4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 4rem 0;
  }
`;
export const ContactUsSectionTitle = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #565656;
  margin-top: 0;
`
export const ContactUsSectionButton = styled.a`
  text-decoration: none;
  padding: 1rem 1.5rem;
  border: 1px solid #7b47ef;
  color: #FFFFFF;
  background: #7B47EF;
  font: 500 1.4rem/1.8rem 'Roboto';
  width: 11rem;
  border-radius: 0.3rem;
  text-align: center;
  display: block;
`