import React, { Component } from 'react';
import { Container } from './styles';
import ZoomHeader from '../ZoomHeader';

import ZoomDocsContent from './ZoomDocsContent';

class ZoomDocs extends Component {
  render() {
    const heading = 'huddl.ai app for Zoom';
    const bannerInfo = `huddl.ai lets users maintain a system of record for the meetings
    inside of Zoom. huddl.ai generates meeting analysis along with
    giving users a collaborative space for note taking and task
    management. Organizers can make sure that the meetings are run more
    structurally by allocating and assigning agenda topics.`;
    return (
      <Container>
        <ZoomHeader heading={heading} info={bannerInfo} />
        <ZoomDocsContent />
      </Container>
    );
  }
}

export default ZoomDocs;
