import React, { useRef, useEffect } from 'react';
import {
  DescriptionContainer,
  DescriptionTextAndTitleContainer,
  DescriptionTextAndTitleContainerInner,
  DescriptionTitle,
  DescriptionText,
  DescriptionImageContainer,
  DescriptionMainImage,
  DescriptionImage
} from './styles';

const IndividualDescription = ({
  i,
  title,
  description,
  imageUrl,
  backgroundImageUrl,
  containerRef,
  ratio,
  scrolledToBotttom,
  path
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const handler = () => {
      ref && ref.current && ref.current.play();
    };
    ref &&
      ref.current &&
      ref.current.addEventListener('canplaythrough', handler);
  }, [ref, imageUrl]);
  return (
    <DescriptionContainer key={`${title}_i`} index={i}>
      <DescriptionTextAndTitleContainer i={i}>
        <DescriptionTextAndTitleContainerInner>
          <DescriptionTitle>{title}</DescriptionTitle>
          {description.map(text => (
            <DescriptionText>{text}</DescriptionText>
          ))}
        </DescriptionTextAndTitleContainerInner>
      </DescriptionTextAndTitleContainer>
      <DescriptionImageContainer i={i} ref={containerRef}>
        {imageUrl && (
          <DescriptionMainImage
            ref={ref}
            autoplay
            muted
            loop
            src={imageUrl}
            alt={`Feature ${title}`}
          />
        )}
        {backgroundImageUrl && (
          <DescriptionImage src={backgroundImageUrl} alt={`Feature ${title}`} />
        )}
      </DescriptionImageContainer>
    </DescriptionContainer>
  );
};

export default IndividualDescription;
