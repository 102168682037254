import React from 'react';
import { FormTextArea } from './styles';

export const TextArea = ({
  onChange,
  placeholder,
  handleTouchedState,
  touched,
  label,
  value,
  labelText,
  status,
  focus,
  isFileType,
  setIsFormFresh,
  field
}) => (
  <FormTextArea
    className="form-text-area"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

export default TextArea;
