import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import {
  MobileJoinBanner,
  MobileDescription,
  IconContainer,
  MobilePhoneImage,
  OpenInAppContainer,
  OneTapJoin
} from './styles';
import {
  getStoreComponent,
  getMeetingIdFromRedirectQueryParam
} from './helpers';
import { BASE_IMG_URL, BASE_WEBCLIENT_URL } from '../../Shared/Config';
import { HuddlLogoSvg } from '../../Shared/Icons/HuddlLogo';
const PHONE_DISPLAY_SRC = 'mobile_display.png';

const appStoreComponent = getStoreComponent();
const PTSN_JOIN = '+1-844-464-8335';

class MobileJoin extends Component {
  render() {
    const { redirect } = queryString.parse(this.props.location.search);
    let redirectDecoded = '';

    // Example of redirectDecoded string: meeting/913860/motes
    if (redirect && redirect !== 'undefined') {
      redirectDecoded = decodeURIComponent(redirect);
    }

    const redirectHref = `${BASE_WEBCLIENT_URL}${redirectDecoded || ''}`;
    const meetingId = getMeetingIdFromRedirectQueryParam(redirectDecoded);
    const meetingIdCode = meetingId ? `,,${meetingId}#` : '';
    const joinPTSNText = `${PTSN_JOIN}${meetingIdCode}`;

    return (
      <div>
        <MobileJoinBanner title="Join Meeting">
          <MobileDescription>
            First time using huddl.ai? Please install the application and join
            your meeting.
          </MobileDescription>
          <IconContainer>{appStoreComponent}</IconContainer>

          <MobileDescription>
            If huddl.ai is installed, please click ‘Open in App’
          </MobileDescription>
          <OpenInAppContainer target="_blank" href={redirectHref}>
            <HuddlLogoSvg />
            <span>Open in App</span>
          </OpenInAppContainer>

          <MobileDescription>For one click dial:</MobileDescription>

          <OneTapJoin href={`tel:${joinPTSNText}`}>{joinPTSNText}</OneTapJoin>

          <MobileDescription>
            Locate your{' '}
            <Link
              to={`/global-access-numbers${meetingId ? `/${meetingId}` : ''}`}
            >
              local access number
            </Link>
          </MobileDescription>
          <MobilePhoneImage src={`${BASE_IMG_URL}/${PHONE_DISPLAY_SRC}`} />
        </MobileJoinBanner>
      </div>
    );
  }
}

export default MobileJoin;
