import React from 'react';
import {
  ThankYouContainer,
  ThankYouTextContainer,
  ThankYouTitle,
  ThankYouDescription
} from './styles';

const ThankYou = props => (
  <ThankYouContainer>
    <ThankYouTextContainer>
      <ThankYouTitle>Thank you for registering!</ThankYouTitle>
      <ThankYouDescription>
        Our team will get back to you shortly
      </ThankYouDescription>
    </ThankYouTextContainer>
  </ThankYouContainer>
);

export default ThankYou;
