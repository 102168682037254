import styled from 'styled-components';
import Positions from './Positions';
import TextContainer from '../../Shared/TextContainer';
import { Roboto } from '../../Shared/fonts';

export const CareersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const USPositions = styled(Positions)`
  border-bottom: 1px solid #979797;
  max-width: 70%;
`;

export const IndiaPositions = styled(Positions)`
  /* max-width: 70%; */
  margin-top: 1rem;
`;

export const CareersTextContainer = styled(TextContainer)`
  .text-container-title {
    font: 300 3rem ${Roboto};
    color: #252525;
  }

  .text-container-description {
    font: 300 1.8rem ${Roboto};
    width: 100%;
  }
  margin: 3rem 0 1rem 0;
`;

export const GalleriesContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3rem;
  align-self: center;
  width: 70%;
`;

export const GalleryWrapper = styled.div`
  width: 75%;
`;
