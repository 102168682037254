export const data = {
  features: {
    route: '/features',
    banner: {
      title: `Generate knowledge from your meetings`,
      description: `Meet anywhere and let huddl take care of the grunt work. Huddl ensures that all the meeting data is captured, prioritized and delivered to the relevant participants through our state of the art AI technology`
    }
  },
  pricing: {
    route: '/pricing',
    banner: {
      title: 'huddl.ai for Teams',
      description:
        'Project decisions,meeting notes, action items, messages, they all live together in huddl.ai.'
    }
  },
  demo: {
    route: '/demo',
    banner: {
      title: 'Request a Demo',
      description:
        'Seeing is believing! Schedule a live demo with one of our product specialists.'
    }
  },
  company: {
    route: '/company',
    banner: {
      title: 'Company',
      description:
        'huddl.ai is the collaboration hub that brings the right people, information and tools together to get work done.'
    }
  },
  careers: {
    title: '/careers',
    banner: {
      title: 'Grow with us',
      description:
        "A sense of mutual respect and mindfulness permeates our culture - in fact, it's the key to our success."
    }
  },
  contact: {
    route: '/contact',
    banner: {
      title: 'Contact Us',
      description: "We'd love to hear from you. We are here to help."
    }
  },
  beta: {
    route: '/beta',
    banner: {
      title: 'Register for Beta',
      description: 'Experience huddl.ai. We would love to get your feedback.'
    }
  },
  press: {
    route: '/press',
    banner: {
      title: 'huddl.ai in the Press',
      description: 'Stories about huddl.ai that we especially love'
    }
  },
  platform: {
    route: '/platform',
    banner: {
      title: 'huddl.ai’s platform features for every usecase',
      description: `Realtime communication with the indulgence of artificial intelligence. Just append your API call with huddl and leverage the models. All our models are low footprint, zero latency, edge and cloud based.`
    }
  }
};

export const footerData = [
  // {
  //   title: 'Resources',
  //   links: [{ title: 'Download huddl.ai', to: '/download' }]
  // },
  {
    title: 'Company',
    links: [
      { title: 'About us', to: '/about-us' },
      { title: 'Contact us', to: '/contact' },
      { title: 'Careers', to: '/careers' },
      { title: 'Terms of Use', to: '/Eula' },
      { title: 'Privacy policy', to: '/privacy-policy' }
    ]
  }
];

export const BASE_IMG_URL = 'https://static.gohuddl.com/img/website';

export const BASE_ASSETS_ROOT =
  'https://s3.us-east-2.amazonaws.com/huddl-help-videos';
