export const emailsData = [
  {
    title: 'General Inquiries',
    email: 'info@huddl.ai'
  },
  {
    title: 'Careers',
    email: 'careers@huddl.ai'
  }
];
