import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 5rem;
`;

export const TitleSection = styled.div`
  display: flex;
`;

export const DateSection = styled(TitleSection)``;

export const DateBox = styled.div``;

export const Title = styled.div``;

export const Seperator = styled.div`
  width: 0;
  height: 1rem;
  border: 1px solid black;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 0.7rem;
`;

export const Summary = styled.div`
  padding: 5rem 4rem 6rem 4rem;
  color: #4c4c4c;
  font-family: Arial !important;
  font-size: 2.5rem;
  letter-spacing: 0;
  line-height: 3.2rem;
`;

export const PointsList = styled.div`
  padding: 0 4rem;
  margin: 0 0 2rem 0;
`;

export const ListHeader = styled.p`
  color: #ec1332;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 1.19px;
  line-height: 3rem;
  text-transform: uppercase;
`;

export const ListWrapper = styled.ul`
  color: #4c4c4c;
  font-family: Arial !important;
  font-size: 1.8rem;
  letter-spacing: -0.15px;
  line-height: 3.2rem;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListPoint = styled.li`
  padding-left: 1em;
  text-indent: -0.7em;
  ::before {
    content: '•';
    color: red;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 1rem 0 0;
  }
`;

export const Body = styled.div`
  width: 100%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  padding: 0 0 6rem 0;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70rem;
  background-color: #ffffff;
  @media screen and (max-width: 1050px) {
    max-width: 50rem;
  }
  @media screen and (max-width: 750px) {
    flex-direction: column;
    margin: 2rem;
  }
`;

export const RegisterBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  @media screen and (max-width: 750px) {
    margin: 2rem;
  }
`;

export const ClickHereText = styled.p`
  text-align: center;
  color: #6b6b6b;
  font-size: 1.5rem;
`;

export const ClickHereLink = styled.a`
  color: #0064ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
