import * as React from "react";
const SpeechToTextIcon = (props) => (
  <svg
    width={45}
    height={38}
    viewBox='0 0 45 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28 3C28 1.34315 26.6569 0 25 0H3C1.34315 0 0 1.34314 0 3V21.8108C0 23.4676 1.34315 24.8108 3 24.8108H18.9589C19.53 24.8108 20.0892 24.9738 20.5708 25.2806L23 26.8281L24.9254 28.0548C25.2655 28.2714 25.6325 28.3703 25.9904 28.3712H26V26.368V25.3712V25.1823V22V19H28V16V3ZM23 16H25V3L3 3V21.8108H18.9589C20.101 21.8108 21.2194 22.1368 22.1827 22.7504L23 23.2711V19V16ZM9.78906 8.5C10.6175 8.5 11.2891 9.17157 11.2891 10V16C11.2891 16.8284 10.6175 17.5 9.78906 17.5C8.96064 17.5 8.28906 16.8284 8.28906 16V10C8.28906 9.17157 8.96064 8.5 9.78906 8.5ZM15.1855 7C15.1855 6.17157 14.514 5.5 13.6855 5.5C12.8571 5.5 12.1855 6.17157 12.1855 7V19C12.1855 19.8284 12.8571 20.5 13.6855 20.5C14.514 20.5 15.1855 19.8284 15.1855 19V7ZM17.5781 9.5C18.4066 9.5 19.0781 10.1716 19.0781 11V15C19.0781 15.8284 18.4066 16.5 17.5781 16.5C16.7497 16.5 16.0781 15.8284 16.0781 15V11C16.0781 10.1716 16.7497 9.5 17.5781 9.5ZM33.7559 22.808V35.528C33.7559 35.88 33.6199 36.112 33.3479 36.224C33.0919 36.336 32.7559 36.392 32.3399 36.392H31.9799V38H40.1639V36.392H39.8039C39.3879 36.392 39.0439 36.336 38.7719 36.224C38.5159 36.112 38.3879 35.88 38.3879 35.528V22.808H39.1079C39.6999 22.808 40.1799 22.832 40.5479 22.88C40.9319 22.928 41.2119 23.032 41.3879 23.192C41.5799 23.336 41.6839 23.552 41.6999 23.84L41.9159 26.456H44.0039V20.96H28.1399V26.456H30.2279L30.4439 23.84C30.4599 23.552 30.5559 23.336 30.7319 23.192C30.9239 23.032 31.2039 22.928 31.5719 22.88C31.9559 22.832 32.4439 22.808 33.0359 22.808H33.7559Z'
      fill='url(#paint0_linear_3500_198)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3500_198'
        x1={26.5292}
        y1={13.2881}
        x2={7.92671}
        y2={32.7501}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
    </defs>
  </svg>
);
export default SpeechToTextIcon;
