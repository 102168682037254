import styled from 'styled-components';
import { Roboto } from '../Shared/fonts';

export const BannerContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 11rem 2rem 13rem;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  background: #f2f1ff;
  @media screen and (min-width: 676px) {
    overflow: hidden;
  }
  @media screen and (min-width: 703px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media screen and (min-width: 617px) {
    border-top: none;
    margin-top: 0;
  }
`;

export const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

export const BannerTitle = styled.div`
  text-align: center;
  color: #222222;
  font: 300 5rem ${Roboto};
  @media screen and (min-width: 644px) {
    font-size: 5rem;
  }
`;

export const BannerDescription = styled.div`
  color: #777777;
  font: 300 2rem ${Roboto};
  text-align: center;
  margin-top: 2rem;
  width: fit-content;
  @media screen and (min-width: 800px) {
    width: ${({ isWebex }) => (isWebex ? '70rem' : '50rem')};
  }
`;

export const HighLightTag = styled.span`
  font-weight: 500;
  border-bottom: 2px solid #7b47ef;
`;

export const BannerBackgroundCircles = styled.div`
  border-radius: 50%;
  background-color: rgb(248, 248, 255);
  height: 30rem;
  width: 30rem;
  display: none;
  opacity: ${({ opacity }) => (opacity ? opacity : '1')};
  top: ${({ top }) => (top ? top : null)};
  left: ${({ left }) => (left ? left : null)};
  right: ${({ right }) => (right ? right : null)};
  bottom: ${({ bottom }) => (bottom ? bottom : null)};
  position: absolute;

  @media screen and (min-width: 676px) {
    display: block;
  }

  @media screen and (min-width: 800px) {
    height: 60rem;
    width: 60rem;
  }
`;
