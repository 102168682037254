import React from 'react';

export const CollapseIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
        stroke="gray"
      />
      <path
        d="M17 12a.587.587 0 00-.5-.58l-.087-.007H7.587A.587.587 0 007.5 12.58l.087.006h8.826A.587.587 0 0017 12z"
        fill="gray"
      />
    </g>
  </svg>
);

export default CollapseIcon;
