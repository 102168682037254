import React from 'react';

export const HuddlLogoSvg = () => (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>0101AAC9-550F-48D6-8F68-116B3375616C@0.5x</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <radialGradient
        cx="94.95278%"
        cy="4.33324353%"
        fx="94.95278%"
        fy="4.33324353%"
        r="132.899646%"
        gradientTransform="translate(0.949528,0.043332),scale(0.991153,1.000000),rotate(133.958557),translate(-0.949528,-0.043332)"
        id="HuddlLogoSvgRadialGradient-2"
      >
        <stop stopColor="#A241E2" offset="0%" />
        <stop stopColor="#534DFD" offset="100%" />
      </radialGradient>
      <path
        d="M10.1745046,11.2511251 C11.8848039,10.0394655 13.3758294,7.9623347 13.06886,5.62556256 C12.7618818,3.28879042 10.6130435,1.12511251 7.23630142,1.12511251 C3.85955929,1.12511251 0.702091036,3.7712127 1.27219081,7.79244271 C1.84229059,11.8136814 7.23630142,14.5831891 11.007726,14.5831891 C11.8848039,14.5831891 11.8848039,15.7515752 11.007726,15.7515752 C5.6575629,15.3731825 0.833651848,12.4627847 0.0881347315,7.83251402 C-0.657382385,3.2022433 3.46487686,0 7.23630142,0 C11.007726,0 14.0336422,2.59641349 14.2843299,5.62556256 C14.5350264,8.65471163 12.1917821,11.2511251 11.007726,12.1165963 C10.475823,12.5053832 9.69211786,11.8569549 10.1745046,11.2511251 Z"
        id="path-2"
      />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Android-Page" transform="translate(-23.000000, -11.000000)">
        <g id="Website_Header" transform="translate(-34.000000, 0.000000)">
          <g id="Group-8">
            <g id="Group-5">
              <g id="Group-2" transform="translate(56.000000, 11.000000)">
                <g id="Group">
                  <g id="Group-6" fill="url(#HuddlLogoSvgRadialGradient-2)">
                    <path
                      d="M18.0992696,34.0116412 C22.6040078,34.0116412 26.3689802,32.5888589 29.3529091,29.75092 C32.6753168,26.5910439 34.7860436,21.968168 34.7860436,17.4724872 C34.7860436,8.33840564 27.3153572,0.933333333 18.0992696,0.933333333 C8.88317861,0.933333333 1.41249569,8.33840564 1.41249569,17.4724872 C1.41249569,26.6065765 8.88317861,34.0116412 18.0992696,34.0116412 Z"
                      id="Stroke-4"
                    />
                  </g>
                  <g id="Group-9" transform="translate(10.986276, 9.736257)">
                    <mask id="mask-3" fill="white">
                      <use xlinkHref="#path-2" />
                    </mask>
                    <use id="Clip-8" fill="#FFFFFF" xlinkHref="#path-2" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
