import React from 'react';
import {
  BottomBannerContainer,
  BottomBannerTitle,
  BottomBannerText,
  BottomBannerButton
} from './styles';
import { Link } from 'react-router-dom';

const BottomBanner = ({
  buttonText,
  text,
  title,
  className,
  toLink,
  containerRef,
  style
}) => (
  <BottomBannerContainer style={style} ref={containerRef} className={className}>
    <BottomBannerTitle className="bottom-banner-title">
      {title}
    </BottomBannerTitle>

    <BottomBannerText className="bottom-banner-text">{text}</BottomBannerText>
    <Link className="bottom-banner-link" to={toLink}>
      <BottomBannerButton className="bottom-banner-button">
        {buttonText}
      </BottomBannerButton>
    </Link>
  </BottomBannerContainer>
);

export default BottomBanner;
