import React, { useState } from 'react';
import {
  Wrapper,
  ActionButton,
  InfoBlock,
  LeftPanel,
  RightPanel,
  DivInput,
  IframeBlock
} from '../HuddlApp/styles';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

const defaultEmail = 'akash.sahi@yopmail.com';

const redirectUrls = {
  local: 'https://local.huddldev.com:3000',
  bridge: 'https://bridge-dev.huddldev.com',
  bridgeIn: 'https://web-bridge.huddldev.com'
};

const getRedirectUrl = env => {
  return redirectUrls[env];
};

const HuddlApp = ({ history }) => {
  const [showChat, setShowChat] = useState(false);
  const [url, createUrl] = useState('');
  const [email, setEmail] = useState(defaultEmail);
  const [env, setEnv] = useState('bridgeIn');
  const [dealId, setDealId] = useState('db4d6eec-780b-44dd-834e-c5c9fc5ae291');

  function receiveMessageFromHuddl(event) {
    const myFrame = document.getElementById('myFrame');
    console.log('data ==>', event);

    /* @param  {Object} event.data 
      ex:   
      {type: 'meetingEnded', payload: {meeting_id: 'afc-dga-eih-fba-ggb-degb'}}
    */
    const { data } = event || {};
    const { type, payload } = data || {};

    switch (type) {
      case 'maximizeChatView':
        // needs to maxmize iframe size to 450 x 500
        myFrame.style.height = '600px';
        myFrame.style.width = '900px';
        break;

      case 'minimzeChatView':
        // needs to minimize iframe size to 300 x 300
        myFrame.style.height = '400px';
        myFrame.style.width = '580px';
        break;

      case 'maximizeShareView':
        //needs to maxmize iframe size to full screen width: 100% height: 100%
        myFrame.style.height = '80vh';
        myFrame.style.width = '80vw';
        break;

      case 'minimzeShareView':
        // needs to minimize iframe size to 300 x 300
        myFrame.style.height = '300px';
        myFrame.style.width = '300px';
        break;

      case 'mediaEventNotAllowed':
        break;

      case 'screenShareOn':
        // at presenter end (who do screenshare)
        break;

      case 'screenShareOff':
        // at presenter end (who do screenshare)
        break;

      case 'redirectToLoanSummary':
        const { loanId, loanNumber } = payload || {};
        console.log(`Loan summary, Loan Id: ${loanId}, ${loanNumber}`);
        break;

      case 'latestUnreadMessage':
        const { message, author, offlineUsers } = payload || {};
        console.log(`Latest unread message:`, message, author, offlineUsers);
        break;

      case 'participantJoined':
        // on participant joined
        const { name } = payload || {};
        console.log(`Name of the joined participant, userName: ${name}`);
        break;

      case 'unreadMessagesCount':
        const { unreadCount } = payload || {};
        console.log(`unread message count, count: ${unreadCount}`);
        break;

      default:
        break;
    }
  }
  window.addEventListener('message', receiveMessageFromHuddl, false);

  const handleSetEnvironment = e => {
    e.preventDefault();
    console.log(e.currentTarget.value);
    setEnv(e.currentTarget.value);
  };

  const handleChatJoin = async () => {
    let data = JSON.stringify({
      username: 'test-team',
      password: '=9)j.mtI#=IJ'
    });
    let config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiYzg2MDYwODgtMzJiMi00YzgzLWE0ZmUtMTY5Yzk5MDRhNGI3IiwidXNlcklEIjoicm9oYW4ucHVyb2hpdEBnb2h1ZGRsLmNvbSIsImRldmljZUlEIjoiZGJmYWUzNWMtM2Q2NS00OWM0LTliZjUtNDA3NmVkNTNkZWFhIiwiaWF0IjoxNjgzODg2MDIyLCJleHAiOjE2ODY0NzgwMjIsImlzcyI6Ikh1ZGRsIn0.jcYZ01DYuXjLw7bBjYf7DYOi50T5zugPMqcCwmTGG1k',
        Cookie:
          'project-t-chat-token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiYzg2MDYwODgtMzJiMi00YzgzLWE0ZmUtMTY5Yzk5MDRhNGI3IiwidXNlcklEIjoicm9oYW4ucHVyb2hpdEBnb2h1ZGRsLmNvbSIsImRldmljZUlEIjoiZGJmYWUzNWMtM2Q2NS00OWM0LTliZjUtNDA3NmVkNTNkZWFhIiwiaWF0IjoxNjgzODg2MDIyLCJleHAiOjE2ODY0NzgwMjIsImlzcyI6Ikh1ZGRsIn0.jcYZ01DYuXjLw7bBjYf7DYOi50T5zugPMqcCwmTGG1k'
      }
    };
    try {
      const basePath = 'https://api-bridge-dev-in.huddldev.com';
      const res = await axios.post(
        `${basePath}/auth/teams/v1/authenticate`,
        data,
        config
      );
      const {
        data: { jwt }
      } = res || {};
      if (jwt) {
        data = JSON.stringify({
          userID: email,
          metadata: {
            dealID: dealId
          }
        });
        config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
            Cookie:
              'project-t-chat-token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiYzg2MDYwODgtMzJiMi00YzgzLWE0ZmUtMTY5Yzk5MDRhNGI3IiwidXNlcklEIjoicm9oYW4ucHVyb2hpdEBnb2h1ZGRsLmNvbSIsImRldmljZUlEIjoiZGJmYWUzNWMtM2Q2NS00OWM0LTliZjUtNDA3NmVkNTNkZWFhIiwiaWF0IjoxNjgzODg2MDIyLCJleHAiOjE2ODY0NzgwMjIsImlzcyI6Ikh1ZGRsIn0.jcYZ01DYuXjLw7bBjYf7DYOi50T5zugPMqcCwmTGG1k'
          }
        };

        const sessionData = await axios.post(
          `${basePath}/auth/users/v1/session`,
          data,
          config
        );
        const {
          data: { login_uri }
        } = sessionData;
        const arr = login_uri.split('?');
        const token = arr[1];
        const finalUrl = getRedirectUrl(env) + '?' + token;
        createUrl(finalUrl);
        setShowChat(true);
      }
    } catch (error) {
      console.error(`Failed to authenticate: ${error}`);
    }
  };

  return (
    <Wrapper>
      <LeftPanel>
        <form>
          <DivInput>
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={e => {
                e.preventDefault();
                setEmail(e.currentTarget.value);
              }}
            />
          </DivInput>
          <DivInput>
            <label>Environment</label>
            <select onChange={handleSetEnvironment} value={env}>
              <option value="local">Local</option>
              <option value="bridge">Bridge</option>
              <option value="bridgeIn">Bridge India</option>
            </select>
          </DivInput>
          <DivInput>
            <label>DealId:</label>
            <input
              type="text"
              value={dealId}
              onChange={e => {
                e.preventDefault();
                setDealId(e.currentTarget.value);
              }}
            />
          </DivInput>
          {showChat ? (
            <ActionButton
              onClick={() => {
                createUrl('');
                setShowChat(false);
              }}
            >
              {'Close chat'}
            </ActionButton>
          ) : (
            <ActionButton
              onClick={() => {
                handleChatJoin();
              }}
            >
              {'Start chat'}
            </ActionButton>
          )}
        </form>
      </LeftPanel>
      <RightPanel>
        <InfoBlock>
          <code>
            Constructed iframe url: <br />
            {url}
          </code>
        </InfoBlock>
        <IframeBlock id="iframeBlock">
          <iframe
            src={url}
            allow="camera; microphone; fullscreen; web-share; display-capture; autoplay"
            title="huddl RTC"
            height={360}
            width={580}
            id="myFrame"
          ></iframe>
        </IframeBlock>
      </RightPanel>
    </Wrapper>
  );
};

export default withRouter(HuddlApp);
