import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';
import IconBase from '@material-ui/core/Icon';

export const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100rem;
  justify-content: center;
  margin-top: 1.5rem;
  border-bottom: 1px solid #d0d0d0;
`;

export const IndividualContainer = styled.a`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem;
  text-decoration: none;
  &:hover {
    div {
      transform: scale(1);
    }
    span {
      opacity: 1;
    }
  }
`;

export const GalleryImage = styled.div`
  width: 12rem;
  height: 12rem;
  background: url(${({ src }) => src});
  background-size: cover;
  filter: grayscale(1);
  transition: filter 0.1s ease-in;
  position: relative;
  @media (min-width: 800px) {
    width: 18rem;
    height: 18rem;
  }
`;

export const Shade = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: calc(50% - 6rem);
  background: rgba(0, 0, 0, 0.8);
  transform: scale(0);
  transition: transform 0.1s ease-out;
  @media (min-width: 800px) {
    top: calc(50% - 9rem);
  }
`;

export const Icon = styled(IconBase)`
  && {
    opacity: 0;
    top: calc(50% - 3rem);
    left: calc(50% - 2.5rem);
    font-size: 5rem;
    position: absolute;
    transition: opacity 0.15s ease-in;
    color: #7b47ef;
  }
`;

export const GalleryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 1.5rem;
  width: 23rem;
`;

export const NameText = styled.div`
  font-size: 1.4rem;
  color: #252525;
  line-height: 2.4rem;
  align-self: center;
  text-align: center;
`;

export const RoleText = styled.div`
  font: 300 1.2rem ${Roboto};
  color: #252525;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  align-self: center;
  text-align: center;
`;
