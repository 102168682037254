import React from 'react';
import ZoomIcon from '../Icons/ZoomIcon.svg';
import WebexIcon from '../Icons/WebexIcon.svg';
import SlackIcon from '../Icons/SlackIcon.svg';
import ServiceNowIcon from '../Icons/ServiceNowIcon.svg';
import TeamsIcon from '../Icons/TeamsIcon.svg';
import { slackLinks } from '../../Slack/docsList';
import ZoomDocsContent from '../../Zoom/ZoomDocs/ZoomDocsContent';
import ZoomFaqsContent from '../../Zoom/ZoomFaqs/ZoomFaqsContent';
import WebexContent from '../../Webex/WebexContent';
import WebexFaqsList from '../../Webex/Faqs/faqsList';
import DocsContent from '../../Slack/DocsContent';
import Faqs from '../Faqs';
import SlackFaqsList from '../../Slack/Faqs/faqsList';
import SlackBot from '../../Slack/SlackBot';
import ServiceNowDocs from '../../ServiceNow/ServiceNowDocs';
import ServiceNowFaqsList from '../../ServiceNow/Faqs/faqsList';
import Support from '../Support';
import Description from '../../Slack/Description';
import TeamsDocs from '../../Teams/TeamsDocs';
import TeamsFaqsList from '../../Teams/Faqs/faqsList';

export const bannerIcons = new Map([
  ['zoom', ZoomIcon],
  ['webex', WebexIcon],
  ['slack', SlackIcon],
  ['service-now', ServiceNowIcon],
  ['teams', TeamsIcon]
]);
export const redirectLink = new Map([
  ['zoom', 'https://marketplace.zoom.us/apps/qucidsAtRIuNlQiGJT_XdA'],
  ['webex', 'https://apphub.webex.com/applications/huddl-ai-huddl-inc'],
  ['slack', slackLinks.get(window.location.origin) || ''],
  ['service-now', ''],
  [
    'teams',
    'https://appsource.microsoft.com/en-us/marketplace/apps?product=teams'
  ]
]);
export const tabMapping = new Map([
  ['description', 0],
  ['docs', 1],
  ['faqs', 2],
  ['support', 3]
]);
export const slackTabMapping = new Map([
  ['description', 0],
  ['docs', 1],
  ['addBot', 2],
  ['faqs', 3],
  ['support', 4]
]);
export const bannerInfo = new Map([
  [
    'zoom',
    `huddl.ai lets users maintain a system of record for the meetings inside of Zoom. Helps in generating insights by analysing meetings along with giving users a collaborative space for note taking and task management.`
  ],
  [
    'webex',
    `huddl.ai lets users maintain a system of record for the meetings inside of Webex. Helps in generating insights by analysing meetings along with giving users a collaborative space for note taking and task management.`
  ],
  [
    'slack',
    'huddl.ai when integrated with Slack gives users the functionality to access huddl.ai features like creating and joining meetings, accessing tasks, viewing upcoming or previous meetings, and many more directly from Slack.'
  ],
  [
    'service-now',
    'huddl.ai when integrated with ServiceNow lets participants pull or push the meeting generated data like notes, tasks, agendas as well as media moments directly to their ServiceNow account.'
  ],
  [
    'teams',
    `huddl.ai lets users maintain a system of record for the meetings inside of Microsoft Teams. Helps in generating insights by analysing meetings along with giving users a collaborative space for note taking and task management.`
  ]
]);
export const heading = new Map([
  ['zoom', 'Zoom'],
  ['webex', 'Webex'],
  ['slack', 'Slack'],
  ['service-now', 'ServiceNow'],
  ['teams', 'Microsoft Teams']
]);

const TabsText = [
  { lightText: 'huddl.ai for Slack', boldText: 'Quick Introduction' },
  { lightText: 'How huddl.ai works?', boldText: 'Getting Started' },
  { lightText: 'Have more questions?', boldText: 'Frequently asked questions' },
  { lightText: 'Need Support?', boldText: 'Submit your Issue' },
  {
    lightText: 'huddl.ai for the channels',
    boldText: 'Ways to add huddl.ai bot'
  }
];

export const TabsIndexAndUrl = new Map([
  [
    'slack',
    [
      { tab: 0, url: 'description', tabText: TabsText[0] },
      { tab: 1, url: 'docs', tabText: TabsText[1] },
      { tab: 2, url: 'addBot', tabText: TabsText[4] },
      { tab: 3, url: 'faqs', tabText: TabsText[2] },
      { tab: 4, url: 'support', tabText: TabsText[3] }
    ]
  ],
  [
    'others',
    [
      { tab: 1, url: 'docs', tabText: TabsText[1] },
      { tab: 2, url: 'faqs', tabText: TabsText[2] },
      { tab: 3, url: 'support', tabText: TabsText[3] }
    ]
  ]
]);

export const contentMap = shrinkHeader =>
  new Map([
    [
      'zoom',
      [
        {
          content: <ZoomDocsContent isIntegration />,
          tab: 1,
          url: 'docs'
        },
        {
          tab: 2,
          url: 'faqs',
          content: <ZoomFaqsContent isIntegration shrinkHeader={shrinkHeader} />
        },
        { tab: 3, url: 'support', content: <Support integration="zoom" /> }
      ]
    ],
    [
      'webex',
      [
        {
          content: <WebexContent shrinkHeader={shrinkHeader} />,
          tab: 1,
          url: 'docs'
        },
        {
          tab: 2,
          url: 'faqs',
          content: (
            <Faqs
              faqsList={WebexFaqsList}
              isIntegration
              shrinkHeader={shrinkHeader}
            />
          )
        },
        { tab: 3, url: 'support', content: <Support integration="webex" /> }
      ]
    ],
    [
      'slack',
      [
        {
          content: <Description isIntegration />,
          tab: 0,
          url: 'description'
        },
        {
          content: <DocsContent isIntegration />,
          tab: 1,
          url: 'docs'
        },
        {
          content: <SlackBot isIntegration />,
          tab: 2,
          url: 'addBot'
        },
        {
          tab: 3,
          url: 'faqs',
          content: (
            <Faqs
              isIntegration
              shrinkHeader={shrinkHeader}
              faqsList={SlackFaqsList}
            />
          )
        },
        { tab: 4, url: 'support', content: <Support integration="Slack" /> }
      ]
    ],
    [
      'service-now',
      [
        {
          content: <ServiceNowDocs isIntegration shrinkHeader={shrinkHeader} />,
          tab: 1,
          url: 'docs'
        },
        {
          tab: 2,
          url: 'faqs',
          content: (
            <Faqs
              isIntegration
              shrinkHeader={shrinkHeader}
              faqsList={ServiceNowFaqsList}
            />
          )
        },
        {
          tab: 3,
          url: 'support',
          content: <Support integration="ServiceNow" />
        }
      ]
    ],
    [
      'teams',
      [
        {
          content: <TeamsDocs isIntegration shrinkHeader={shrinkHeader} />,
          tab: 1,
          url: 'docs'
        },
        {
          tab: 2,
          url: 'faqs',
          content: (
            <Faqs
              isIntegration
              shrinkHeader={shrinkHeader}
              faqsList={TeamsFaqsList}
            />
          )
        },
        {
          tab: 3,
          url: 'support',
          content: <Support integration="Teams" />
        }
      ]
    ]
  ]);
