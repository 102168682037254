import React from 'react';
import { FeaturesPageTextContainer } from './styles';

const TextContainer = ({ containerRef, ratio }) => {
  return (
    <FeaturesPageTextContainer
      containerRef={containerRef}
      title="Problem with Virtual Meetings?"
      description={[
        'There’s too many of them and the meeting content is all over the place.',
        `Traditional video conferencing solutions focus on the meeting mechanics - invitations, audio, video connectivity etc. huddl allows you and your team to prepare, capture and share meeting data with relevant stakeholders. Our AI simplifies organizing all meeting content so that tracking and searching becomes a breeze. To reduce time spent in consuming meeting data huddl's AI automatically summarizes the meeting for you.`
      ]}
    />
  );
};

export default TextContainer;
