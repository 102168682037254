import React, { useEffect } from 'react';

import { BlogDiv } from './styles';

const Blog = () => {
  useEffect(() => {
    window.location =
      'https://huddlai.notion.site/huddl-ai-on-Webex-4728a25fc84a4b28bd84fbb086e13723';
  }, []);

  return <BlogDiv>loading...</BlogDiv>;
};

export default Blog;
