import WebexIcon from './Icons/WebexIcon.svg';
import ZoomIcon from './Icons/ZoomIcon.svg';
import SlackIcon from './Icons/SlackIcon.svg';
import ServiceNowIcon from './Icons/ServiceNowIcon.svg';
import TeamsIcon from './Icons/TeamsIcon.svg';
import {
  ZOOM_INTEGRATION_ROUTE,
  WEBEX_INTEGRATION_ROUTE,
  SLACK_INTEGRATION_ROUTE,
  SERVICE_NOW_INTEGRATION_ROUTE,
  TEAMS_INTEGRATION_ROUTE
} from '../../../constants/routes';
const integrationsList = [
  {
    path: ZOOM_INTEGRATION_ROUTE,
    icon: ZoomIcon,
    name: 'huddl.ai for Zoom',
    desc: 'Use the collaborative space provided by huddl.ai inside your Zoom call for setting up of meeting agendas, taking notes, manage tasks, capture important multimedia moments.'
  },
  {
    path: WEBEX_INTEGRATION_ROUTE,
    icon: WebexIcon,
    name: 'huddl.ai for Webex',
    desc: 'huddl.ai inside Webex meeting helps in running a well structured meeting by allocating agendas to be discussed, with features like collaborative note taking and task management system with multimedia moment capturing.'
  },
  {
    path: SLACK_INTEGRATION_ROUTE,
    icon: SlackIcon,
    name: 'huddl.ai for Slack',
    desc: 'huddl.ai when integrated with Slack gives users the functionality to access huddl.ai features like creating and joining meetings, accessing tasks, viewing upcoming or previous meetings, and many more directly from Slack.'
  },
  {
    path: SERVICE_NOW_INTEGRATION_ROUTE,
    icon: ServiceNowIcon,
    name: 'huddl.ai for ServiceNow',
    desc: 'Sync your huddl.ai account with ServiceNow to send all your meeting data generated during your meeting to ServiceNow.'
  },
  {
    path: TEAMS_INTEGRATION_ROUTE,
    icon: TeamsIcon,
    name: 'huddl.ai for Microsoft Teams',
    desc: 'A single collaborative workspace inside your Microsoft Teams meeting to have a productive session. Keeping the participants focused on the meeting agenda while generating insights in the form of tasks, note and multi-media moments.'
  }
];

export default integrationsList;
