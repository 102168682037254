import React, { Component } from 'react';
import {
  MenuHamburger,
  CloseMenuIconContainer,
  MenuContainer,
  MobileNavContainer,
  LogoLink,
  NavBar
} from './styles';
import Menu from './Menu';
import { withRouter } from 'react-router-dom';
import { CloseIcon } from '../../Shared/Icons/Close';
import { HuddlLogoWithTextSvg } from '../../Shared/Icons/HuddlLogoWithText';

const HAMBURGER_ICON_SRC =
  'https://static.gohuddl.com/img/baseline-menu-24px.svg';

class MobileNav extends Component {
  state = {
    open: false
  };

  toggleMenu = () => {
    const { toggleOverflow } = this.props;
    this.setState({ open: !this.state.open }, () => toggleOverflow());
  };

  render() {
    const { open } = this.state;
    const { links, loggedIn, signInLink, logOut } = this.props;
    return (
      <MobileNavContainer open={open}>
        {open ? (
          <MenuContainer>
            <CloseMenuIconContainer onClick={this.toggleMenu}>
              <CloseIcon />
            </CloseMenuIconContainer>
            <Menu
              signInLink={signInLink}
              loggedIn={loggedIn}
              toggleMenu={this.toggleMenu}
              links={links}
              logOut={logOut}
            />
          </MenuContainer>
        ) : (
          <NavBar>
            <LogoLink to="/" />
            <HuddlLogoWithTextSvg id={1} />

            <MenuHamburger onClick={this.toggleMenu} src={HAMBURGER_ICON_SRC} />
          </NavBar>
        )}
      </MobileNavContainer>
    );
  }
}

export default withRouter(MobileNav);
