const jobDetails = [
  {
    id: '80c01045-03ca-4c4c-b144-4a27cc014698',
    title: 'AI Engineer',
    shortDescription: '',
    description: [
      "The ideal candidate will have experience in the latest techniques in Artificial Intelligence, Natural Language Processing, and Machine Learning (including Deep Learning approaches). Experiences in real- time collaboration is a plus. We're looking for someone with specialized knowledge and general skills. Someone who can architect new features one day and produce optimized code the next. The ideal candidate has a strong mix of education and practical experience."
    ],
    locations: ['Mountain View, CA'],
    responsibilities: [
      'Deliver a commercially deployable platform that provides an intuitive collaboration solution targeted at enterprise customers.',
      'Use NLP and ML techniques to bring order to unstructured data.',
      'Experience in extracting signal from noise in large unstructured datasets a plus.',
      'Work within the Engineering Team to design, code, train, test, deploy and iterate on enterprise scale machine learning systems.',
      'Work alongside an excellent, cross-functional team across Engineering, Product and Design.'
    ],
    requiredSkills: [
      'Experience in applying machine learning techniques, Natural Language Processing or Computer Vision.',
      'Strong analytical and problem-solving skills.',
      'Solid software engineering skills across multiple languages including but not limited to Java or Python, C/C++.',
      'Problem solver - able to work independently, and be comfortable with deadlines and milestones.',
      'Deep understanding of ML techniques such as: classification, clustering, deep learning, optimization methods, supervised and unsupervised techniques.',
      'Strong communication skills and an easy going attitude.',
      'Proven ability to apply, debug, and develop machine learning models for real-world applications.',
      'Previous industry work experience required.'
    ],
    preferredSkills: [
      'Experience with Git, SparkML, TensorFlow and Keras',
      'Exposure to MongoDB, Kafka and S3.',
      'Experience with Natural Language Processing (NLP), Natural Language Understanding (NLU)',
      'Experience building applications for the enterprise customer',
      'Previous early-stage company experience is a plus'
    ],
    qualifications: [],
    otherRequirements: [],
    perks: [
      'Competitive pay and benefits',
      'Office in downtown Mountain View, close to Caltrain and coffee shops',
      'Fun, small office work environment'
    ]
  },
  {
    id: '533b4d2a-cc20-40ff-9a1a-0a77f82981710',
    title: 'Media Technical Lead',
    description: [
      'Are you a multimedia expert looking to innovate, contribute in a fast-paced startup? We happen to be looking to add a few to our engineering team.'
    ],
    locations: ['Hyderabad, Bangalore'],
    responsibilities: [
      'Lead the architecture and design aspects of conferencing platform',
      'To handle large scale cloud based unified communication solution',
      'Optimize media server, media gateway for density and performance',
      'Should serve roles of developer, architect and managerial during execution',
      ' Implementations for security and data protection',
      'Cloud deployment and automation aspects'
    ],
    requiredSkills: [
      '10+ years of experience in voice, video domain',
      'Strong background in voice/video codecs and applications',
      'Should have expertise in standards related to codecs',
      'Expert knowledge of SIP, RTP, RTCP, ICE, DTLS',
      'Prior experience in WebRTC technology',
      'Experience with building and operating large scale real time services and conferencing systems',
      'Preferable to have experience in using Kamailio, Asterisk/FreeSWITCH',
      'Understand network and common data center infrastructure'
    ],
    preferredSkills: [],
    qualifications: [' B.Tech/B.E in CS/ECE/EEE from reputed universities'],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  },
  /*  {
    id: '533b4d2a-cc20-40ff-9a1a-0a77f8298172',
    title: 'Media Integration Engineer',
    description: [
      `Are you a multimedia developer looking to innovate, contribute in a fast-paced startup?
      We happen to be looking to add a few to our engineering team.`
    ],
    locations: ['Hyderabad, Bangalore'],
    responsibilities: [
      'Design and implement the call flows as required by the product',
      'Update and maintain the system configuration',
      'Analyse and debug issues in the Media server call flows',
      'Implement the deployment updates as needed',
      'Work with open source VoIP servers like Kamailo, Asterisk, Freeswitch'
    ],
    requiredSkills: [
      '5+ years of experience in working with video/VoIP infrastructure components like Kamailo, Asterisk or Freeswitch',
      'Good knowledge of Media protocols: SIP, RTP, RTSP, TLS',
      'Good understanding of configuring Asterisk and Freeswitch',
      'Good working knowledge of WebRTC',
      'Experience with debugging networking issues',
      'Proficiency in Linux environment'
    ],
    preferredSkills: [
      'Good understanding of Kamailo scripting',
      'Good understanding of SIP signaling and protocol',
      'Experience with deployment concepts like Chef scripting, Orchestration',
      'Experience working with Linux system administration/internals',
      'Experience with deployment in AWS or any other cloud'
    ],
    otherRequirements: [],
    qualifications: [
      'B.E/B.Tech in Computer Science or ECE or EEE from reputed universities'
    ],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  }, */
  {
    id: '202109272a-cc20-40ff-9a1a-0a77f9298',
    title: 'DevOps Lead Engineer',
    description: [
      `We are looking for a passionate devops lead to come help us build our next generation cloud
      infrastructure, and guide our dev ops team. This position will act as a primary technical contact and is
      responsible for delivery of AWS infrastructure solutions including private cloud, which runs huddl.ai
      Collaboration service. An ideal candidate will have a strong operations background as well as some
      development experience. Candidates for this position must be able to, from day one, roll up their
      sleeves and hit the ground running, and bring their experience to the team to make the Infrastructure
      deployments much smoother. Most importantly, the right individual will be with a positive, "can-do"
      attitude and a passion for delivering technical solutions in a fast-paced environment.`
    ],
    locations: ['Hyderabad'],
    responsibilities: [
      'Responsible for the design, implementation, and support of large scale infrastructure for the huddl.ai collaboration platform.',
      'Participates in the design and implementation phases for new and existing products, with a focus on AWS technical knowledge for successful execution of the requirements.',
      'Help with new feature development - work closely with other engineering teams to provide platform solutions to new products',
      'Working with team to analyze and design infrastructure with 99.99% up-time with server virtualization, clustering, replicating databases, disaster recovery, and geographic redundancy.',
      'Give technical guidance, knowledge transfer and mentorship to engineering peers as required and lead technical staff responsibilities.',
      'Build out a robust system to auto-scale our platform and work closely with our engineering team to diagnose how to build this, how to manage this and what triggers to measure.',
      'Actively monitor and administer the applications and hosting environment to increase efficiency, improve reliability, and minimize downtimes.',
      'Triage and analyze technical solutions to environment related issues encountered by new and existing applications in the environment.',
      'Troubleshoot and debug environment and infrastructure problems found in the production and non-production environments.',
      'Measurement, optimization, and tuning of system performance and ensuring that systems will run reliably and are highly available in a 24/7 production environment.',
      'Manage and control the continuous release promotion cycle (through automation) including the following environments: development, QA, Performance, Pre-prod and production.',
      'Extensive experience on Devops tools like Subversion, GIT, Jenkins, Chef, Ansible and Bitbucket'
    ],
    requiredSkills: [
      'Bachelors or Masters degree in Computer Science or a related field',
      'Minimum 5 to 7 years overall experience in Linux/Unix systems with atleast 2+ years hands on experience with cloud infrastructure on AWS.',
      'Extensive experience with Kubernetes, Jenkins is a must.',
      'Experience with automation with tools like Ansible/Terraform Prior experience in using Kafka is a plus',
      'Prior experience or exposure to container technology - docker, orchestration, container scheduling.',
      'Clustering, Message queues, load balancing, session persistence.',
      'Database performance tuning, backup/restore.',
      'High availability and disaster recovery',
      'Have experience leading a DevOps group to scale up successfully in production-ready service management.'
    ],
    preferredSkills: [],
    qualifications: ['Bachelor’s degree in CS/EE, or equivalent experience'],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  },
  /*   {
    id: '533b4d2a-cc20-40ff-9a1a-0a77f8298',
    title: 'Backend Lead Engineer',
    description: [
      `We're looking for a Backend Lead Engineer with an eye for best practices and scalability. We'd like to see a developer with a passion for building highly scalable and performant systems. Apply if you think you are up for a challenge, working in a fast-paced informal startup environment with other experienced engineers.`
    ],
    locations: ['Hyderabad,  Bangalore'],
    responsibilities: [
      'Drive new feature development - from ideation to PoC to testing to scalable production deployments',
      'Writing reusable, testable, and efficient code',
      'Implementing highly scalable, secure, robust, and fault-tolerant systems in a microservices ecosystem',
      'Working on continuously improving tools and systems'
    ],
    requiredSkills: [
      'An overall industry experience of 7+ years',
      '4+ years of experience writing, testing, optimizing code',
      'Experience with building scalable applications (Node.JS, Go, Python, etc.)',
      'Knowledge of relational databases(MySQL, Postgres) and NoSQL (Redis, Mongo, etc.)',
      'Demonstrable experience with microservices (design, deployment, monitoring, etc.)',
      'Strong proponent for best practices and code quality',
      'Understanding fundamental design principles behind a scalable application.',
      'Good communication skills'
    ],
    preferredSkills: [
      'Experience with machine learning, data analytics, real time application is a bonus',
      'Pet projects and open source contributions are a big plus as well'
    ],
    qualifications: ['Bachelor’s degree in CS/EE, or equivalent experience'],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  }, */

  {
    id: '644b4d2a-cc20-40ff-9a1a-0a77f9298',
    title: 'Sr. Backend Engineer',
    description: [
      `We're looking for a Sr. Backend Engineer with an eye for best practices and scalability. We'd like to see a developer with a passion for building highly scalable and performant systems. Apply if you think you are up for a challenge, working in a fast-paced informal startup environment with other experienced engineers.`
    ],
    locations: ['Hyderabad, Bangalore'],
    responsibilities: [
      'Drive new feature development - from ideation to PoC to testing to scalable production deployments',
      'Writing reusable, testable, and efficient code',
      'Implementing highly scalable, secure, robust, and fault-tolerant systems in a microservices ecosystem',
      'Working on continuously improving tools and systems'
    ],
    requiredSkills: [
      'An overall industry experience of 5+ years',
      '4+ years of experience writing, testing, optimizing code',
      'Experience with building scalable applications (Node.JS, Go, Python, etc.)',
      'Knowledge of relational databases(MySQL, Postgres) and NoSQL (Redis, Mongo, etc.)',
      'Demonstrable experience with microservices (design, deployment, monitoring, etc.)',
      'Strong proponent for best practices and code quality',
      'Understanding fundamental design principles behind a scalable application.',
      'Good communication skills'
    ],
    preferredSkills: [
      'Experience with machine learning, data analytics, real time application is a bonus',
      'Pet projects and open source contributions are a big plus as well'
    ],
    qualifications: ['Bachelor’s degree in CS/EE, or equivalent experience'],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  },
  /*   {
    id: '533b4d2a-cc20-40ff-9a1a-7f8298',
    title: 'Front End Lead Developer',
    description: [
      `We're looking for a Front End Lead Developer with an eye for best practices and scalability. We'd like to see a generalist who thinks seriously about what is best for a product and displays the following:`
    ],
    locations: ['Hyderabad, Bangalore'],
    responsibilities: [
      'Drive new feature development - from ideation to PoC to testing to scalable production deployments',
      'Writing reusable, testable, and efficient code',
      'Implementing highly scalable, secure, robust, and fault-tolerant systems in a microservices ecosystem',
      'Working on continuously improving tools and systems',
      'Work alongside an excellent, cross-functional team across Engineering, Product and Design'
    ],
    requiredSkills: [
      'An overall industry experience of 7+ years',
      'At least 4 years of experience writing, testing, optimizing code',
      'Solid understanding of the React ecosystem on the client side',
      'Strong proponent for best practices and code quality',
      'Use of TypeScript or Flow , comfortable using ES6/ES7',
      'Experience with Test-driven development',
      'Familiarity with performance optimization and profiling tools',
      'Good communication skills'
    ],
    preferredSkills: [
      'Pet projects and open source contributions are a big plus as well'
    ],
    qualifications: ['Bachelor’s degree in CS/EE, or equivalent experience'],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  },
  {
    id: '534c4d2a-cc20-40ff-9a1a-7f8298',
    title: 'Sr. Front End Developer',
    description: [
      `We're looking for a Sr. Front End Developer with an eye for best practices and scalability. We'd like to see a generalist who thinks seriously about what is best for a product and displays the following:`
    ],
    locations: ['Hyderabad, Bangalore'],
    responsibilities: [
      'Drive new feature development - from ideation to PoC to testing to scalable production deployments',
      'Writing reusable, testable, and efficient code',
      'Implementing highly scalable, secure, robust, and fault-tolerant systems in a microservices ecosystem',
      'Working on continuously improving tools and systems',
      'Work alongside an excellent, cross-functional team across Engineering, Product and Design'
    ],
    requiredSkills: [
      'An overall industry experience of 5+ years',
      'At least 4 years of experience writing, testing, optimizing code',
      'Solid understanding of the React ecosystem on the client side',
      'Strong proponent for best practices and code quality',
      'Use of TypeScript or Flow , comfortable using ES6/ES7',
      'Experience with Test-driven development',
      'Familiarity with performance optimization and profiling tools',
      'Good communication skills'
    ],
    preferredSkills: [
      'Pet projects and open source contributions are a big plus as well'
    ],
    qualifications: ['Bachelor’s degree in CS/EE, or equivalent experience'],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffees'
    ]
  }, */
  {
    id: '3acb2a7d-d8f0-4033-9bf8-f391657e7bc2',
    title: 'AI Engineer',
    description: [
      "The ideal candidate will have experience in the latest techniques in Artificial Intelligence, Natural Language Processing, Machine Learning (including Deep Learning approaches). Experiences in real- time collaboration is a plus. We're looking for someone with specialized knowledge and general skills. Someone who can architect new features one day and produce optimized code the next. The ideal candidate has a strong mix of education and practical experience"
    ],
    locations: ['Hyderabad, Bangalore'],
    responsibilities: [
      'Deliver a commercially deployable platform that provides an intuitive collaboration solution targeted at enterprise customers',
      'Use NLP and ML techniques to bring order to unstructured data',
      'Experience in extracting signal from noise in large unstructured datasets a plus',
      'Work within the Engineering Team to design, code, train, test, deploy and iterate on enterprise scale machine learning systems',
      'Work alongside an excellent, cross-functional team across Engineering, Product and Design'
    ],
    requiredSkills: [
      'Experience in applying machine learning techniques, Natural Language Processing or Computer Vision',
      'Strong analytical and problem-solving skills',
      'Solid software engineering skills across multiple languages including but not limited to Java or Python, C/C++',
      'Problem solver - able to work independently, and be comfortable with deadlines and milestones',
      'Deep understanding of ML techniques such as: classification, clustering, deep learning, optimization methods, supervised and unsupervised techniques',
      'Strong communication skills and an easy going attitude',
      'Proven ability to apply, debug, and develop machine learning models for real-world applications',
      'Previous industry work experience required'
    ],
    preferredSkills: [
      'Familiarity with Git, Spacy, Tensorflow, PyTorch, Elasticsearch, Functional and Async Programming',
      'Experience with Natural Language Processing (NLP), information retrieval and extraction',
      'Experience building applications for the enterprise customers',
      'Previous early-stage company experience is a plus'
    ],
    qualifications: [],
    otherRequirements: [],
    perks: [
      'Office in Hi-tech city, close to Tech giants Google, Oracle, Deloitte, Tech M',
      'Competitive Pay',
      'Dynamic, experienced engineering team',
      'Lunch Catering',
      'Unlimited Coffee'
    ]
  }
];

export default jobDetails;
