import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Banner from '../../Banner';
import ThankYou from './ThankYou';
import { getInitialState, formFields, postToHubSpot } from './config';
import { data } from '../../Shared/BannerAndFooterData';
import {
  PrivateBetaForm,
  BetaTextContainer,
  TextAndFormWrapper,
  TextAndFormContainer,
  CustomSnackbarContent,
  RegisterNowText,
  UnderlineBorder,
  FormTitle
} from './styles';

class Beta extends Component {
  state = getInitialState();

  componentDidMount() {
    this.setState({ showForm: true });
  }

  closeOtherInput = name => {
    const updatedShowOtherInputs = {
      ...this.state.showOtherInputs,
      [name]: false
    };
    this.setState({ showOtherInputs: updatedShowOtherInputs });
  };

  openOtherInput = name => {
    const updatedShowOtherInputs = {
      ...this.state.showOtherInputs,
      [name]: true
    };
    this.setState({ showOtherInputs: updatedShowOtherInputs });
  };

  handleFormChange = (field, val) => {
    const { hubspotData } = this.state;
    hubspotData[field] = val;
    this.setState({ hubspotData });
  };

  resetState = () => {
    const {
      formStatuses: { err }
    } = this.state;
    if (err) {
      return;
    } else {
      const initialState = getInitialState();
      const { snackBar, formStatuses } = this.state;
      const newState = {
        ...initialState,
        snackBar,
        formStatuses
      };
      this.setState(newState);
    }
  };

  handleSubmit = async () => {
    const {
      snackBar: { ...updatedSnackBarState },
      formStatuses: { ...updatedFormStatusesState }
    } = this.state;
    try {
      const { hubspotData } = this.state;
      const hubSpotResponse = await postToHubSpot(hubspotData);
      const {
        data: { inlineMessage }
      } = hubSpotResponse;
      const formattedStr = inlineMessage.replace('<p>', '').replace('</p>', '');
      updatedSnackBarState.open = true;
      updatedFormStatusesState.successMessage = formattedStr;
      updatedFormStatusesState.err = null;
      this.setState(
        {
          snackBar: updatedSnackBarState,
          formStatuses: updatedFormStatusesState
        },
        () => this.resetState()
      );
    } catch (err) {
      if (err.response) {
        const {
          data: { errors }
        } = err.response;
        if (errors.length) {
          const { message } = errors[0];
          if (typeof message === 'string') {
            const formattedErrMessage = message.replace(/E.*\. /, '');
            updatedFormStatusesState.err = formattedErrMessage;
            updatedFormStatusesState.successMessage = null;
            updatedSnackBarState.open = true;
            this.setState({
              formStatuses: updatedFormStatusesState,
              snackBar: updatedSnackBarState
            });
            return;
          }
        }
      }
      const defaultErrMessage = 'There was an error, please try again.';
      updatedFormStatusesState.err = defaultErrMessage;
      updatedSnackBarState.open = true;
      this.setState({
        formStatuses: updatedFormStatusesState,
        snackBar: updatedSnackBarState
      });
    }
  };

  setError = err => {
    const {
      formStatuses: { ...updatedFormStatusesState },
      snackBar: { ...updatedSnackBarState }
    } = this.state;
    updatedFormStatusesState.err = err;
    updatedSnackBarState.open = true;
    this.setState({
      formStatuses: updatedFormStatusesState,
      snackBar: updatedSnackBarState
    });
  };

  handleClose = () => {
    const {
      snackBar: { ...updatedSnackBarState }
    } = this.state;
    updatedSnackBarState.open = false;
    this.setState({ snackBar: updatedSnackBarState });
  };
  render() {
    const {
      hubspotData,
      showOtherInputs,
      showForm,
      snackBar: { open },
      formStatuses: { err, successMessage }
    } = this.state;
    const {
      beta: {
        banner: { title, description }
      }
    } = data;
    return showForm ? (
      <div>
        <Banner title={title} description={description} />
        <TextAndFormWrapper>
          <TextAndFormContainer>
            <RegisterNowText>Register Now</RegisterNowText>
            <UnderlineBorder />
            <BetaTextContainer>
              Please submit your information below and we will be in touch
              shortly to register your company as a Beta user.
            </BetaTextContainer>
            <FormTitle>Please enter your information*</FormTitle>
            <PrivateBetaForm
              showOtherInputs={showOtherInputs}
              formId="Private-Beta"
              formFields={formFields}
              onChange={this.handleFormChange}
              formValues={hubspotData}
              formSubmitContent="Register"
              onClickSubmit
              handleSubmit={this.handleSubmit}
              setError={this.setError}
              openOtherInput={this.openOtherInput}
              closeOtherInput={this.closeOtherInput}
            />
            <Snackbar
              open={open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              onClose={this.handleClose}
              autoHideDuration={10000}
            >
              <CustomSnackbarContent
                mode={err ? 'error' : 'success'}
                message={err || successMessage}
              />
            </Snackbar>
          </TextAndFormContainer>
        </TextAndFormWrapper>
      </div>
    ) : (
      <div>
        <Banner title={title} description={description} />
        <ThankYou />
      </div>
    );
  }
}

export default Beta;
