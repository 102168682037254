import React from 'react';
import {
  ItemContainer,
  ItemLink,
  DateText,
  TitleText,
  Footer,
  SourceName,
  SourceLogo
} from './styles';

const PressItem = ({ date, title, link, name, logo }) => (
  <ItemContainer>
    <ItemLink href={link} target="_blank">
      <DateText>{date}</DateText>
      <main>
        <TitleText>{title}</TitleText>
      </main>
      <Footer>
        <SourceName>{name}</SourceName>
        {logo && <SourceLogo src={logo} alt={`${name} logo`} />}
      </Footer>
    </ItemLink>
  </ItemContainer>
);

export default PressItem;
