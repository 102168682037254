import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HuddlLogoTextFont } from '../../Shared/fonts';
import { Roboto } from '../../Shared/fonts';

export const LargeScreenNavContainer = styled.div`
  display: none;
  @media screen and (min-width: 750px) {
    display: flex;
    position: fixed;
    background: white;
    z-index: 10;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid rgb(207, 206, 207);
  }
  @media screen and (min-width: 900px) {
    padding: 0 10rem 0 5rem;
  }
`;

export const LeftNavAligner = styled.div`
  display: flex;
`;

export const RightNavAligner = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoTextContainer = styled(Link)`
  && {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

export const HuddlLogo = styled.img`
  height: 6rem;
  width: 6rem;
`;

export const HuddlText = styled.div`
  font: 300 3rem ${HuddlLogoTextFont};
  color: black;
`;

export const LargeScreenLinksContainer = styled.div`
  display: flex;
  min-width: 45rem;
  padding-right: 2rem;
`;

export const IndividualLinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  margin-right: 4rem;
  justify-content: space-between;
  margin-left: ${({ showBetaButton }) => (showBetaButton ? '0' : '2rem')};
`;

export const LargeScreenNavLink = styled(Link)`
  && {
    text-decoration: none;
    text-transform: capitalize;
    font: 500 1.4rem ${Roboto};
    color: #646464;
  }
`;

export const LinkBorderBottom = styled.div`
  background: #7b47ef;
  align-self: flex-end;
  margin-top: 2rem;
  width: 100%;
  height: 2.75px;
`;

export const BetaButtonWrapper = styled.div`
  padding-top: 1rem;
`;

export const ExternalLinks = styled.a`
  color: #7b47ef;
  margin: 0 4rem 0 0;
  text-decoration: none;
  font-size: 1.4rem;
  white-space: nowrap;
  ${({ isButtonStyle }) =>
    isButtonStyle
      ? 'color: #fff; background: #705ddc; padding: 0.7rem 2rem; border-radius: 0.5rem;'
      : 'color:#705ddc;'}
`;
