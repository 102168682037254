import * as React from "react";
const SpeechNlpModelIcon = (props) => (
  <svg
    width={46}
    height={38}
    viewBox='0 0 46 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.34766 13.6586V8.31769L14.3477 4.14187V17.8344L9.34766 13.6586ZM7.83634 5.67125H6H2C0.895431 5.67125 0 6.56668 0 7.67125V14.476C0 15.5806 0.89543 16.476 2 16.476H6H8.04107L14.0656 21.5075C15.3679 22.5951 17.3477 21.6692 17.3477 19.9725V2.00383C17.3477 0.30714 15.3679 -0.618832 14.0656 0.468766L7.83634 5.67125ZM3 13.476V8.67125H6V13.476H3ZM19.3208 4.85185C19.7261 4.47671 20.3588 4.50119 20.7339 4.90651C23.7893 8.20777 23.6982 13.0763 20.8037 16.9856C20.475 17.4295 19.8488 17.5229 19.4049 17.1942C18.9611 16.8656 18.8677 16.2393 19.1963 15.7955C21.6351 12.5016 21.5441 8.7263 19.2661 6.26501C18.891 5.85968 18.9154 5.22699 19.3208 4.85185ZM23.7769 2.00938C23.4292 1.5803 22.7995 1.51435 22.3704 1.86207C21.9413 2.20979 21.8754 2.83951 22.2231 3.26859C25.7491 7.61958 26.1105 13.2928 22.1878 18.7539C21.8656 19.2024 21.968 19.8273 22.4166 20.1495C22.8652 20.4717 23.49 20.3692 23.8122 19.9207C28.2334 13.7656 27.8995 7.09655 23.7769 2.00938ZM29 23.2663C29 22.714 29.4477 22.2663 30 22.2663H38C38.5523 22.2663 39 22.714 39 23.2663C39 23.8186 38.5523 24.2663 38 24.2663H30C29.4477 24.2663 29 23.8186 29 23.2663ZM22 26.1953C21.4477 26.1953 21 26.643 21 27.1953C21 27.7476 21.4477 28.1953 22 28.1953H38C38.5523 28.1953 39 27.7476 39 27.1953C39 26.643 38.5523 26.1953 38 26.1953H22ZM21 31.1243C21 30.5721 21.4477 30.1243 22 30.1243H38C38.5523 30.1243 39 30.5721 39 31.1243C39 31.6766 38.5523 32.1243 38 32.1243H22C21.4477 32.1243 21 31.6766 21 31.1243ZM14 29.6776C14 28.8491 14.6716 28.1776 15.5 28.1776C16.3284 28.1776 17 28.8491 17 29.6776V34.0001C17 34.5524 17.4477 35.0001 18 35.0001H42C42.5523 35.0001 43 34.5524 43 34.0001V20.3905C43 19.8382 42.5523 19.3905 42 19.3905H30.5C29.6716 19.3905 29 18.719 29 17.8905C29 17.0621 29.6716 16.3905 30.5 16.3905H42C44.2091 16.3905 46 18.1814 46 20.3905V34.0001C46 36.2092 44.2091 38.0001 42 38.0001H18C15.7909 38.0001 14 36.2092 14 34.0001V29.6776Z'
      fill='url(#paint0_linear_3500_142)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3500_142'
        x1={28}
        y1={17}
        x2={24.3108}
        y2={39.6014}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#A241E2' />
        <stop offset={1} stopColor='#534DFD' />
      </linearGradient>
    </defs>
  </svg>
);
export default SpeechNlpModelIcon;
