import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Body,
  GetStartedTitle,
  BannerHeader,
  BannerBlock,
  BannerHeading,
  BannerInfo,
  GetStartedInner,
  CircleDiv,
  AppSupportWrapper,
  ContactSection,
  ContactHeading,
  ContactSub,
  SupportBlock,
  SupportDiv,
  SupportTitle,
  SupportMail,
  AddressDiv,
  AddressText,
  SupportForm,
  EmailInput,
  DescriptionArea,
  SubmitBtn,
  Hint,
  MailSent
} from './styles';
import LocationIcon from '../ZoomFaqs/Location';
import { validateEmail } from '../helper';
import { postSupportData } from '../config';

const ZoomSupport = () => {
  const [isValid, setIsvalid] = useState(true);
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [mailSent, setMailSent] = useState(false);
  const inputEl = useRef();

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const handleDescription = e => {
    if (e && e.currentTarget && e.currentTarget.value) {
      const data = e.currentTarget.value;
      setDescription(data);
    } else {
      setDescription('');
    }
  };

  const changeHandler = e => {
    if (e && e.currentTarget && e.currentTarget.value) {
      const email = e.currentTarget.value;
      const isValidEmail = validateEmail(email);
      setIsvalid(isValidEmail);
      setEmail(email);
    } else {
      setIsvalid(false);
    }
  };

  const resetState = () => {
    setIsvalid(true);
    setEmail('');
    setDescription('');
    setTimeout(() => {
      setMailSent(false);
    }, 8000);
  };

  const handleFormSubmit = async e => {
    e && e.preventDefault();
    if (isValid && Boolean(description)) {
      console.log(email, description);
      try {
        const res = await postSupportData(email, description);
        console.log('Mail sent', res);
        setMailSent(true);
        resetState();
      } catch (err) {
        console.error('Support form submission failed');
      }
    }
  };
  const canSubmit = Boolean(description) && isValid;

  return (
    <>
      <Container>
        <BannerHeader>
          <BannerBlock>
            <BannerHeading>huddl.ai support</BannerHeading>
          </BannerBlock>
          <BannerInfo>
            If you think that huddl.ai app has an issue, please post your issue
            using the comment box below and someone from our team would get
            back. Also in the mean time you can check the{' '}
            <a href="https://www.huddl.ai/huddl-with-zoom-faqs">FAQ's</a> page
          </BannerInfo>
        </BannerHeader>
        <Body>
          <AppSupportWrapper>
            {mailSent && (
              <MailSent>Issue reported. We will get back to you soon</MailSent>
            )}
            <GetStartedInner>
              <GetStartedTitle>
                <CircleDiv />
                Post your issue
              </GetStartedTitle>
            </GetStartedInner>
            <SupportForm onSubmit={handleFormSubmit}>
              <EmailInput
                type="text"
                onChange={changeHandler}
                placeholder="Your email"
                value={email}
                ref={inputEl}
              />
              {!isValid && <Hint>Enter valid email</Hint>}
              <DescriptionArea
                type="textarea"
                placeholder="Description"
                onChange={handleDescription}
                value={description}
              ></DescriptionArea>

              <SubmitBtn
                type="button"
                onClick={handleFormSubmit}
                disabled={!canSubmit}
              >
                Submit
              </SubmitBtn>
            </SupportForm>
          </AppSupportWrapper>
        </Body>
      </Container>
      <ContactSection>
        <ContactHeading>Contact Us</ContactHeading>
        <ContactSub>
          We’d love to hear from you. We are here to help.
        </ContactSub>

        <SupportBlock>
          <SupportDiv>
            <SupportTitle>General Inquiries</SupportTitle>
            <SupportMail
              href="mailto:info@huddl.ai?subject=General Inquiries"
              target="_blank"
            >
              info@huddl.ai
            </SupportMail>
          </SupportDiv>
          <SupportDiv>
            <SupportTitle>Support</SupportTitle>
            <SupportMail
              href="mailto:support@huddl.ai?subject=Support"
              target="_blank"
            >
              support@huddl.ai
            </SupportMail>
          </SupportDiv>
          <SupportDiv>
            <SupportTitle>Careers</SupportTitle>
            <SupportMail
              href="mailto:careers@huddl.ai?subject=Careers"
              target="_blank"
            >
              careers@huddl.ai
            </SupportMail>
          </SupportDiv>
        </SupportBlock>
        <AddressDiv>
          <LocationIcon />
          <AddressText>321 Castro St. Mountain View CA 94041</AddressText>
        </AddressDiv>
      </ContactSection>
    </>
  );
};

export default ZoomSupport;
