import styled, {css} from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  padding: 5% 5% 10% 10%;
  overflow: auto;
  color: black;
`;

export const HyperLink = styled.a`
  color: black;
  font-family: 'Roboto';
  text-decoration: none;
  text-decoration: underline;
`;

export const Category = styled.div`
  margin: 0 0 30px 0;
  padding: 0;
  font-family: 'Roboto';
  font-size: 11px;
  font-weight: 300;
  line-height: 19px;
`;

export const Header = styled.h5`
  font-family: 'Roboto';
  padding: 0;
  margin: 0 0 15px 0;
  letter-spacing: 1px;
  font-size: 11px;
`;

export const HeaderLarge = styled.h3`
  font-family: 'Roboto';
  padding: 0;
  margin: 0 0 15px 0;
  letter-spacing: 1px;
`;

export const ReleaseDate = styled.p`
  font-family: 'Roboto';
  display: block;
  letter-spacing: 0.5px;
  margin-top: 5px;
`;

export const Description = styled.p`
  font-family: 'Roboto';
  font-size: 11px;
  font-weight: 300;
  line-height: 19px;
  display: block;
  margin-top: 5px;
`;

export const OrderedList = styled.ol``;

export const UnorderdList = styled.ul`
  list-style-position: outside;
  padding-left: 1.5rem;
`;

export const List = styled.li`
  margin: 0 0 2px 2px;
  font-size: 11px;
  list-style-position: outside;
  ${({nested}) => nested && css`
    list-style-type: circle;
    margin: 0 0 2px 12px;
  `}
`;

const commonStyle = `border: 1px solid #ddd; border-collapse: collapse;`;

export const TableBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DataTable = styled.table`
  ${commonStyle};
  width: 95%;
`;

export const TableRow = styled.tr`
  ${commonStyle};
  vertical-align: baseline;
`;
export const TableHead = styled.th`
  ${commonStyle}
`;
export const TableData = styled.td`
  ${commonStyle}
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  padding-left: ${({ alignCenter }) => (alignCenter ? '0' : '1.5rem')};
  padding-right: ${({ alignCenter }) => (alignCenter ? '0' : '1.5rem')};
`;
