import React from 'react';
import { FeaturesPageSeenInContainer } from './styles';
import NewyorkTimes from '../../../Shared/Icons/BrandLogos/NewyorkTimes';
import TechCrunch from '../../../Shared/Icons/BrandLogos/TechCrunch';
import { withRouter } from 'react-router-dom';
import { PRESS_ROUTE } from '../../../../constants/routes';

const SeenInContainer = ({ containerRef, ratio, history }) => {
  const redirectTo = () => {
    history && history.push(PRESS_ROUTE);
  };

  return (
    <FeaturesPageSeenInContainer
      containerRef={containerRef}
      title="As seen in"
      logos={[<NewyorkTimes />, <TechCrunch />]}
      redirectTo={redirectTo}
    />
  );
};

export default withRouter(SeenInContainer);
