import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';
import MacBook from './images/pro.svg';
import WebexBg from './images/webexBg.svg';
import WebexHdBg from './images/webexlanding-bg.png';

export const Container = styled.div`
  padding-top: 5rem;
  background: url('${WebexBg}') top center no-repeat;
  background-size: contain;

  @media screen and (min-width: 1500px) {
    background: url('${WebexHdBg}') top center repeat;
  }
`;

export const BannerHeader = styled.div`
  margin: 8rem 2rem 6rem 2rem;

  @media screen and (min-width: 1100px) {
    margin: 8rem 10rem 6rem 10rem;
  }
`;

export const BannerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerHeading = styled.div`
  color: #000000;
  letter-spacing: -0.69px;
  font: 500 5rem/5.8rem ${Roboto};
`;

export const WebexAddBtn = styled.div`
  box-sizing: border-box;
  height: 4rem;
  width: 19.4rem;
  border: 1px solid #705ddc;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  color: #705ddc;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  justify-content: center;
  /* cursor: pointer; */
  opacity: 0.2;
  display: none;
`;

export const BannerInfo = styled.div`
  color: #000000;
  letter-spacing: 0;
  font: 300 2rem/3rem ${Roboto};
`;

export const ContentImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 0.7rem;
  max-width: 80rem;
  margin: 0 auto;
`;

export const Body = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 6rem 0;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const JoinHuddl = styled.div`
  width: 78rem;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: 750px) {
  }
`;

export const SubHeader = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 1rem;
  margin-bottom: 0;
  @media screen and (max-width: 750px) {
  }
`;

export const AnchorLink = styled.a`
  font-weight: 1.8rem;
  text-decoration: none;
  @media screen and (max-width: 750px) {
  }
`;

export const Slide = styled.img``;

export const WebexSteps = styled.div`
  width: 87rem;
  margin: 0 auto;
  text-align: center;
  position: relative;
  background-image: url(${MacBook});
  background-repeat: no-repeat;
  height: 55rem;
  background-size: contain;
  max-width: 110rem;
  @media screen and (min-width: 1100px) {
    width: 100rem;
    height: 70rem;
    max-width: 110rem;
  }
`;
export const Steps = styled.p`
  color: #202020;
  font: 1.2rem ${Roboto};
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 3rem;
`;

export const GetStartedWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 72.5rem;
  margin: 0 auto;
  margin-top: -5rem;
  padding: 0 1rem;
  @media screen and (min-width: 1100px) {
    max-width: 110rem;
  }
`;

export const GetStartedInner = styled.div`
  max-width: 60rem;
  @media screen and (min-width: 1100px) {
    width: 80rem;
    max-width: 110rem;
  }
`;

export const GetStartedTitle = styled.h4`
  color: #252525;
  font: 500 2.5rem ${Roboto};
  letter-spacing: 0;
  line-height: 29px;
  position: relative;
  filter: saturate(0.5);
`;
export const CircleDiv = styled.div`
  height: 70px;
  width: 70px;
  opacity: 0.55;
  background-color: #eae6ff;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: -22px;
  left: -28px;
`;
export const GetStartedList = styled.ol`
  list-style: number;
  margin: 0;

  padding-left: 20px;
`;
export const GetStartedListItem = styled.li`
  color: #252525;
  font: 1.6rem ${Roboto};
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 1rem;
  padding-left: 10px;
`;

export const WebexLink = styled.a`
  text-decoration: none;
  width: 22rem;
  background-color: white;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-top: 2rem;
  color: #705ddc;
  font: 500 1.4rem ${Roboto};
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  border: 1px solid #705ddc;
  &:hover {
    background: #7b47ef;
    color: white;
  }
`;

export const WebexAllStepsWrapper = styled.div`
  height: 38rem;
  background: #000;
  width 100%;
  margin-top: 3rem;
`;

export const WebexVideo = styled.video`
  width: 100%;
`;

export const FaqsWrapper = styled(GetStartedWrapper)`
  margin-top: 0.5rem;
`;

export const FaqsOrederList = styled.ol`
  padding: 1rem 2rem;
  font-size: 1.6rem;
`;

export const GoToHuddlBlock = styled.div`
  height: 15.4rem;
  width: 100%;
  background-color: #faf9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4.8rem auto;
`;

export const WebLink = styled.a`
  color: #705ddc;
  margin-left: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  display: block;
`;

export const HuddlText = styled.div`
  width: 60rem;
  font: 500 1.8rem/2.8rem ${Roboto};
  @media screen and (min-width: 1100px) {
    width: 80rem;
  }
`;
