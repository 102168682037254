import styled from 'styled-components';
import LogosContainer from '../../../Shared/LogosContainer';

export const FeaturesPageTrustedByContainer = styled(LogosContainer)`
  z-index: 1;
  padding: 3rem 0 0;
  margin: 1rem 0 0 0;
  border-top: 1px solid rgb(208, 208, 208);

  .logos-container-title {
    padding: 0 2rem;
  }
  .logos-container-div {
    padding: 0 2rem;
  }
  .logos-container-brand {
    margin: 0.5rem 3rem 2rem;
  }

  @media screen and (max-width: 500px) {
  }

  @media screen and (min-width: 800px) {
  }
`;
