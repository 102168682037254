import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';

/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import { unregister } from './registerServiceWorker';
import './index.css';

unregister();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
