import styled from 'styled-components';

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;

  @media screen and (min-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
`;

export const AddressText = styled.div`
  font-size: 3rem;
  color: #4a4a4a;
  width: 75%;
  align-self: center;
  text-align: center;
  margin-top: 3rem;
  @media screen and (min-width: 800px) {
    align-self: flex-start;
    text-align: left;
  }
`;

export const EmailAndTitleContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 17rem;
  @media screen and (min-width: 800px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

export const EmailTitle = styled.div`
  font-size: 2rem;
  font-weight: 300;
  color: black;
`;

export const Email = styled.div`
  font-size: 2rem;
  color: #868686;
`;
