import styled from 'styled-components';
import { Roboto } from '../../../../Shared/fonts';

export const DescriptionTextAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    width: 40%;
  }
`;

export const DescriptionTextAndTitleContainerInner = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 40rem;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 800px) {
    margin-top: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const DescriptionHeading = styled.div`
  width: fit-content;
  position: relative;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #888;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 1rem;
    top: calc(100% + 4px);
    background: #f2f2f2;
  }
`;

export const DescriptionTitle = styled.div`
  font-size: 3.6rem;
  font-weight: 700;
  color: #252525;
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (min-width: 1000px) {
    text-align: left;
  }
`;

export const DescriptionText = styled.div`
  font: 400 1.6rem ${Roboto};
  width: 31rem;
  text-align: center;
  padding-bottom: 2rem;
  line-height: 2.5rem;
  @media screen and (min-width: 724px) {
    width: 40rem;
    max-width: 100%;
    text-align: left;
  }
`;

export const DescriptionImageContainer = styled.div`
  @media screen and (min-width: 800px) {
    width: 50%;
  }
`;

export const DescriptionMainImage = styled.video`
  max-width: 100%;
`;

export const DescriptionImage = styled.img`
  max-width: 90%;
  margin-left: 2em;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 6rem 0;
  flex-direction: column-reverse;
  align-items: stretch;
  justify-content: center;
  background: ${({ index }) => (index % 2 !== 0 ? '#faf8fd' : 'white')};

  @media screen and (min-width: 800px) {
    flex-direction: ${({ index }) => (index % 2 !== 0 ? 'row-reverse' : 'row')};
  }
`;
