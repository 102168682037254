import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const SectionContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5rem;
`;

export const SectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  width: 100%;
  color: #252525;
  font: 2.5rem ${Roboto};
  margin-bottom: 1rem;
`;

export const SectionTextSentence = styled.div`
  color: #252525;
  font: 300 1.8rem ${Roboto};
  line-height: 2.6rem;
`;
