const numbersDatav2 = [
  {
    key: 'global-access-number-1',
    country: 'Brazil',
    number: '+552135002499',
    dialIn: '+55 21 3500 2499',
    vanity: ''
  },
  {
    key: 'global-access-number-2',
    country: 'Israel',
    number: '+972553390220',
    dialIn: '+972 5533 90220',
    vanity: ''
  },
  {
    key: 'global-access-number-3',
    country: 'Mexico',
    number: '+525541724835',
    dialIn: '+52 554 172 4835',
    vanity: ''
  },
  {
    key: 'global-access-number-4',
    country: 'Spain',
    number: '+34876148335',
    dialIn: '+34 876 148 335',
    vanity: '+34 8761 HUDDL'
  },
  {
    key: 'global-access-number-5',
    country: 'United Kingdom - National',
    number: '+448458348335',
    dialIn: '+44 845 834 8335',
    vanity: '+44 845 83 HUDDL'
  },
  {
    key: 'global-access-number-6',
    country: 'United Kingdom',
    number: '+441228248335',
    dialIn: '+44 122 824 8335',
    vanity: '+44 122 82 HUDDL'
  },
  {
    key: 'global-access-number-7',
    country: 'United States (Toll-free)',
    number: '18444648335',
    dialIn: '1 844 4648335',
    vanity: '1 844 GOHUDDL'
  },
  {
    key: 'global-access-number-9',
    country: 'United States ',
    number: '+12054833533',
    dialIn: '+1 205 483 3533',
    vanity: '+1 205 HUDDL 33'
  },
  {
    key: 'global-access-number-10',
    country: 'United States ',
    number: '+16697709390',
    dialIn: '+1 669 770 9390',
    vanity: ''
  }
];
export default numbersDatav2;
