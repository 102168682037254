import React from 'react';
import IconBase from '../../IconBase';

const widthUnit = 146;
const heightUnit = 40;

const TechM = ({
  width = widthUnit,
  height = heightUnit,
  className = '',
  ...props
}) => (
  <IconBase
    viewBox={`0 0 ${widthUnit} ${heightUnit}`}
    width={width}
    height={height}
    className={className}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M11.198 17.62l-.002-13.505 6.439.001V.001L0 0v4.115l6.428.002V17.62h4.77zm50.106.22V.22h-4.722v17.62h4.722zm-7.658 0v-3.353h-8.822c-.738 0-1.331-.587-1.331-1.318h-.016v-4.03h.016c0-.732.593-1.32 1.33-1.32h6.652c.329 0 .545-.125.801-.304.358-.251.44-.581.597-.97l.745-1.843H41.52c-1.539 0-2.805 1.24-2.805 2.781v7.577c0 1.54 1.266 2.78 2.805 2.78h12.126zm-21.255-.162c.526 0 1.037-.254 1.324-.667l1.85-2.685H25.384a1.325 1.325 0 01-1.244-1.32v-5.13h7.438v1.477a.53.53 0 01-.526.524H26.1v1.907c0 .46.288.714.686.714h7.167a1.75 1.75 0 001.77-1.747V6.064c0-.032 0-.048-.015-.08a1.46 1.46 0 00-1.451-1.223H19.496v10.136a2.78 2.78 0 002.792 2.781h10.103zm41.042.162V7.703c0-1.54-1.261-2.78-2.808-2.78h-6.369c-.543 0-.814.27-1.101.699-.007.007-.397.565-.816 1.168l-.23.33-.769 1.103-.036.052h6.145c.703.032 1.26.604 1.26 1.319v8.246h4.724z"
        fill="gray"
      />
      <path
        d="M68.676 28.664l4.722-3.114v-3.256h-4.722v6.37zm-15.042 11.32v-17.62h-4.722v17.62h4.722zm-48.928 0V26.48h5.041a.87.87 0 01.862.875v12.63h4.722v-12.63c0-.494.399-.875.877-.875h3.717c.718 0 1.309.572 1.309 1.288v12.217h4.706V25.605a3.241 3.241 0 00-3.255-3.24H0v17.62h4.706zm108.337 0v-17.62h-4.738V36.76h-6.844c-.479 0-.814-.414-.814-.763v-4.259h.016c0-.73.59-1.318 1.324-1.318h3.462c.51 0 1.037-.302 1.26-.651l1.596-2.701h-9.603a2.789 2.789 0 00-2.793 2.78v7.817c0 1.493 1.196 2.32 2.297 2.32h14.837zm-47.444 0V29.847c0-1.54-1.26-2.78-2.808-2.78h-6.205c-.543 0-.814.27-1.101.7-.007.007-.397.565-.816 1.167l-.23.33-.427.613-.172.247-.206.296h5.983c.702.032 1.26.603 1.26 1.318v8.246H65.6zm15.682 0V30.42h5.392c.734 0 1.34.588 1.34 1.319v8.246h4.739V29.848c0-1.541-1.26-2.78-2.808-2.78H76.527v12.917h4.754zm39.532 0v-8.23c0-.73.527-1.35 1.277-1.35h3.525c.51-.031.942-.333 1.213-.73.007-.008.396-.556.816-1.147l.23-.324.804-1.135h-10.672c-1.181 0-1.947.905-1.947 1.875v11.042h4.754zm24.52 0V29.849a2.779 2.779 0 00-2.792-2.78H131.63c-.542 0-1.036.254-1.34.668l-1.834 2.684h10.991c.686.032 1.245.62 1.245 1.319l-.017 5.131h-8.231v-1.477a.53.53 0 01.526-.525h5.76v-1.906c0-.46-.288-.715-.687-.715h-7.977a1.75 1.75 0 00-1.77 1.748v4.687c0 .032 0 .048.016.08a1.46 1.46 0 001.452 1.223h15.57zm-99.356 0V29.849a2.779 2.779 0 00-2.792-2.78H32.273c-.526 0-1.037.254-1.324.668L29.1 30.42H40.09c.686.032 1.245.62 1.245 1.319v5.131h-8.248v-1.477a.53.53 0 01.526-.525h5.759v-1.906c0-.46-.287-.715-.686-.715H30.71a1.75 1.75 0 00-1.77 1.748v4.687c0 .032 0 .048.016.08a1.46 1.46 0 001.452 1.223h15.57zm27.456 0V27.212s-1.147.708-2.367 1.467l-.35.217-1.017.635c-.59.381-.989 1.017-.989 1.732v8.723h4.723z"
        fill="#CBCBCB"
      />
    </g>
  </IconBase>
);

export default TechM;
