import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const BottomPortionContainer = styled.div`
`;

export const LeadershipImage = styled.img`
  height: 80%;
  width: 100%;
`;

export const LeadershipTextAndMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeadershipTextTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 75%;
  align-self: center;
  font: 300 3rem ${Roboto};
  color: #252525;
  text-align: center;

  @media screen and (min-width: 880px) {
    width: 60rem;
  }
`;

export const InvestorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 5rem;
`;

export const InvestorImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 900px) {
    flex-direction: row;
    height: 20rem;
  }
`;

export const InvestorImg = styled.img`
  width: 20rem;
  filter: invert(0.2);
  margin-top: 2rem;
  @media screen and (min-width: 900px) {
    margin-right: 5rem;
    margin-top: 0;
  }
`;

export const PropelInvestorImg = styled(InvestorImg)`
  width: 14rem;
  filter: invert(0.2);
`;