import React, { Component } from 'react';
import {
  Container,
  Body,
  BannerHeader,
  BannerBlock,
  BannerHeading,
  WebexAddBtn,
  BannerInfo,
  GoToHuddlBlock,
  WebLink,
  HuddlText
} from './styles';

import Banner from '../../Banner';
// import FaqsContent from './Faqs';
import WebexContent from './WebexContent';

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;

//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: 'block',
//         width: '40px',
//         height: '40px'
//       }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <img
//       style={{ ...style }}
//       className={className}
//       alt="prev"
//       onClick={onClick}
//       src={NextArrow}
//     />
//   );
// }

class Webex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      isAuthanticated: false,
      slideIndex: 0,
      slides: 0
    };
  }

  componentDidMount() {
    this.setState({ isAuthanticated: true });
  }

  render() {
    const { isAuthanticated } = this.state;
    return (
      <Container>
        {isAuthanticated ? (
          <>
            <BannerHeader>
              <BannerBlock>
                <BannerHeading>huddl.ai for Webex</BannerHeading>
                <WebexAddBtn>Add huddl.ai to Webex</WebexAddBtn>
              </BannerBlock>
              <BannerInfo>
                Capture important meeting moments, add to enterprise workflows
                and track results
              </BannerInfo>
            </BannerHeader>
            <Body>
              <WebexContent />
              <GoToHuddlBlock>
                <HuddlText>
                  ​To access all your meeting notes at a single place, go to{' '}
                  <WebLink href="https://web.gohuddl.com" target="_blank">
                    web.gohuddl.com
                  </WebLink>
                </HuddlText>
              </GoToHuddlBlock>
            </Body>
          </>
        ) : (
          <Banner
            isWebex
            title="Page not found."
            description="Try going back to home to explore more."
            // link={<WebexLink href="/">Add huddl.ai to webex</WebexLink>}
          />
        )}
      </Container>
    );
  }
}

export default Webex;
