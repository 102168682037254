import styled from 'styled-components';
import TextContainer from '../../../Shared/TextContainer';
import { Roboto } from '../../../Shared/fonts';

const font1x = '300 1.3rem';
const font2x = '300 1.6rem/2.5rem';
const font3x = '3rem';

export const DownloadContainer = styled.div`
  position: relative;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 5.5rem;
  background: #f2f1ff;
`;

export const MainImage = styled.img`
  max-width: calc(100% - 4rem);
  width: 100rem;
  margin: 0 auto;
  display: block;
`;

export const DownloadsLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DownloadsLinkTextContainer = styled(TextContainer)`
  .text-container-title {
    font: ${font3x} ${Roboto};
    color: #4a4a4a;
    margin-bottom: 1rem;
    margin-top: 5rem;
  }
  .text-container-description {
    font: ${font2x} ${Roboto};
    color: #535353;
  }
`;

export const DownloadIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 0.5rem 0
  width: 100%;
`;

export const DownloadIconText = styled.div`
  font: ${font1x} ${Roboto};
  color: #878787;
  align-self: center;
`;

export const Icon = styled.img`
  margin: 1rem;
  &:hover {
    filter: invert(1);
  }
`;

export const SeeAllPlatformsText = styled.div`
  font: ${font2x} ${Roboto};
  color: #4a4a4a;
  text-decoration: underline;
  margin-bottom: 5.2rem;
  cursor: pointer;
`;

export const HurryText = styled.div`
  font: ${font2x} ${Roboto};
  color: #4a4a4a;
  margin-bottom: 5.2rem;
`;

export const HurryLink = styled.a`
  font: ${font2x} ${Roboto};
  color: '#1B72E8';
  text-decoration: underline;
`;

export const AppsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const AppsIcon = styled.img``;

export const DownloadLink = styled.a`
  text-decoration: none;
  margin-top: 2rem;
`;

export const DownloadButton = styled.button`
  height: 6rem;
  width: 30rem;
  padding: 0.5rem;
  border: 1px solid #7b47ef;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 2rem;
  color: #464547;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #7b47ef;
    color: white;
  }
`;
