import React, { Component } from 'react';
import axios from 'axios';
import LargeScreenDownloadPage from './LargeScreenDownloadPage';
import MobileScreenDownloadPage from './MobileScreenDownloadPage';
import { DESKTOP_LATEST_VERSION_API } from './config';

const MAC = 'Mac';
const WINDOWS = 'Win';
const LINUX = 'Linux';
const MOBILE = 'mobile';
export const DEFAULT = 'default';

class Download extends Component {
  state = {
    osFamily: null,
    defaultDownloadLinks: {
      [MAC]: {
        url: null,
        version: null,
        fullName: 'Mac'
      },
      [WINDOWS]: {
        version: null,
        url: null,
        fullName: 'Windows'
      },
      [LINUX]: {
        version: null,
        url: null,
        fullName: 'Linux'
      }
    }
  };

  componentWillMount() {
    const {
      navigator: { platform }
    } = window;
    this.fetchDefaultLinks();
    const osFamily = this.detectOs(platform);
    this.setState({ osFamily });
  }

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  };

  /**
   * fetch download urls for all operating systems
   *
   */
  fetchDefaultLinks = async () => {
    const newDefaultLinks = { ...this.state.defaultDownloadLinks };
    try {
      // API documentation here
      // https://bitbucket.org/huddl-ai/huddl-apps-versioning/src/master/
      const latestReleaseData = await axios.get(DESKTOP_LATEST_VERSION_API);
      if (!latestReleaseData) return;

      const { data: { desktop = [] } = {} } = latestReleaseData;
      if (desktop.length === 0) return;
      const [latestVersion = {}] = desktop;
      const {
        appVersion: version,
        downloadLinks: { mac = {}, win = {}, linux = {} } = {}
      } = latestVersion;
      newDefaultLinks[MAC] = {
        ...newDefaultLinks[MAC],
        url: mac.pkg,
        version
      };
      newDefaultLinks[WINDOWS] = {
        ...newDefaultLinks[WINDOWS],
        url: win.exe,
        version
      };
      newDefaultLinks[LINUX] = {
        ...newDefaultLinks[LINUX],
        url: linux.appImage,
        version
      };
      this.setState({ defaultDownloadLinks: newDefaultLinks });
    } catch (ex) {}
  };

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  };

  /**
   * use the platform to determine the correct os family
   * if none is found, set as default
   */
  detectOs = family => {
    let osFamily;
    if (this.getMobileOperatingSystem()) {
      osFamily = MOBILE;
    } else {
      return DEFAULT;
      //  if (family.includes(LINUX)) {
      //   osFamily = LINUX;
      // } else if (family.includes(WINDOWS)) {
      //   osFamily = WINDOWS;
      // } else if (family.includes(MAC)) {
      //   osFamily = MAC;
      // } else {
      //   osFamily = DEFAULT;
      // }
    }
    return osFamily;
  };

  setOsToDefault = () => {
    const { scrollToTop } = this.props;
    this.setState({ osFamily: DEFAULT }, scrollToTop);
  };

  render() {
    const { osFamily, downloadUrl, defaultDownloadLinks } = this.state;

    const version =
      osFamily in defaultDownloadLinks
        ? defaultDownloadLinks[osFamily].version
        : defaultDownloadLinks.Mac.version;
    return (
      <div>
        {osFamily === MOBILE ? (
          <MobileScreenDownloadPage version={version} />
        ) : (
          <LargeScreenDownloadPage
            setOsToDefault={this.setOsToDefault}
            downloadUrl={downloadUrl}
            osFamily={osFamily}
            version={version}
            defaultDownloadLinks={defaultDownloadLinks}
          />
        )}
      </div>
    );
  }
}

export default Download;
