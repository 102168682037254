import styled from 'styled-components';
import { Roboto } from '../../Shared/fonts';

const formValidationColors = {
  neutral: 'rgba(255, 255, 255, 0.5)',
  error: '#ff7d76',
  valid: '#79d0ac'
};
const attachColor = '#4a90e2';

export const Label = styled.label`
  position: relative;
  width: 75%;
  margin-bottom: 1.5rem;
  padding: 0.25rem 0;
`;

export const Input = styled.input`
  height: ${({ large }) => (large ? '10rem' : '4rem')};
  width: 100%;
  padding-left: ${({ focus }) => (focus ? '0.7rem' : '0.5rem')};
  padding-bottom: ${({ focus }) => (focus ? '1rem' : '0rem')};
  padding-top: ${({ focus }) => (focus ? '2.5rem' : '0rem')};
  outline: none;
  border-radius: 3px;
  font: 300 1.5rem ${Roboto};
  color: #535353;
  border: 1px solid #d1d1d1;
  margin-top: 2rem;

  ::placeholder {
    overflow-wrap: normal;
  }
`;

export const InputText = styled.span`
  position: absolute;
  top: 0.25rem;
  font: 300 1.5rem ${Roboto};
  text-transform: capitalize;
`;

export const InputLabelText = styled(InputText)`
  left: 0.5rem;
  color: ${({ customInputLabelColor }) =>
    customInputLabelColor || 'rgba(255, 255, 255, 0.3)'};
  transition: transform 0.1s ease-in-out;
  ${({ isFileType, focus }) =>
    isFileType
      ? ''
      : `transform: ${focus ? 'translate3D(-0.5rem, -2rem, 0)' : ''}`};
`;

export const Context = styled(InputText)`
  right: 0.5rem;
  color: ${({ isFileType }) =>
    isFileType ? attachColor : formValidationColors.neutral};
`;

export const ErrorText = styled(InputText)`
  font-size: 1.25rem;
  top: calc(100% + 0.25rem);
  left: 0.5rem;
  text-transform: inherit;
  padding-bottom: 1rem;
  color: ${formValidationColors.error};
`;

export const InputPlaceHolder = styled.span`
  position: absolute;
  font: 300 1.5rem ${Roboto};
  font-size: ${({ focus }) => (focus ? '1rem' : '1.5rem')};
  color: ${({ focus }) => (focus ? '#7b47ef' : '#535353')};
  left: 2%;
  top: 50%;
  transform: ${({ focus }) =>
    focus ? 'translate3d(0rem, -0.5rem, 0px)' : null};
  transition: transform 0.1s ease-in-out;
`;
