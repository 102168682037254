import React, {useEffect, useState} from 'react';
import Banner from '../../Banner';
import {
  PlatformContainer,
  ContactButton,
  Description,
  DescriptionText,
  PlatformDetails,
  PlatformCapabilities,
  PlatformCapabilitiesItem,
  PlatformCapabilitiesItemText,
  PlatformCapabilitiesItemDesc,
  PlatformItemContainer,
  PlatformItem,
  PlatformItemIcon,
  PlatformItemTitle,
  PlatformItemDesc,
  AISection,
  AISectionTitle,
  AISectionDesc,
  ContactUsSection,
  ContactUsSectionTitle,
  ContactUsSectionButton,

} from './styles';
import { data } from '../../Shared/BannerAndFooterData';
import { Capabilities, RtcCapabilities, aaiCapabilities, conversionalCapabilities } from './constant';

const {
  platform: {
    banner: { title, description }
  }
} = data;



const PlatformPage = (props) => {
  const bannerWidth = document.getElementById('main-banner')?.clientWidth;
  const bannerHeight = document.getElementById('main-banner')?.clientHeight;
  const tabHeight = document.getElementById('capabilitiesTab')?.clientHeight;
  const rtcCapabilitiesHeight = document.getElementById('rtcCapabilities')?.clientHeight;
  console.log(props, 'props');
  const [activeCap, setActiveCap] = useState(0);
  const [isCapabilitiesSticky, setIsCapabilitiesSticky] = useState(false);
  const [isCapabilitiesDescHidden, setIsCapabilitiesDescHidden] = useState(false);
  
  // All coomented code will be removed later
  useEffect(()=>{
    // console.log('scrollY', props.scrollY);
    // if(props.scrollY < 500){
    //   setActiveCap(0);
    //   setIsCapabilitiesSticky(false);
    //   setIsCapabilitiesDescHidden(false);
    // }
    // if(props.scrollY > 500 && props.scrollY < 800){
    //   setIsCapabilitiesSticky(true);
    // }

    // if(props.scrollY > 600 && props.scrollY < 890){
    //   setIsCapabilitiesDescHidden(true);
    //   setActiveCap(0)
    // }
    // if(props.scrollY > 920){
    //   setIsCapabilitiesDescHidden(true);
    //   setActiveCap(1)
    // }
    
    if(props.scrollY > bannerHeight){
      console.log('scrollY', props.scrollY, bannerHeight + tabHeight );
      setIsCapabilitiesSticky(true);
    }
    if(props.scrollY < (bannerHeight + tabHeight)){
      console.log('Less then one')
      setIsCapabilitiesDescHidden(false);
      setActiveCap(0);
    }
    if(props.scrollY > (bannerHeight + tabHeight)){
      console.log('Greater one')
      setIsCapabilitiesDescHidden(true);
      setActiveCap(0);
    }
    if(props.scrollY > (bannerHeight + tabHeight + rtcCapabilitiesHeight - 100)){
      setActiveCap(1);
    }
  }, [props, bannerHeight, bannerWidth, rtcCapabilitiesHeight, tabHeight])

  const handleCapabilitiesClick = (i) => {
    if(activeCap === i) return;
    const scrollTop = i === 0 ? bannerHeight+tabHeight : bannerHeight+tabHeight+ rtcCapabilitiesHeight; // i === 0 ? 'rtcCapabilities' : 'aaiCapabilities';
    setTimeout(()=>{
      document.getElementById('app-container').scrollTo({
        top: scrollTop - (bannerWidth < 600 ? 90 : 128), // 90 and 128 are tab+AI section height on mobile and desktop respectively
        behavior: 'smooth',
      });
    }, 100);
  }
return(
  <PlatformContainer>
    <Banner 
      title={title}
      description={
        <Description>
          <DescriptionText>
          {description}
          </DescriptionText>
          <DescriptionText withMargin>
            Do you have any particular areas of interest?
          </DescriptionText>
        </Description>
      } 
      link={ <ContactButton href={'/contact'} >
              Contact Us
            </ContactButton>
        } 
    />        
    <PlatformDetails>
        <PlatformCapabilities isSticky={isCapabilitiesSticky} id="capabilitiesTab">
          {Capabilities.map(({title, description}, i) => (
            <PlatformCapabilitiesItem 
              key={i} isActive={activeCap === i} 
              onClick={() =>handleCapabilitiesClick(i)}
            >
              <PlatformCapabilitiesItemText>{title}</PlatformCapabilitiesItemText>
              <PlatformCapabilitiesItemDesc isHidden={isCapabilitiesDescHidden}>{description}</PlatformCapabilitiesItemDesc>
            </PlatformCapabilitiesItem>
          ))}
          
        </PlatformCapabilities>
        <PlatformItemContainer id="rtcCapabilities">
          {RtcCapabilities.map(({title, description, icon}, i) => (
            <PlatformItem key={i} >
              <PlatformItemIcon>{icon}</PlatformItemIcon>
              <PlatformItemTitle>{title}</PlatformItemTitle>
              <PlatformItemDesc>{description}</PlatformItemDesc>
            </PlatformItem>
          ))}
        </PlatformItemContainer>
      

      <div id="aaiCapabilities">
        <AISection>
          <AISectionTitle>Audio AI</AISectionTitle>
          <AISectionDesc>Audio AI leverages advanced machine learning algorithms to automatically transcribe audio files, identify speakers, and even detect emotions. Audio AI can help businesses in industries such as healthcare, legal, and education save time and improve accuracy by automating the process of transcribing audio files.</AISectionDesc>
        </AISection>

        <PlatformItemContainer>
        {aaiCapabilities.map(({title, description, icon}, i) => (
            <PlatformItem key={i} >
              <PlatformItemIcon>{icon}</PlatformItemIcon>
              <PlatformItemTitle>{title}</PlatformItemTitle>
              <PlatformItemDesc>{description}</PlatformItemDesc>
            </PlatformItem>
          ))}
        </PlatformItemContainer>
        
        <AISection>
          <AISectionTitle>Conversational  AI</AISectionTitle>
          <AISectionDesc>Conversational AI enables more natural and intuitive interactions between humans and machines. With Conversational AI, clients can build chatbots and virtual assistants that can understand and respond to user requests, providing a more personalized and efficient experience for users.</AISectionDesc>
        </AISection>

        <PlatformItemContainer>
        {conversionalCapabilities.map(({title, description, icon}, i) => (
            <PlatformItem key={i} >
              <PlatformItemIcon>{icon}</PlatformItemIcon>
              <PlatformItemTitle>{title}</PlatformItemTitle>
              <PlatformItemDesc>{description}</PlatformItemDesc>
            </PlatformItem>
          ))}
        </PlatformItemContainer>
      </div>
    </PlatformDetails>
    <ContactUsSection>
      <ContactUsSectionTitle>Interested in working with our platform and technology?</ContactUsSectionTitle>
      <ContactUsSectionButton href={'/contact'} >
        Contact Us
      </ContactUsSectionButton>
    </ContactUsSection>
  </PlatformContainer>
)};

export default PlatformPage;
