import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #7b47ef;
  align-self: flex-start;
  height: 4rem;
  width: 16rem;
  border-radius: 5px;
  border: none;
  margin: 2rem 0 2rem 0;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  align-self: center;

  @media screen and (min-width: 700px) {
    align-self: flex-start;
  }
`;
