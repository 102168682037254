import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import IconBase from '@material-ui/core/Icon';

export const IndividualContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem;
`;

export const GalleryImageLink = styled.a`
  text-decoration: none;
  &:hover {
    div {
      transform: scale(1);
      filter: blur(5px) grayscale(1);
    }
    span {
      opacity: 1;
    }
  }
`;

export const GalleryImage = styled.div`
  width: 12rem;
  height: 12rem;
  background: url(${({ src }) => src});
  background-size: cover;
  filter: grayscale(1);
  position: relative;
  @media (min-width: 800px) {
    width: 18rem;
    height: 18rem;
  }
`;

export const Icon = styled(IconBase)`
  && {
    opacity: 0;
    top: calc(50% - 3rem);
    left: calc(50% - 2.5rem);
    font-size: 5rem;
    position: absolute;
    transition: opacity 0.15s ease-in;
    color: white;
  }
`;

export const GalleryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 1.5rem;
  width: 23rem;
`;

export const NameText = styled.div`
  font-size: 1.4rem;
  color: #252525;
  line-height: 2.4rem;
  align-self: center;
  text-align: center;
`;

export const RoleText = styled.div`
  font: 300 1.2rem ${Roboto};
  color: #252525;
  line-height: 1.5rem;
  align-self: center;
  text-align: center;
`;
