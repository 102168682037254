import React from 'react';
import { FeaturesPageTrustedByContainer } from './styles';
import Nutanix from '../../../Shared/Icons/BrandLogos/Nutanix';
import TechM from '../../../Shared/Icons/BrandLogos/TechM';
import BigBasket from '../../../Shared/Icons/BrandLogos/BigBasket';

const TrustedByContainer = ({ containerRef, ratio }) => {
  return (
    <FeaturesPageTrustedByContainer
      containerRef={containerRef}
      title="Trusted by teams at"
      logos={[<Nutanix />, <TechM />, <BigBasket />]}
    />
  );
};

export default TrustedByContainer;
