import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const ThankYouContainer = styled.div`
    display: flex;
    height: 40rem;
    align-items: center;
    justify-content: center;
`;

export const ThankYouTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThankYouTitle = styled.div`
  color: #644bf7;
  font-size: 3rem;
`;

export const ThankYouDescription = styled.div`
  color: black;
  font: 300 2rem ${Roboto};
`;
