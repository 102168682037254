import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { getFilteredList } from './helpers';

import {
  GlobalAccessContainer,
  Title,
  TableContainer,
  TableHeadRow,
  StyledTableCell,
  SearchInput
} from './styles';

const GlobalAccessNumbers = ({ match, numbersData, showOneClick }) => {
  const { params: { meetingId } = {} } = match;
  const meetingIdDialInText = meetingId ? `,,${meetingId}#` : '';

  const [searchText, setSearchText] = useState('');
  const [numbersList, setNumbersList] = useState(numbersData);

  const handleSearchInput = e => {
    const {
      target: { value }
    } = e;
    setSearchText(value);
    setNumbersList(getFilteredList(value.toLowerCase(), numbersData));
  };

  return (
    <GlobalAccessContainer>
      <Title>Global Access Numbers</Title>
      <SearchInput
        type="text"
        autoFocus
        placeholder="Search for a Country"
        value={searchText}
        onChange={e => handleSearchInput(e)}
      />
      <TableContainer>
        <Table>
          <TableHeadRow>
            <TableRow>
              <StyledTableCell>Country</StyledTableCell>
              {showOneClick && (
                <StyledTableCell>One Click Join</StyledTableCell>
              )}
              <StyledTableCell>Dial-In Numbers</StyledTableCell>
            </TableRow>
          </TableHeadRow>
          <TableBody>
            {numbersList.map(row => {
              const { key, country, number, dialIn, vanity } = row;
              return (
                <TableRow key={key}>
                  <StyledTableCell>{country}</StyledTableCell>
                  {showOneClick && (
                    <StyledTableCell>
                      <a
                        href={`tel:${number}${meetingIdDialInText}`}
                      >{`${number}${meetingIdDialInText}`}</a>
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    <div>{dialIn}</div>
                    {vanity && <div>{vanity}</div>}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </GlobalAccessContainer>
  );
};

export default GlobalAccessNumbers;
