import React from 'react';
import { MenuContainer, MenuLink } from './styles';
import { externalNavigationLinks } from '../../Shared'

const Menu = ({ links, toggleMenu, loggedIn, signInLink, logOut }) => (
  <MenuContainer>
    {links.map(({ key, to, label }) => (
      <MenuLink key={key} onClick={toggleMenu} to={to}>
        {label}
      </MenuLink>
    ))}
    {
      externalNavigationLinks.map(({ key, to, label }) => (
      <MenuLink key={key} onClick={toggleMenu} to={to}>
        {label}
      </MenuLink>
    ))}
    {/* <SignIn
      toggleMenu={toggleMenu}
      logOut={logOut}
      loggedIn={loggedIn}
      signInLink={signInLink}
    /> */}
  </MenuContainer>
);

export default Menu;
