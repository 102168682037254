import styled from 'styled-components';
import TextContainer from '../../../Shared/TextContainer';
import Gallery from '../../../Shared/Gallery';
import { Roboto } from '../../../Shared/fonts';

export const TextAndGalleryContainer = styled.div``;

export const TeamGalleryAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 3rem;
`;

export const GalleryTextContainer = styled(TextContainer)`
  margin-top: 3rem;
  height: fit-content;
  .text-container-title {
    font: 300 3rem ${Roboto};
    color: #252525;
    letter-spacing: -0.53px;
    line-height: 35px;
  }

  .text-container-description {
    font: 300 1.8rem ${Roboto};
    @media screen and (min-width: 800px) {
      width: 85rem;
    }
  }
`;
export const TeamGallery = styled(Gallery)`
  border-bottom: none;
  height: fit-content;
`;

export const AdvisorGalleryAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #929292;
  background: rgb(250, 250, 250);
`;

export const AdvisorGallery = styled(Gallery)`
  padding-bottom: 5rem;
  margin-top: 0;
  border-bottom: none;
`;
