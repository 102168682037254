import styled from 'styled-components';
import { Roboto } from '../../../../Shared/fonts';

export const ToggleIcon = styled.div`
  cursor: pointer;
  visibility: hidden;
`;

export const QaBlock = styled.li`
  max-width: 70rem;
  padding: 1.2rem;
  position: relative;

  &:after {
    content: '';
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: 1px;
    position: absolute;
    left: -15px;
    margin-top: 8px;
  }

  &:hover {
    ${ToggleIcon} {
      visibility: visible;
    }
  }
`;

export const QuestionBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
export const Question = styled.div`
  color: #252525;
  font: 1.6rem/2.4rem ${Roboto};
  min-height: 2.8rem;
  ${({ showAnswer }) =>
    showAnswer ? 'font-weight: 500;' : 'font-weight: normal;'}
  user-select: none;
  transition: font-weight 0.2s ease-out;
`;
export const Answer = styled.div`
  color: #252525;
  font: 1.4rem/2rem ${Roboto};
  margin-top: 0.5rem;
  a {
    text-decoration: none;
  }
  transition: all 0.5s ease-in-out;
  ${({ showAnswer }) =>
    showAnswer
      ? 'max-height: 100vh; opacity: 1;'
      : 'max-height: 0; opacity:0; transition: all .25s;'}
`;
