import styled from 'styled-components';
import { Roboto } from '../Shared/fonts';

export const BottomBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20rem;
  width: 100%;
  background-color: #7b47ef;
  padding: 0 2rem 0 2rem;
`;

export const BottomBannerText = styled.div`
  text-align: center;
  font: 300 2rem ${Roboto};
  color: white;
  background: transparent;
`;

export const BottomBannerTitle = styled(BottomBannerText)`
  font-size: 3rem;
`;

export const BottomBannerButton = styled.button`
  height: 6rem;
  width: 20rem;
  background: transparent;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ffffff;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    background: white;
    color: #7b47ef;
  }
`;
