import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Label, ErrorText, InputPlaceHolder } from './styles';
import { RadioInput } from './Radio';
import TextAreaInput from './TextArea';

class FormField extends Component {
  state = {
    touched: false,
    focus: false,
    status: 'neutral',
    error: null
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.isFresh && nextProps.isFresh) {
      this.resetField();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setInputStatus();
    }
  }

  handleChange = e => {
    const { onChange, isFileType } = this.props;
    const { touched } = this.state;

    if (!touched) {
      this.setState({ touched: true });
      this.props.setIsFormFresh(false);
    }
    let val;
    if (isFileType) {
      if (e.target.files && e.target.files.length) {
        [val] = e.target.files;
      }
    } else {
      val = e.target.value;
    }
    if (onChange) onChange(val);
  };

  handleTouchedState = val => {
    this.setState({ touched: val });
  };

  setInputStatus = () => {
    const valid = this.validate();
    let newStatus = 'neutral';
    if (typeof valid === 'boolean') {
      newStatus = valid ? 'valid' : 'error';
    }
    this.setState({
      status: newStatus
    });
  };

  setFocus = () => {
    this.toggleFocus(true);
  };

  removeFocus = () => {
    this.toggleFocus(false);
  };

  toggleFocus = focus => {
    const toggledFocus = typeof focus === 'boolean' ? focus : !this.state.focus;
    this.setState({
      touched: false,
      focus: toggledFocus
    });
  };

  resetField = () => {
    this.setState({
      touched: false,
      focus: false,
      status: 'neutral'
    });
  };

  validate = () => {
    const { touched } = this.state;
    const { validation, value, required, labelText } = this.props;
    let res = null;
    if (touched) {
      if (!value && required) {
        res = false;
      } else if (!value && !required) {
        res = null;
      } else if (!validation) {
        res = true;
      }
      if (required) {
        const { error, valid } = validation(value, labelText);
        this.setState({ error });
        res = error ? false : valid;
      }
    }
    return res;
  };
  render() {
    const {
      type,
      formId,
      field,
      labelText,
      value,
      customInputLabelColor,
      options,
      autoFocus,
      setIsFormFresh,
      showOtherInput,
      openOtherInput,
      closeOtherInput
    } = this.props;

    const { touched, focus, status, error } = this.state;

    const isFileType = type === 'file';
    let label = labelText;
    if (isFileType && value) {
      label = value.name;
    }

    if (type === 'textarea') {
      return (
        <Label>
          <TextAreaInput
            onClick={this.setFocus}
            autoFocus={autoFocus ? true : false}
            type={type}
            onFocus={this.setFocus}
            placeholder={label}
            onBlur={this.removeFocus}
            form={formId}
            value={isFileType ? '' : value}
            focus={focus}
            onChange={this.handleChange}
            isFileType={isFileType}
          />

          {error && <ErrorText>{error}</ErrorText>}
        </Label>
      );
    }
    if (type === 'radio') {
      return (
        <RadioInput
          autoFocus={autoFocus ? true : false}
          handleTouchedState={this.handleTouchedState}
          onChange={this.handleChange}
          options={options}
          status={status}
          touched={touched}
          value={value}
          labelText={labelText}
          customInputLabelColor={customInputLabelColor}
          focus={focus}
          isFileType={isFileType}
          setIsFormFresh={setIsFormFresh}
          showOtherInput={showOtherInput}
          openOtherInput={openOtherInput}
          closeOtherInput={closeOtherInput}
          field={field}
        />
      );
    } else {
      return (
        <Label>
          <Input
            className="form-text-input"
            onClick={this.setFocus}
            autoFocus={autoFocus ? true : false}
            type={type}
            onFocus={this.setFocus}
            onBlur={this.removeFocus}
            form={formId}
            value={isFileType ? '' : value}
            focus={value.length}
            onChange={this.handleChange}
            isFileType={isFileType}
          />
          <InputPlaceHolder touched={touched} focus={value.length}>
            {label}
          </InputPlaceHolder>
          {error && <ErrorText>{error}</ErrorText>}
        </Label>
      );
    }
  }
}

FormField.propTypes = {
  type: PropTypes.string,
  formId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string)
  ]),
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validation: PropTypes.func,
  isFresh: PropTypes.bool,
  setIsFormFresh: PropTypes.func.isRequired
};

FormField.defaultProps = {
  type: 'text',
  formId: null,
  value: '',
  validation: null,
  onChange: null,
  required: false,
  isFresh: true
};

export default FormField;
