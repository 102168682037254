import React from 'react';

import {
  Body,
  GetStartedWrapper,
  GetStartedTitle,
  GetStartedList,
  GetStartedListItem,
  GetStartedInner,
  GettingStartedTitle,
  GettingStartedDesc,
  CircleDiv,
  SubSection,
  SubTitle,
  SubCircleDiv,
  BottomText
} from '../styles';

import docsList from '../docsList';

const ZoomDocsContent = ({ isIntegration }) => {
  return (
    <Body isIntegration={isIntegration}>
      <GetStartedWrapper isIntegration={isIntegration}>
        {isIntegration ? (
          <>
            <GettingStartedTitle>Getting Started</GettingStartedTitle>
            <GettingStartedDesc>
              An overview about how huddl.ai works and how to get started
            </GettingStartedDesc>
          </>
        ) : null}
        {docsList.map(({ heading, list, subSections }, index) => (
          <GetStartedInner key={`docsList_${index}`}>
            <GetStartedTitle>
              <CircleDiv />
              {heading}
            </GetStartedTitle>
            <GetStartedList>
              {list.map((point, inx) => (
                <GetStartedListItem
                  key={`listem_item_${inx}`}
                  dangerouslySetInnerHTML={{ __html: point }}
                />
              ))}
            </GetStartedList>
            {subSections && subSections.length ? (
              <SubSection>
                {subSections.map(({ subHeading, subList }, sIndex) => (
                  <div key={`Sublist-${sIndex}`}>
                    <SubTitle>
                      <SubCircleDiv />
                      {subHeading}
                    </SubTitle>
                    <GetStartedList>
                      {subList.map((subpoint, subInx) => (
                        <GetStartedListItem
                          key={`sub_list_item_${subInx}`}
                          dangerouslySetInnerHTML={{ __html: subpoint }}
                        />
                      ))}
                    </GetStartedList>
                  </div>
                ))}
              </SubSection>
            ) : null}
          </GetStartedInner>
        ))}
        <BottomText>
          For more questions, visit our{' '}
          <a
            href="https://www.huddl.ai/huddl-with-zoom-faqs"
            target="_blank"
            rel="noopener noreferrer"
          >
            frequently asked question
          </a>{' '}
          page or write to us at{' '}
          <a
            href="mailto:support@huddl.ai?subject=Support"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@huddl.ai
          </a>
        </BottomText>
      </GetStartedWrapper>
    </Body>
  );
};

export default ZoomDocsContent;
