import React, { Component } from 'react';
import {
  Container,
  BannerImage,
  //   TitleSection,
  //   Title,
  //   DateSection,
  //   DateBox,
  TextSection,
  Body,
  Summary,
  PointsList,
  ListWrapper,
  ListHeader,
  ListPoint,
  RegisterBox,
  ClickHereText,
  ClickHereLink
} from './styles';
import TechMBanner from '../../../assets/banner.jpg';
import { PointsData, SummaryText } from './data.js';

class WebinarRegistration extends Component {
  componentDidMount() {
    window.EBWidgets.createWidget({
      // Required
      widgetType: 'checkout',
      eventId: '101742314000',
      iframeContainerId: 'eventbrite-widget-container-101742314000',
      // Optional
      iframeContainerHeight: 600 // Widget height in pixels. Defaults to a minimum of 425px if not provided
    });
  }
  render() {
    return (
      <Container>
        <BannerImage src={TechMBanner} />
        <Body>
          <TextSection>
            <Summary>{SummaryText}</Summary>
            <PointsList>
              <ListHeader>Key Takeaways</ListHeader>
              <ListWrapper>
                {PointsData.map((point, index) => (
                  <ListPoint key={`${index}-point`}>{point}</ListPoint>
                ))}
              </ListWrapper>
            </PointsList>

            <ClickHereText>
              For more details about the event{' '}
              <ClickHereLink
                href="https://experience.techmahindra.com/manufacturing-ai-virtual-event"
                target="_blank"
              >
                click here
              </ClickHereLink>
            </ClickHereText>
          </TextSection>
          <RegisterBox id="eventbrite-widget-container-101742314000"></RegisterBox>
        </Body>
      </Container>
    );
  }
}

export default WebinarRegistration;
