import imageKrishnaYarlagadda from '../../../../assets/Krishna_Yarlagadda.png';
import imageNavaDavuluri from '../../../../assets/Nava_Davuluri.png';
import imageHarishRajamani from '../../../../assets/Harish_Rajamani.png';
import { AdvisorsData } from './advisors';

const linkedInKrishnaYarlagadda =
  'https://www.linkedin.com/in/krishna-yarlagadda-800114/';
const linkedInNavaDavuluri = 'https://www.linkedin.com/in/navadavuluri/';
const linkedInHarishRajamani = 'https://www.linkedin.com/in/harishrajamani/';

export const TeamData = [
  {
    imageUrl: imageKrishnaYarlagadda,
    name: 'Krishna Yarlagadda',
    role: 'Founder & CEO',
    linkedIn: linkedInKrishnaYarlagadda
  },
  {
    imageUrl: imageNavaDavuluri,
    name: 'Nava Davuluri',
    role: 'Founder & CTO',
    linkedIn: linkedInNavaDavuluri
  },
  {
    imageUrl: imageHarishRajamani,
    name: 'Harish Rajamani',
    role: 'Director of Engineering',
    linkedIn: linkedInHarishRajamani
  },
  ...AdvisorsData
];
