import styled from 'styled-components';
import Banner from '../../Banner';
import { Roboto } from '../../Shared/fonts';
import BottomBanner from '../../BottomBanner';

export const FeaturesBanner = styled(Banner)`
  border-bottom: none;
  background: none;
  height: fit-content;
  padding: 7rem 5rem 7rem;
  border-top: 0;
  padding-bottom: 2rem;

  .banner-circle {
    display: none;
  }

  .banner-title {
    color: #333;
    font-weight: 500;
    font-size: 6rem;
    text-transform: initial;
  }

  .banner-description {
    color: #333;
    font-weight: 400;
    padding: 0;
    margin: 1rem 0;
    @media screen and (min-width: 800px) {
      width: fit-content;
      max-width: 75rem;
    }
  }

  @media screen and (min-width: 675px) {
    padding-top: 12rem;
  }
`;

export const BannerAndImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RegisterForBetaInput = styled.input`
  height: 5rem;
  width: 20rem;
  padding-left: 1.2rem;
  margin-right: 0.5rem;
  font: 300 1.5rem ${Roboto};
  ::placeholder {
    color: #bebebe;
  }
  outline: none;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  @media screen and (min-width: 950px) {
    width: 36rem;
  }
`;

export const MainImageAndTextContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: ${({ isVisible }) => (!isVisible ? 'scale(1.05)' : null)};
  transition: transform 0.2s ease-in;
  @media screen and (min-width: 800px) {
  }
`;

export const MainImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const FeaturesPageBottomBanner = styled(BottomBanner)`
  justify-content: center;
  height: 23rem;
  padding: 5.5rem 2 5.5rem 2;
  .bottom-banner-text {
    font: 3rem ${Roboto};
    margin-bottom: 2rem;
    letter-spacing: -0.53px;
    line-height: 3.5rem;
  }
  .bottom-banner-button {
    height: 5rem;
    width: 25rem;
    border: 1px solid #ffffff;
    letter-spacing: -0.44px;
    line-height: 29px;
    font: 500 1.8rem ${Roboto};
    color: white;

    &:hover {
      color: #7b47ef;
    }
  }
`;

export const EarlyInvite = styled.div`
  height: 38rem;
  background: #ebe7f1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Header = styled.div`
  color: #252525;
  font-size: 3.6rem;
  line-height: 5rem;
  margin-bottom: 2rem;
  font-weight: bold;
`;
