import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';

export const BannerHeader = styled.div`
  margin: 0rem 0 6rem 0;
  min-height: 26rem;
  padding: 8rem 2rem 6rem 2rem;
  background: #f6f4ff;

  @media screen and (min-width: 1100px) {
    margin: 0rem 0 6rem 0;
    padding: 8rem 10rem 6rem 10rem;
  }
`;

export const BannerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerInfo = styled.div`
  color: #000000;
  letter-spacing: 0;
  font: 300 2rem/3rem ${Roboto};
  padding-left: 2rem;
`;

export const BannerHeading = styled.div`
  color: #202020;
  padding: 0 0 3rem 1.5rem;
  letter-spacing: -0.69px;
  font: 500 5rem/5.8rem ${Roboto};
`;

export const GetHuddlBtn = styled.a`
  text-decoration: none;
  padding: 1rem 1.5rem;
  border: 1px solid #7b47ef;
  color: #7b47ef;
  background: transparent;
  font: 500 1.4rem/1.8rem ${Roboto};
  width: 17rem;
  margin: 2rem 0 0.5rem 1.5rem;
  border-radius: 0.3rem;
  display: block;
`;
