import styled from 'styled-components';
import LogosContainer from '../../../Shared/LogosContainer';

export const FeaturesPageSeenInContainer = styled(LogosContainer)`
  z-index: 1;
  margin-top: -8rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgb(208, 208, 208);
  padding-bottom: 1rem;

  .logos-container-title {
    padding: 0 2rem;
  }
  .logos-container-div {
    padding: 0 2rem;
  }
  .logos-container-brand {
    margin-top: 0.5rem;
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    margin-top: -2rem;
  }

  @media screen and (min-width: 800px) {
    margin-top: 0;
    position: absolute;
    bottom: -4rem;
  }
`;
