import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 675px) {
    align-items: flex-start;
  }
`;

export const Fieldset = styled.fieldset``;
