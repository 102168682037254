import { privateBetaFormValidator } from './validations';
import axios from 'axios';

const { REACT_APP_PORTAL_ID, REACT_APP_FORM_ID } = process.env;
const hubSpotURL = 'https://api.hsforms.com/submissions/v3/integration/submit';

export const getInitialState = () => ({
  hubspotData: {
    company: '',
    email_provider: 'G Suite/Gmail',
    firstname: '',
    lastname: '',
    phone: '',
    work_email: ''
  },
  showOtherInputs: {
    email_provider: false
  },
  formStatuses: {
    err: null,
    successMessage: null
  },
  snackBar: {
    open: false
  },
  showForm: false
});

export const formFields = [
  {
    field: 'firstname',
    labelText: 'First Name',
    required: true,
    type: 'text',
    validation: privateBetaFormValidator,
    autoFocus: false
  },
  {
    field: 'lastname',
    labelText: 'Last Name',
    required: true,
    type: 'text',
    validation: privateBetaFormValidator,
    autoFocus: false
  },
  {
    field: 'company',
    labelText: 'Company',
    required: true,
    type: 'text',
    validation: privateBetaFormValidator,
    autoFocus: false
  },
  {
    field: 'email_provider',
    labelText: 'Email Provider',
    type: 'radio',
    options: [
      { name: 'G Suite/Gmail', textInput: false },
      { name: 'Office365', textInput: false },
      { name: 'Other', textInput: true }
    ],
    validation: privateBetaFormValidator,
    autoFocus: false
  },
  {
    field: 'phone',
    labelText: 'Phone Number',
    required: true,
    type: 'text',
    validation: privateBetaFormValidator,
    autoFocus: false
  },
  {
    field: 'email',
    labelText: 'Email',
    required: true,
    type: 'text',
    validation: privateBetaFormValidator,
    autoFocus: false
  },
  {
    field: 'work_email',
    labelText: 'Work Email',
    required: true,
    type: 'text',
    validation: privateBetaFormValidator,
    autoFocus: false
  }
];

export const postToHubSpot = data => {
  const postToHubSpotUrl = `${hubSpotURL}/${REACT_APP_PORTAL_ID}/${REACT_APP_FORM_ID}`;
  const dataToSend = prepareDataForPost(data);
  return axios.post(postToHubSpotUrl, dataToSend);
};

const prepareDataForPost = data => {
  const dataArr = [];
  for (let key in data) {
    const newDataObj = {
      name: key,
      value: data[key]
    };
    dataArr.push(newDataObj);
  }
  const finalDataObj = {
    fields: dataArr
  };
  return finalDataObj;
};
