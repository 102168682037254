import React from 'react';
import { TextAndGalleryContainer } from './styles';
import TeamGallery from './TeamGallery';
import TransitionContainer from '../../../Shared/TransitionContainer';
const Main = ({ scrolledToBottom }) => (
  <TextAndGalleryContainer>
    <TransitionContainer>
      <TeamGallery scrolledToBottom={scrolledToBottom} />
    </TransitionContainer>
  </TextAndGalleryContainer>
);

export default Main;
