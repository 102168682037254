import styled from 'styled-components';
import TextContainer from '../../../Shared/TextContainer';

export const FeaturesPageTextContainer = styled(TextContainer)`
  margin-top: 2rem;
  padding: 2rem 0;
  .text-container-title {
    padding: 2rem 2rem 0 2rem;
  }
  .text-container-description {
    padding: 0 2rem;
    margin: 1rem 0;
    @media screen and (min-width: 1100px) {
      max-width: 75rem;
    }
  }
`;
