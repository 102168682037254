import React from 'react';

const BackIcon = props => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.76343 11.7708C6.44801 12.0764 5.93661 12.0764 5.62118 11.7708L0.236567 6.55339C-0.078856 6.24776 -0.078856 5.75224 0.236567 5.44661L5.62118 0.229221C5.93661 -0.0764066 6.44801 -0.0764066 6.76343 0.229221C7.07886 0.534848 7.07886 1.03037 6.76343 1.336L1.94994 6L6.76343 10.664C7.07886 10.9696 7.07886 11.4652 6.76343 11.7708Z"
        fill="#808080"
      />
    </svg>
  );
};

export default BackIcon;
