import * as React from "react";
const CameraIcon = (props) => (
  <svg
    width={48}
    height={38}
    viewBox="0 0 48 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2923 3L17.7077 3L16.8734 5.11361C16.1197 7.02304 14.2754 8.27778 12.2226 8.27778H4C3.44772 8.27778 3 8.72549 3 9.27778V34C3 34.5523 3.44772 35 4 35H44C44.5523 35 45 34.5523 45 34V9.27778C45 8.72549 44.5523 8.27778 44 8.27778H35.7774C33.7246 8.27778 31.8803 7.02305 31.1266 5.11361L30.2923 3ZM30.2923 0C31.524 0 32.6305 0.752839 33.0827 1.8985L33.9171 4.01211C34.2185 4.77589 34.9562 5.27778 35.7774 5.27778H44C46.2091 5.27778 48 7.06864 48 9.27778V34C48 36.2091 46.2091 38 44 38H4C1.79086 38 0 36.2091 0 34V9.27778C0 7.06864 1.79086 5.27778 4 5.27778H12.2226C13.0438 5.27778 13.7814 4.77589 14.0829 4.01211L14.9173 1.8985C15.3695 0.752838 16.476 0 17.7077 0H30.2923ZM16 22C16 17.5817 19.5817 14 24 14C28.4183 14 32 17.5817 32 22C32 26.4183 28.4183 30 24 30C19.5817 30 16 26.4183 16 22ZM18 22C18 18.6863 20.6863 16 24 16C27.3137 16 30 18.6863 30 22C30 25.3137 27.3137 28 24 28C20.6863 28 18 25.3137 18 22ZM40 14C40 15.1046 39.1046 16 38 16C36.8954 16 36 15.1046 36 14C36 12.8954 36.8954 12 38 12C39.1046 12 40 12.8954 40 14Z"
      fill="url(#paint0_linear_3489_115)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3489_115"
        x1={-6}
        y1={3}
        x2={53.5}
        y2={41}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9358" />
        <stop offset={1} stopColor="#D457FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default CameraIcon;
