import React, { useState, useEffect } from 'react';
import {
  Wrapper,
  ActionButton,
  InfoBlock,
  LeftPanel,
  RightPanel,
  DivInput,
  IframeBlock
} from './styles';
import { encodeToBase64 } from '../../../utils';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

const redirectUrls = {
  dev: 'https://web-dev.huddldev.com',
  staging: 'https://web-stg.gohuddl.com',
  production: 'https://web.gohuddl.com',
  local: 'https://local.huddldev.com:3000',
  edge: 'https://web-edge.huddldev.com',
  bridge: 'https://bridge-dev.huddldev.com'
};

const getRedirectUrl = env => {
  return redirectUrls[env];
};

// const redirect = `https://web-dev.huddldev.com/huddl`;
// const redirect = `https://local.huddldev.com:3001/huddl`;
const defaultEmail = 'test2@gohuddl.com';
const defaultPassword = 'Huddl@123';
const defaultUid = encodeToBase64(`${defaultEmail}:${defaultPassword}`);

const getCredentails = {
  tavant: {
    email: defaultEmail,
    password: defaultPassword,
    uid: defaultUid
  },
  kore: {
    email: 'user@huddldev.in',
    password: 'Huddl@123!',
    uid: encodeToBase64(`user@huddldev.in:Huddl@123!`)
  },
  gohuddl: {
    email: defaultEmail,
    password: defaultPassword,
    uid: defaultUid
  }
};

const HuddlApp = ({ history }) => {
  const [showIframe, setShowIframe] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [refId, setRefId] = useState('refId567');
  const [refKey, setRefKey] = useState('gohuddl');
  const [isGuest, setIsGuest] = useState(false);
  const [url, createUrl] = useState('');
  const [env, setEnv] = useState('dev');
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState(defaultPassword);
  const [iframeDimensions, setIframeDimensions] = useState({
    width: 300,
    height: 300
  });

  const { location: { search } = {} } = history || {};
  useEffect(() => {
    const redirectFragment = qs.parse(search, {
      ignoreQueryPrefix: true
    });
    const { demo } = redirectFragment || {};
    if (demo) {
      const { email: demoEmail, password: pwd } = getCredentails[demo] || {};
      if (!demoEmail) return;
      setEmail(demoEmail);
      setPassword(pwd);
      setRefKey(demo);
    }
  }, [search]);

  useEffect(() => {
    if (env === 'edge' || env === 'bridge' || env === 'local') {
      setRefId('c4ec9112-486d-4d76-9ad9-f7feb7e6254a');
    }
  }, [env]);

  const handleSetEnvironment = e => {
    e.preventDefault();
    console.log(e.currentTarget.value);
    setEnv(e.currentTarget.value);
  };

  function receiveMessageFromHuddl(event) {
    const myFrame = document.getElementById('myFrame');
    console.log('data ==>', event);

    /* @param  {Object} event.data 
      ex:   
      {type: 'meetingEnded', payload: {meeting_id: 'afc-dga-eih-fba-ggb-degb'}}
    */
    const { data } = event || {};
    const { type, payload } = data || {};

    switch (type) {
      case 'maximizeChatView':
        // needs to maxmize iframe size to 450 x 500
        myFrame.style.height = '360px';
        myFrame.style.width = '580px';
        break;

      case 'minimzeChatView':
        // needs to minimize iframe size to 300 x 300
        myFrame.style.height = '300px';
        myFrame.style.width = '300px';
        break;

      case 'maximizeShareView':
        //needs to maxmize iframe size to full screen width: 100% height: 100%
        myFrame.style.height = '80vh';
        myFrame.style.width = '80vw';
        break;

      case 'minimzeShareView':
        // needs to minimize iframe size to 300 x 300
        myFrame.style.height = '300px';
        myFrame.style.width = '300px';
        break;

      case 'meetingEnded':
        //  close the iframe
        console.log('data ==>', event);
        createUrl('');
        setShowIframe(false);
        break;

      case 'mediaEventNotAllowed':
        break;

      case 'screenShareOn':
        // at presenter end (who do screenshare)
        break;

      case 'screenShareOff':
        // at presenter end (who do screenshare)
        break;

      case 'redirectToLoanSummary':
        const { loanId } = payload || {};
        console.log(`Loan summary, Loan Id: ${loanId}`);
        break;

      case 'latestUnreadMessage':
        const { message, author } = payload || {};
        console.log(`Latest unread message: ${message} by ${author}`);
        break;

      case 'participantJoined':
        // on participant joined
        const { name } = payload || {};
        console.log(`Name of the joined participant, userName: ${name}`);
        break;

      default:
        break;
    }
  }
  window.addEventListener('message', receiveMessageFromHuddl, false);

  const handleJoin = () => {
    if (showIframe) {
      // close Meeting
      createUrl('');
      setShowIframe(false);

      // Triggering Leave from parent
      document
        .getElementById('myFrame')
        .contentWindow.postMessage({ type: 'leaveMeeting', payload: {} }, '*');
    } else {
      let url;
      const redirect = getRedirectUrl(env);
      const uid = encodeToBase64(`${email}:${password}`);
      if (isGuest) {
        url = `${redirect}/huddl?refKey=${refKey}&refId=${refId}`;
      } else {
        url = `${redirect}/huddl?refKey=${refKey}&refId=${refId}&userHash=${uid}`;
      }
      createUrl(url);
      setShowIframe(true);
      setIframeDimensions({ width: 300, height: 300 });
    }
  };

  const handleChatJoin = () => {
    if (showChat) {
      // close Meeting
      createUrl('');
      setShowChat(false);

      // Triggering Leave from parent
      document
        .getElementById('myFrame')
        .contentWindow.postMessage({ type: 'leaveMeeting', payload: {} }, '*');
    } else {
      let url;
      const redirect = getRedirectUrl(env);
      const uid = encodeToBase64(`${email}:${password}`);
      if (isGuest) {
        if (env === 'edge') {
          url = `${redirect}/chat?refKey=${refKey}&refId=${refId}`;
        } else {
          url = `${redirect}?refKey=${refKey}&refId=${refId}`;
        }
      } else {
        if (env === 'edge') {
          url = `${redirect}/chat?refKey=${refKey}&refId=${refId}&userHash=${uid}`;
        } else {
          url = `${redirect}?refKey=${refKey}&refId=${refId}&userHash=${uid}`;
        }
      }
      createUrl(url);
      setShowChat(true);
      // setIframeDimensions({ width: 580, height: 360 });
    }
  };

  return (
    <Wrapper>
      <LeftPanel>
        <form>
          <DivInput>
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={e => {
                e.preventDefault();
                setEmail(e.currentTarget.value);
              }}
            />
          </DivInput>
          <DivInput>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={e => {
                e.preventDefault();
                setPassword(e.currentTarget.value);
              }}
            />
          </DivInput>
          <DivInput>
            <label>UserHash: encodeToBase64(email:password)</label>
            <input type="text" value={`h1ZG${defaultUid}aHerd`} disabled />
          </DivInput>

          <DivInput>
            <label>Company Name: (refKey)</label>
            <input
              type="text"
              value={refKey}
              onChange={e => {
                e.preventDefault();
                setRefKey(e.currentTarget.value);
              }}
            />
          </DivInput>
          <DivInput>
            <label>Environment</label>
            <select onChange={handleSetEnvironment} value={env}>
              <option value="dev">Development</option>
              <option value="staging">Staging</option>
              <option value="production">Production</option>
              <option value="local">Local</option>
              <option value="edge">Edge</option>
              <option value="bridge">Bridge</option>
            </select>
          </DivInput>

          <DivInput>
            <label>Reference Id: (refId)</label>
            <input
              type="text"
              value={refId}
              onChange={e => {
                e.preventDefault();
                setRefId(e.currentTarget.value);
              }}
            />
          </DivInput>

          <DivInput>
            <input
              type="checkbox"
              id="guest"
              name="guest"
              value="guest"
              checked={isGuest}
              onChange={() => {
                setIsGuest(!isGuest);
              }}
            />
            <label htmlFor="guest" style={{ display: 'inline' }}>
              Guest Join
            </label>
          </DivInput>
          <ActionButton
            onClick={() => {
              handleJoin();
            }}
          >
            {showIframe ? 'Close Meeting' : 'Start Meeting'}
          </ActionButton>
          {(env === 'edge' || env === 'local' || env === 'bridge') && (
            <ActionButton
              onClick={() => {
                handleChatJoin();
              }}
            >
              {showChat ? 'Close chat' : 'Start chat'}
            </ActionButton>
          )}
        </form>
      </LeftPanel>
      <RightPanel>
        <InfoBlock>
          <code>
            Constructed iframe url: <br />
            {url}
          </code>
        </InfoBlock>
        <IframeBlock id="iframeBlock">
          <iframe
            src={url}
            allow="camera; microphone; fullscreen; web-share; display-capture; autoplay"
            title="huddl RTC"
            height={iframeDimensions.height}
            width={iframeDimensions.width}
            id="myFrame"
          ></iframe>
        </IframeBlock>
      </RightPanel>
    </Wrapper>
  );
};

export default withRouter(HuddlApp);
