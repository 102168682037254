import React, { Component } from 'react';
import { BrandSection, LogosDiv, Title, BrandLogoBlock } from './styles';

class LogosContainer extends Component {
  render() {
    const {
      title,
      logos,
      className,
      containerRef,
      style,
      redirectTo
    } = this.props;
    return (
      <BrandSection style={style} ref={containerRef} className={className}>
        <Title className="logos-container-title">{title}</Title>

        {Array.isArray(logos) ? (
          <LogosDiv className="logos-container-div">
            {logos.map((logo, id) => (
              <BrandLogoBlock
                className="logos-container-brand"
                key={`brand-logo-${id}`}
                onClick={() => {
                  Boolean(redirectTo) && redirectTo();
                }}
              >
                {logo}
              </BrandLogoBlock>
            ))}
          </LogosDiv>
        ) : null}
      </BrandSection>
    );
  }
}

export default LogosContainer;
