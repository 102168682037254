import React, { Component } from 'react';
import {
  Container,
  Header,
  Category,
  Description,
  OrderedList,
  UnorderdList,
  List,
  HeaderLarge,
  HyperLink,
  TableBlock,
  DataTable,
  TableData,
  TableHead,
  TableRow
} from './styles';

const policyData = {
  heading: {
    title: 'Privacy Policy',
    date: 'Last modified: December 17, 2018',
    description:
      'Huddl, Inc ("us", "we", or "our") operates the huddl.ai application (the "Service"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.'
  },
  infoCollect: {
    title: 'Information We Collect',
    description:
      'huddl.ai collects device and user information to provide better service to all users, when you launch, access, and use any related services.'
  },
  infoProvide: {
    title: 'Information You Provide',
    para: [
      `Registration and Account Information - When you register for our services, you voluntarily provide your personal information (e.g., name, email, photo<auto sync when you use single-sign-on when authenticating with Huddl>) to create a huddl.ai account. You may also provide us with billing information if you choose to use any of our paid services.`,
      `Agenda, Moments, Notes & Action items(Tasks) you add into the system are stored on huddl.ai servers and available for search for a period of time based on your company policy and the data is available only for the host and the meeting attendees. Private notes are kept private (only you have access to these notes and they would show in your personal search results.`,
      `Messages - The messages that you send pass through our secure servers. We will retain such messages and make them available to you and the people that are participating in the same meeting(s) as you do however chat conversation/msgs won't be available post your meeting end.`,
      `Quick Contacts - To help you organize how you communicate with others, we may create a favorites list of your contacts for you, and you can create, join, or get added to groups, and such groups and lists get associated with your account information.`,
      `Customer Support - You may provide us with information related to your use of our Services, including copies of your messages, and how to contact you so we can provide you with customer support. For example, you may send us an email with information relating to our app performance or other issues.`
    ]
  },
  infoCollectedAuto: {
    title: 'Automatically Collected Information',
    para: [
      `Usage and Log Information - We collect service-related, diagnostic, and performance information. This includes information about your activity (such as how you use our Services, how you interact with others using our Services, and the like), log files, and diagnostic, crash, website, and performance logs and reports.`,
      `For logging and debugging we push to our trusted third-party service (Loggly). Information such as device type (Android, iOS, MAC, Win, Linux), date, APP version, meeting Id, session Id, userName, message, deviceId, userId, manufacturer, OS version, subdevice type, device model, user email.`,
      `We push Email id, user id to our trusted third-party service(Firebase Crashlytics).`,
      `Transcription - We collect in meeting conversations which are converted into the text format using our services and stored into our servers securely and are available for future references.`,
      `Device and Connection Information - We collect device-specific information when you install, access, or use our Services. This includes information such as hardware model, operating system information, browser information, IP address.`,
      `Cookies - We use cookies to operate and provide our Services, including to provide our Services that are web-based, improve your experiences, understand how our Services are being used, and customize our Services. For example, we use cookies to provide huddl.ai for web and other web-based services.`,
      `Transactional Information - If you pay for our Services, we may receive information and confirmations, such as payment receipts, including from app stores or other third parties processing your payment.`
    ]
  },
  infoThirdParty: {
    title: 'Third-Party Information',
    para: [
      `Third-Party Providers - We work with third-party providers to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with companies to distribute our apps, provide our infrastructure, delivery, and other systems, process payments, help us understand how people use our Services, and market our Services. These providers may provide us information about you in certain circumstances; for example, app stores may provide us with reports to help us diagnose and fix service issues.`,
      `These third-party vendors can access personal data subject to contractual and technical requirements for protecting personal data and prohibiting them from using personal data for any purpose other than to provide services to Huddl or as required by law.`
    ]
  },
  infoUse: {
    title: 'How We Use Information',
    description: `We use all the information we have to help us operate, provide, improve, understand, customize, support, and market our Services.`,
    para: [
      `Public info - Visible to others(Guests & Attendees): Name, email, profile photo, location, device type(Mobile, Web).`,
      `Our Services - We operate and provide our Services, including providing customer support, and improving, fixing, and customizing our Services. We understand how people use our Services, and analyze and use the information we have to evaluate and improve our Services, research, develop, and test new services and features, and conduct troubleshooting activities. We also use your information to respond to you when you contact us.`,
      `Safety and Security - We verify accounts and activity, and promote safety and security on and off our Services, such as by investigating suspicious activity or violations of our Terms, and to ensure our Services are being used legally.`,
      `No Third-Party Banner Ads - We do not allow third-party banner ads on huddl.ai. We have no intention to introduce them, but if we ever do, we will update this policy.`
    ]
  },
  infoDisclose: {
    title: 'Sharing and Disclosure of Information',
    description_1:
      'We do not rent, sell, or share any information about our users with any third parties, except as specifically described herein. We may disclose your Personal Information if we believe such action is necessary to:',
    description_2: `The following is a description of how we may share your Personal Information with trusted third-party partners. Should we add additional partners, we will immediately update this description:`,
    usePoints: [
      `Comply with the law, or legal process served on us;`,
      `Protect and defend our rights or property (including the enforcement of our agreements; or`,
      `Act in urgent circumstances to protect the personal safety of users of our Services`
    ],
    discloseTable: [
      {
        sno: 1,
        thirdPartyProvider: 'Hosting our services',
        purpose: 'Hosting our services',
        infoWeShare: ['s3', 'MySQL', 'CloudFront']
      },
      {
        sno: 2,
        thirdPartyProvider: 'Loggly',
        purpose: 'Debugging and user activities',
        infoWeShare: [
          'Device type(Android)',
          'Date',
          'App-Version',
          'Device-ID',
          'Manufacturer',
          'OS Version',
          'Subdevice Type Device-Model',
          'Meeting-ID',
          'Session-ID',
          'Message',
          'User-Name',
          'User-ID',
          'User-Email'
        ]
      },
      {
        sno: 3,
        thirdPartyProvider: 'Firebase Crashlytics',
        purpose: 'Crash related',
        infoWeShare: ['Email ID', 'User ID']
      },
      {
        sno: 4,
        thirdPartyProvider: 'Amplitude',
        purpose: 'User activities',
        infoWeShare: [
          'OS',
          'User ID',
          'OS version',
          'Model',
          'Manufacturer',
          'App version',
          'Email ID',
          'User name',
          'Organization'
        ]
      },
      {
        sno: 5,
        thirdPartyProvider: 'Sendgrid',
        purpose: 'Email communication',
        infoWeShare: ['Email ID']
      },

      {
        sno: 6,
        thirdPartyProvider: 'Cloud AMQP Messaging server',
        purpose: 'Push notifications',
        infoWeShare: ['User ID']
      },

      {
        sno: 7,
        thirdPartyProvider: 'Deepgram',
        purpose: 'Analysis',
        infoWeShare: ['Recordings']
      },

      {
        sno: 8,
        thirdPartyProvider: 'Sentry',
        purpose: 'User activities',
        infoWeShare: [
          'Browser',
          'OS',
          'Date',
          'User ID',
          'Meeting ID',
          'IP Address',
          'User Name',
          'Email'
        ]
      }
    ],
    sharePonits: [
      'We may use Push Notification Service to notify you about various events related to our Services. This message is sent to the push notification service provider, for delivery to your device.',
      'We use third-party to process the payment and/or billing information that you submit on our website to facilitate payment transactions. We require that these third parties take the appropriate organizational and technical measures to protect your personal data and traffic data and to comply with relevant laws, but we are not liable or responsible for these third-party transactions. Therefore, you should review such third-party terms and conditions before providing your payment or billing information.',
      'Also, phone numbers are shared with providers like Bandwidth.com (previously Voxbone), Vonage (previously Nexmo), and VoxValley for PSTN connectivity.',
      "Notwithstanding any of the foregoing, huddl.ai servers that are used to store your personal information are owned and hosted by Amazon Web Services, LLC, an Amazon.com company. You can read more about measures taken by Amazon to protect the security of their servers and your Personal Information from Amazon's website.",
      'We may disclose information about you if we determine that for national security, law enforcement, or other issues of public importance disclosure of the information is necessary. We may from time to time ask you to provide information about your experiences, which will be used to measure and improve quality. You are at no time under any obligation to provide any such data. Any and all information that is voluntarily submitted in feedback forms on the site or from the app that you accept to take part in is used for the purposes of reviewing this feedback and improving software, products, and websites.``We may contact you to send you an email about faults and service issues. Furthermore, we reserve the right to use email, huddl.ai software to notify you of any eventual claims related to your use of our software, website, and/or products, including without limitation claims or violation of third-party rights.',
      `Notwithstanding any of the foregoing, huddl.ai servers that are used to store your personal information are owned and hosted by Amazon Web Services, LLC, an Amazon.com company. You can read more about measures taken by Amazon to protect the security of their servers and your Personal Information from Amazon’s web site.`,
      `We may disclose information about you if we determine that for national security, law enforcement, or other issues of public importance that disclosure of information is necessary. We may from time to time ask you to provide information about your experiences, which will be used to measure and improve quality. You are at no time under any obligation to provide any such data. Any and all information which is voluntarily submitted in feedback forms on the site or from app that you accept to take part in is used for the purposes of reviewing this feedback and improving software, products and websites.`,
      `We may contact you to send you messages about faults and service issues. Furthermore, we reserve the right to use email, huddl.ai software or SMS to notify you of any eventual claims related to your use of our software, website and/or products, including without limitation claims or violation of third party rights.`
    ]
  },
  infoShare: {
    title: 'Information You Share Publicly',
    description: `You share your information as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, customize, support, and market our Services.`,
    para: [
      `Account Information - Your phone number, profile name, and photo may be available to anyone who uses our Services, although you can configure your Services settings to manage certain information available to other users.`,
      `Third-Party Providers - We work with third-party providers to help us operate, provide, improve, understand, customize, support, and market our Services. When we share information with third-party providers, we require them to use your information in accordance with our instructions and terms or with express permission from you.`,
      `Third-Party Services - When you use third-party services that are integrated with our Services, they may receive information about what you share with them. If you interact with a third-party service linked through our Services, you may be providing information directly to such a third party. Please note that when you use third-party services, their own terms and privacy policies will govern your use of those services.`
    ]
  },
  securityEncryptions: {
    title: 'Security & Encryptions',
    description_1: `We take appropriate steps to protect the personal information entrusted to us and treat it securely in accordance with this Privacy Statement. Huddl implements technical and organizational safeguards designed to protect your personal information from accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access. 
    `,
    encrptTable: [
      { sno: 1, personalData: 'User Information', type: ['Encrypted at rest'] },
      {
        sno: 2,
        personalData: 'Passwords(Stored if Single Sign-On is not configured',
        type: ['Encrypted at rest']
      },
      {
        sno: 3,
        personalData: 'Host & Usage information',
        type: ['Encrypted at rest']
      }
    ],
    description_2: `The Service encrypts User Information, Passwords, and User-Generated Information, as described above, at rest. Any data not encrypted at rest is protected by highly-secure data center protection mechanisms and operational procedures. Huddl Meetings data centers feature communication infrastructure with industry-leading performance, integration, flexibility,
    scalability, and availability.`
  },
  encryptAtRuntime: {
    title: 'Encryption at Run Time',
    description: `All communications on the huddl.ai Meetings platform occur over encrypted channels. TLS 1.2 protocol with high strength cipher suites.`
  },
  bussinessTrans: {
    title: 'Business Transitions',
    description: `All of our rights and obligations under our Privacy Policy are freely assignable by us to any of our affiliates, in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner.`
  },
  deleteAccountPolicy: {
    title: 'Deleting Your Account',
    description: 'The process of deleting a user’s own account is as follows:',
    deleteAccountTable: [
      {
        steps: [
          'The user requests Huddl to delete their account from the app (web or mobile)',
          {
            title: 'Huddl backend generates a code and sends the same to the user in an email.',
            children: ['The email is sent to the email id tied to the account.', 'The code expires after a certain period (10 minutes as of today).']
          },
          'The requester has to copy and paste the code in order to complete the deletion process.'
        ],
        deletedData: [
          'Personal information like email id, name, phone number.',
          'Settings, preferences, and other user-level configurations.',
          'Calendar integrations of linked Google or Office365 accounts',
          'Access and Refresh tokens of linked accounts'
        ],
        nonDeletingData:[
          {
            title: 'Meetings created or attended by the requester and the data related to those meetings are not deleted',
            children: ['Why? Because - The meetings and meeting-related information (viz. notes, tasks, agendas, transcriptions, etc.) may potentially be shared with other participants of the meeting.']
          },
          'Logs, stats, and telemetry related to the meetings attended by a user.'
        ]
      }
    ]
  },
  infoManage: {
    title: 'Managing Your Information',
    description: `If you would like to manage, change, limit, or delete your information, we allow you to do that through the following tools:`,
    para: [
      `Services Settings - You can change your Services settings to manage certain information available to other users.`,
      // `Deleting your huddl.ai Account - You may delete your huddl.ai account at any time (including if you want to revoke your consent to our use of your information) using our in-app delete my account feature. When you delete your huddl.ai account, your undelivered messages are deleted from our servers as well as any of your other information we no longer need to operate and provide our Services. Be mindful that if you only delete our Services from your device without using our in-app delete my account feature, your information may be stored with us for a longer period. Please remember that when you delete your account, it does not affect the information other users have relating to you, such as their copy of the messages you sent them.`
    ]
  },
  dataRetentionPolicy: {
    title: 'Data Retention Policy',
    description: 'huddl.ai user data is retained for as long as the user remains active. The huddl.ai platform provides active users with the option to delete their account, in turn delete their personal data. Users’ personal data (as mentioned in privacy policy document) is removed from live databases upon deletion of the user account. User account can be self deleted or can be deleted by admin of the team/organisation. Information stored in replicas, snapshots, and backups are not actively purged but instead naturally ages itself from the repositories as the data lifecycle occurs. huddl.ai retains certain data that user had shared with others like meetings, meeting-related info, notes, tasks, moments, etc. huddl.ai also retains logs and related metadata in order to address security, compliance, or statutory needs.'
  },

  dataArchivingAndRemovalPolicy: {
    title: 'Data Archiving and Removal Policy',
    description: 'The huddl.ai platform provides active users with the option to delete their account, in turn their personal data. Users’ personal data (as mentioned in privacy policy document) is removed from live databases upon deletion of the user account. User account can be self deleted or can be deleted by admin of the team/organisation. Personal data includes but not limited to email id, name, phone number, settings, preferences, other user-level configurations, calendar integrations of linked Google or Office365 accounts, access tokens, refresh tokens of all linked accounts are deleted immediately on account deletion. Information stored in replicas, snapshots, and backups are not actively purged but instead naturally ages itself from the repositories as the data lifecycle occurs. huddl.ai retains certain data that user had shared with others like meetings, meeting-related info, notes, tasks, moments, etc. huddl.ai also retains logs and related metadata in order to address security, compliance, or statutory needs.'
  },
  dataStoragePolicy: {
    title: 'Data Storage Policy',
    description: 'huddl.ai platform is hosted with AWS cloud infrastructure. AWS supports security standards and compliance certifications including PCI-DSS, HIPAA/HITECH, FedRAMP, GDPR, FIPS 140-2, and NIST 800-171, among others. Access to the data stores are limited to a small set of huddl.ai employees based on their roles, and access is limited to the individuals who need it in order to respond to customer support and related requests.'
  },
  dataAccess: {
    title: 'Right of Access and Data Subject Access Requests',
    description: `Under data protection legislation, any individual has the right to access the information that an organisation holds about them. Accessing personal data in this way is known as making a subject access request. (Subject access requests are different to requests submitted under FOI legislation, which relate to information about the organisation itself)`,
    accessPoints: [
      `to be informed whether your personal data are being processed by Huddl.`,
      `to be sent a copy of your personal data (normally as of the date of receipt of your request), subject to any applicable exemptions and the removal of other people’s personal data as appropriate.`
    ]
  },

  infoProtection: {
    title: 'Law and Protection',
    description: `We may collect, use, preserve, and share your information if we have a good-faith belief that it is reasonably necessary to:`,
    para: [
      `Respond pursuant to applicable law or regulations, to legal process, or to government requests.`,
      `Enforce our Terms and any other applicable terms and policies, including for investigations of potential violations;`,
      `Detect, investigate, prevent, and address fraud and other illegal activity, security, or technical issues; or`,
      `Protect the rights, property, and safety of our users, huddl.ai, affiliated companies, or others.`
    ]
  },
  infoOperations: {
    title: 'Global Operations',
    description: `You agree to our information practices, including the collection, use, processing, and sharing of your information as described in this Privacy Policy, as well as the transfer and processing of your information to the United States and other countries globally where we have or use facilities, service providers, or partners, regardless of where you use our Services. You acknowledge that the laws, regulations, and standards of the country in which your information is stored or processed may be different from those of your own country.`
  },
  infoUpdate: {
    title: 'Updates to this Policy',
    description: `From time to time, as our services evolve, we may update this Policy. We will provide you notice to this Privacy Policy, as appropriate, and update the “Last Modified” date at the top of this Privacy Policy. Your continued use of our Services confirms your acceptance of our Privacy Policy, as amended. If you do not agree to our Privacy Policy, as amended, you must stop using our Services. Please review our Privacy Policy from time to time.`
  },
  infoContact: {
    title: 'Contact Us',
    para: [
      `In the event you read this Privacy Policy in any language other than English, you agree that in the event of any discrepancies, the English version shall prevail.`
    ]
  }
};

class PrivacyPolicy extends Component {
  render() {
    return (
      <Container>
        <Category>
          <HeaderLarge>{policyData.heading.title}</HeaderLarge>
          <Description>{policyData.heading.description}</Description>
        </Category>

        <Category>
          <Header>{policyData.infoCollect.title}</Header>
          <Description>{policyData.infoCollect.description}</Description>
        </Category>

        <Category>
          <Header>{policyData.infoProvide.title}</Header>
          <UnorderdList>
            {policyData.infoProvide.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>

        <Category>
          <Header>{policyData.infoCollectedAuto.title}</Header>
          <UnorderdList>
            {policyData.infoCollectedAuto.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>

        <Category>
          <Header>{policyData.infoThirdParty.title}</Header>
          <UnorderdList>
            {policyData.infoThirdParty.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>

        <Category>
          <Header>{policyData.infoUse.title}</Header>
          <Description>{policyData.infoUse.description}</Description>
          <UnorderdList>
            {policyData.infoUse.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>

        <Category>
          <Header>{policyData.infoDisclose.title}</Header>
          <Description>{policyData.infoDisclose.description_1}</Description>
          <OrderedList>
            {policyData.infoDisclose.usePoints.map(item => (
              <List>{item}</List>
            ))}
          </OrderedList>
          <Description>{policyData.infoDisclose.description_2}</Description>
          {/* discloseTable data */}
          <TableBlock>
            <DataTable>
              <TableRow>
                <TableHead>SNo</TableHead>
                <TableHead>Third-Party Provider</TableHead>
                <TableHead>Purpose</TableHead>
                <TableHead>Data/Info we share</TableHead>
              </TableRow>
              {policyData.infoDisclose.discloseTable.map(
                (
                  { sno, thirdPartyProvider, purpose, infoWeShare } = {},
                  index
                ) => (
                  <TableRow key={`discloseTable_${index}`}>
                    <TableData alignCenter>{sno}</TableData>
                    <TableData>{thirdPartyProvider}</TableData>
                    <TableData>{purpose}</TableData>
                    <TableData>
                      <UnorderdList>
                        {infoWeShare.map(item => (
                          <List key={`${item}_${index}_dt`}>{item}</List>
                        ))}
                      </UnorderdList>
                    </TableData>
                  </TableRow>
                )
              )}
            </DataTable>
          </TableBlock>
          <UnorderdList>
            {policyData.infoDisclose.sharePonits.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>

        <Category>
          <Header>{policyData.infoShare.title}</Header>
          <Description>{policyData.infoShare.description}</Description>
          <UnorderdList>
            {policyData.infoShare.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>
        <Category>
          <Header>{policyData.securityEncryptions.title}</Header>
          <Description>
            {policyData.securityEncryptions.description_1}
          </Description>
          <TableBlock>
            <DataTable>
              <TableRow>
                <TableHead>Sno</TableHead>
                <TableHead>Personal data</TableHead>
                <TableHead>Encryption type</TableHead>
              </TableRow>
              {policyData.securityEncryptions.encrptTable.map(
                ({ sno, personalData, type } = {}, index) => (
                  <TableRow key={`encrypt_${index}`}>
                    <TableData>{sno}</TableData>
                    <TableData>{personalData}</TableData>
                    <TableData>
                      <UnorderdList>
                        {type.map(item => (
                          <List key={`${item}_${index}`}>{item}</List>
                        ))}
                      </UnorderdList>
                    </TableData>
                  </TableRow>
                )
              )}
            </DataTable>
          </TableBlock>
          <Description>
            {policyData.securityEncryptions.description_2}
          </Description>
        </Category>
        <Category>
          <Header>{policyData.encryptAtRuntime.title}</Header>
          <Description>{policyData.encryptAtRuntime.description}</Description>
        </Category>
        <Category>
          <Header>{policyData.bussinessTrans.title}</Header>
          <Description>{policyData.bussinessTrans.description}</Description>
        </Category>

        <Category>
          <Header>{policyData.deleteAccountPolicy.title}</Header>
          <Description>{policyData.deleteAccountPolicy.description}</Description>
          <TableBlock>
            <DataTable>
              <TableRow>
                <TableHead>Steps to be followed for deleting an account</TableHead>
                <TableHead>What information is deleted?</TableHead>
                <TableHead>What information is not deleted?</TableHead>
              </TableRow>
              {policyData.deleteAccountPolicy.deleteAccountTable.map(
                (
                  {steps, deletedData, nonDeletingData } = {},
                  index
                ) => (
                  <TableRow key={`deleteAccountPolicyTable_${index}`}>
                    <TableData>
                    <UnorderdList>
                        {steps.map(item => (
                          <>
                           {(typeof(item) === 'object') ? 
                            <>
                            <List key={`${item.title}_${index}_dt`}>{item.title}</List>
                              {item.children.map(innerItem => (
                                <List key={`${innerItem}_${index}_dt`} nested>{innerItem}</List>
                              ))}
                            </>:
                            <List key={`${item}_${index}_dt`}> {item}</List>
                           }
                          </>
                          
                        ))}
                      </UnorderdList>
                      </TableData>
                    <TableData>
                      <UnorderdList>
                        {deletedData.map(item => (
                          <List key={`${item}_${index}_dt`}>{item}</List>
                        ))}
                      </UnorderdList>
                    </TableData>
                    <TableData>
                      <UnorderdList>
                       
                        {nonDeletingData.map(item => (
                          <>
                           {(typeof(item) === 'object') ? 
                            <>
                            <List key={`${item.title}_${index}_dt`}>{item.title}</List>
                              {item.children.map(innerItem => (
                                <List key={`${innerItem}_${index}_dt`} nested>{innerItem}</List>
                              ))}
                            </>:
                            <List key={`${item}_${index}_dt`}> {item}</List>
                           }
                          </>
                          
                        ))}
                      </UnorderdList>
                    </TableData>
                  </TableRow>
                )
              )}
            </DataTable>
          </TableBlock>
        </Category>

        <Category>
          <Header>{policyData.dataRetentionPolicy.title}</Header>
          <Description>{policyData.dataRetentionPolicy.description}</Description>
        </Category>

         <Category>
          <Header>{policyData.dataArchivingAndRemovalPolicy.title}</Header>
          <Description>{policyData.dataArchivingAndRemovalPolicy.description}</Description>
        </Category>

         <Category>
          <Header>{policyData.dataStoragePolicy.title}</Header>
          <Description>{policyData.dataStoragePolicy.description}</Description>
        </Category>

         <Category>
          <Header>{policyData.dataAccess.title}</Header>
          <Description>{policyData.dataAccess.description}</Description>
          <Description>You are entitled:</Description>
          <UnorderdList>
            {policyData.dataAccess.accessPoints.map(item=><List>{item}</List>)}
            <List>to be sent certain information about your personal data
            Your subject access request to Huddl may be submitted in whatever format you wish and emailed to{' '} 
            <HyperLink href="mailto:security@gohuddl.com">
              security@gohuddl.com
            </HyperLink>
            </List>
          </UnorderdList>
        </Category>

        {/* <Category>
          <Header>{policyData.infoManage.title}</Header>
          <Description>{policyData.infoManage.description}</Description>
          <UnorderdList>
            {policyData.infoManage.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category> */}

        <Category>
          <Header>{policyData.infoProtection.title}</Header>
          <Description>{policyData.infoProtection.description}</Description>
          <UnorderdList>
            {policyData.infoProtection.para.map(item => (
              <List>{item}</List>
            ))}
          </UnorderdList>
        </Category>
        <Category>
          <Header>{policyData.infoOperations.title}</Header>
          <Description>{policyData.infoOperations.description}</Description>
        </Category>
        <Category>
          <Header>{policyData.infoContact.title}</Header>
          <Description>
            If you have questions about our Privacy Policy, please contact us at{' '}
            <HyperLink href="mailto:info@gohuddl.com">
              info@gohuddl.com
            </HyperLink>
          </Description>
          {policyData.infoContact.para.map(item => (
            <Description>{item}</Description>
          ))}
        </Category>
      </Container>
    );
  }
}

export default PrivacyPolicy;
