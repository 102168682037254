const faqs = [
  {
    id: 1,
    question: 'How do I sign-up with huddl.ai for Microsoft Teams?',
    answer: `Signing up with huddl.ai for Microsoft Teams is very easy. Once you install the huddl.ai application in your Microsoft Teams account from the app store, you will have to open the huddl.ai application.
    Here you will be asked to sign up if you are new to huddl.ai, register using your Microsoft Teams account details.`
  },
  {
    id: 2,
    question: 'How do I access huddl.ai inside my Microsoft Teams meeting?',
    answer: `
    When you are inside the Microsoft Teams meeting<br>
    <ol>
    <li>Click the “ + ” button on the top of the meeting section</li>
    <li>In the drop down window search for huddl.ai, select it and save it.</li>
    </ol>
    `
  },
  {
    id: 3,
    question: 'How do I add huddl.ai to a new meeting?',
    answer:
      'You can only add huddl.ai to the existing meeting by clicking on the “ + “ icon. So, incase of a new meeting create the meeting by inviting the participants and save the meeting. Once saved go back and edit the meeting and add huddl.ai to that meeting.'
  },
  {
    id: 4,
    question: 'How do I add huddl.ai to a existing meeting?',
    answer: `There are 3 ways to add huddl.ai to a meeting. Make sure you have already installed huddl.ai in your Microsoft Teams app.<br>
      <br>
      <b>Way 1</b><br>
      Go to Microsoft Teams app directory<br>
      <ol>
      <li>Click on Search and type huddl.ai</li>
      <li>Once you find huddl.ai app select it</li>
      <li>On the modal window click the down arrow and select Add to a meeting</li>
      <li>Search and select the meeting and click Set up a tab.</li>
      </ol>
      <br>
      <b>Way 2</b><br>
      Go to the Microsoft Teams calendar<br>
      <ol>
      <li>Click the existing meeting and choose edit option</li>
      <li>Click the “ + ” button on the top</li>
      <li>In the modal window search for huddl.ai, select it and save.</li>
      </ol>
      <br>
      <b>Way 3</b><br>
      During your Microsoft Teams meeting<br>
      <ol>
      <li>Click the “ + ” Apps button on the top of the meeting section</li>
      <li>In the drop down window search for huddl.ai, select it and save it.</li>
      </ol>
      `
  },
  {
    id: 5,
    question:
      'I have a huddl.ai account and had used Google to create it. How do I use huddl.ai with Microsoft Teams now?',
    answer:
      'Google flow is currently not supported by Microsoft Teams, please signup with your Microsoft Teams email ID at huddl.ai and then Sign in into huddl.ai using your Microsoft Teams account.'
  },
  {
    id: 6,
    question:
      'Which type of account can be used with huddl.ai app on Microsoft Teams?',
    answer:
      'Currently you can only use your Microsoft work account to sign up into huddl.ai and use in your Microsoft Teams meeting.'
  }
];

export default faqs;
