import React from 'react';
import { DescriptionsOuterContainer } from './styles';
import { descriptionsData } from './config';
import IndividualDescription from './IndividualDescription';
import TransitionContainer from '../../../Shared/TransitionContainer';

const Descriptions = ({
  windowHeight,
  distFromTop,
  imageContainerIsVisible,
  scrollY,
  scrolledToBotttom
}) => (
  <DescriptionsOuterContainer className="outer-container">
    {descriptionsData.map(
      (
        {
          heading,
          title,
          description,
          imageUrl,
          backgroundImageUrl,
          absoluteOffsets,
          imageContainerOffsets,
          textContainerOffsets,
          outerContainer,
          path
        },
        i
      ) => {
        return (
          <TransitionContainer
            key={title}
            scrolledToBotttom={scrolledToBotttom}
          >
            <IndividualDescription
              i={i}
              heading={heading}
              title={title}
              description={description}
              imageUrl={imageUrl}
              backgroundImageUrl={backgroundImageUrl}
              absoluteOffsets={absoluteOffsets}
              imageContainerOffsets={imageContainerOffsets}
              textContainerOffsets={textContainerOffsets}
              outerContainer={outerContainer}
              distFromTop={distFromTop}
              windowHeight={windowHeight}
              imageContainerIsVisible={imageContainerIsVisible}
              scrollY={scrollY}
              scrolledToBotttom={scrolledToBotttom}
              path={path}
            />
          </TransitionContainer>
        );
      }
    )}
  </DescriptionsOuterContainer>
);

export default Descriptions;
