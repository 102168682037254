import React, { Component } from 'react';
import { Container, Title, Description } from './styles';

class TextContainer extends Component {
  render() {
    const { title, description, className, containerRef, style } = this.props;
    return (
      <Container style={style} ref={containerRef} className={className}>
        <Title className="text-container-title">{title}</Title>
        {Array.isArray(description) ? (
          description.map(text => (
            <Description className="text-container-description">
              {text}
            </Description>
          ))
        ) : (
          <Description className="text-container-description">
            {description}
          </Description>
        )}
      </Container>
    );
  }
}

export default TextContainer;
