import { TableContainer, TableCell } from '@material-ui/core';
import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import { GetStartedInner } from '../../Zoom/ZoomDocs/styles';

const commonStyles = `color: #252525; font: 1.6rem ${Roboto}; letter-spacing: 0; line-height: 25px; text-align: none;`;

export const DescriptionText = styled.div`
  ${commonStyles};
  padding: 2em 0;
`;

export const Slide = styled.img``;
export const GetStartedInnerDiv = styled(GetStartedInner)`
  margin-top: 10rem;
`;
export const StyledTableContainer = styled(TableContainer)`
  && {
    width: 65%;
  }
`;
export const StyledCommand = styled(TableCell)`
  && {
    ${commonStyles};
    color: #705ddc;
  }
`;
export const StyledTableHeading = styled(TableCell)`
  && {
    ${commonStyles};
    font-weight: 500;
    padding: 0;
  }
`;
export const StyledTableCell = styled(TableCell)`
  && {
    ${commonStyles};
  }
`;
