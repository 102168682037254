import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  FooterContainer,
  FooterDataContainer,
  FooterLinksContainer,
  InidividualFooterLinkColumn,
  FooterLink,
  FooterBrandContainer,
  AllRightsText
} from './styles';

import { HuddlLogoWithTextSvg } from '../Shared/Icons/HuddlLogoWithText';

const Footer = ({ footerData, location }) => {
  if (location.pathname.includes('techmahindra')) return null;
  return (
    <FooterContainer>
      <FooterDataContainer>
        <FooterBrandContainer>
          <HuddlLogoWithTextSvg id={3} fillColor="#f5f3f9" />
          <AllRightsText>
            &copy; {new Date().getFullYear()}, Huddl, Inc. All Rights Reserved.
          </AllRightsText>
        </FooterBrandContainer>
        <FooterLinksContainer>
          {footerData.map(({ title, links }) => (
            <InidividualFooterLinkColumn key={title}>
              {links.map(({ title, to }) => (
                <FooterLink key={title} to={to}>
                  {title}
                </FooterLink>
              ))}
            </InidividualFooterLinkColumn>
          ))}
        </FooterLinksContainer>
      </FooterDataContainer>
    </FooterContainer>
  );
};

export default withRouter(Footer);
