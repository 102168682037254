import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  padding: 1rem;
  color: #fff;
  border-radius: 0.3rem;
  background-color: purple;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 500 1.5rem/2rem 'Roboto';
  width: 12rem;
  margin: 1rem;
  border-radius: 3px;
`;

export const InfoBlock = styled.div`
  padding: 0.5rem 2rem;
  width: 100%;
  font-size: 1.1rem;
`;

export const LeftPanel = styled.div`
  padding: 2rem;
  background: #eee;
  height: 80vh;
  margin-top: 9vh;
`;

export const RightPanel = styled.div`
  margin-top: 10vh;
  flex: 1;
`;

export const DivInput = styled.div`
  font-size: 1.3rem;
  label {
    display: block;
  }
  input,
  select {
    padding: 0.6rem 0.5rem;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #fff;
  }

  select {
    width: 18.9rem;
  }

  margin: 1rem;
`;

export const IframeBlock = styled.div`
  padding: 0 2rem;
`;
