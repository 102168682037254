import styled from 'styled-components';
import { Roboto } from '../../../Shared/fonts';
import {
  BannerContainer,
  BannerTextContainer,
  BannerTitle,
  BannerDescription
} from '../../../Banner/styles';
import { Link } from 'react-router-dom';

export const JobDescriptionBannerContainer = styled(BannerContainer)`
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  padding-top: 7rem;
  padding-bottom: 5rem;
  position: fixed;
  width: 100%;
  @media screen and (min-width: 680px) {
    padding: 6rem 13rem 0 10rem;
    height: 20rem;
  }
`;

export const JobDescriptionTextContainer = styled(BannerTextContainer)`
  align-items: flex-start;
`;

export const ViewAllJobsLink = styled(Link)`
  && {
    text-decoration: none;
    color: #2c2c2c;
    a:hover,
    a:visited {
      text-decoration: none;
      color: #2c2c2c;
    }
  }
`;

export const ViewAllJobsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const ArrowImg = styled.img``;

export const ViewAllJobsText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

export const JobDescriptionTitle = styled(BannerTitle)`
  text-align: left;
  font: 300 3rem ${Roboto};
  color: #000000;
  @media screen and (min-width: 644px) {
    font-size: 3rem;
  }
`;

export const JobDescriptionLocation = styled(BannerDescription)`
  color: #000000;
  width: 100%;
  font: 300 1.6rem ${Roboto};
  text-align: left;
  margin-top: 0;
  @media screen and (min-width: 644px) {
    font-size: 1.6rem;
  }
`;

export const ApplyNowButton = styled.button`
  height: 4rem;
  width: 14rem;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 5px;
  background: #7b47ef;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-in;
  &:hover {
    background: #7143d7;
  }
`;
