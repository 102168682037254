const descriptionList = [
  {
    heading: 'huddl.ai for Slack can be used to:',
    list: [
      `Create instant meetings on Slack`,
      `Gather intelligent agenda suggestions for any meeting`,
      `View a suggested list of actions items`,
      `Keep a track of all the meetings`
    ],
    subSections: []
  },
  {
    tableHeading: 'huddl.ai for Slack commands:',
    table: [
      {
        sno: '1',
        description: 'Show list of commands',
        command: '/huddl'
      },
      {
        sno: '2',
        description: 'Create an instant meeting',
        command: '/huddl create meeting "title"'
      },
      {
        sno: '3',
        description: 'All meetings for the day',
        command: '/huddl all meetings'
      },
      {
        sno: '4',
        description: 'List down my tasks (Action items)',
        command: '/huddl my tasks'
      },
      {
        sno: '5',
        description: 'Suggest agendas for a meeting',
        command: '/huddl suggest agendas'
      }
    ]
  }
];

export default descriptionList;
