import styled from 'styled-components';
import TextContainer from '../../../../Shared/TextContainer';
import Gallery from '../../../../Shared/Gallery';
import { Roboto } from '../../../../Shared/fonts';

export const TeamGalleryAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
`;

export const TeamTextContainer = styled(TextContainer)`
  margin-top: 3rem;
  height: fit-content;
  .text-container-title {
    font: 300 3rem ${Roboto};
    color: #252525;
    letter-spacing: -0.53px;
    line-height: 35px;
  }

  .text-container-description {
    font: 300 1.8rem ${Roboto};
    @media screen and (min-width: 800px) {
      width: 85rem;
    }
  }
`;
export const TeamGalleryComponent = styled(Gallery)`
  border-bottom: none;
  height: fit-content;
`;
