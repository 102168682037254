import React from 'react';
// import { GalleryTextContainer } from '../Main/styles';
import LeadershipBottomBanner from '../BottomPortion/BottomBanner';
import TransitionContainer from '../../../Shared/TransitionContainer';
import {
  BottomPortionContainer
  // InvestorsContainer,
  // InvestorImagesContainer,
  // InvestorImg,
  // PropelInvestorImg
} from './styles';
// import imagePropel from '../../../../assets/Propel.svg';
// import imageBain from '../../../../assets/BainCapitalVentures.svg';
// import imageBloomberg from '../../../../assets/BloombergBeta.svg';

const BottomPortion = ({ containerRef, ratio, scrolledToBotttom }) => {
  const multiplier = Math.min(ratio * (ratio / 2), 1);
  const opacityAmount = multiplier * 1;
  const opacity = `${opacityAmount}`;

  return (
    <BottomPortionContainer
      style={{ opacity: scrolledToBotttom ? '1' : opacity }}
      ref={containerRef}
    >
      {/* <InvestorsContainer>
        <GalleryTextContainer
          title="Our investors"
          description="We're backed by a group of experienced investors who support our long-term success. Among them are the best venture funds and individuals."
        />
        <InvestorImagesContainer>
          <InvestorImg src={imageBain} />
          <PropelInvestorImg src={imagePropel} />
          <InvestorImg src={imageBloomberg} />
        </InvestorImagesContainer>
      </InvestorsContainer> */}
      <TransitionContainer>
        <LeadershipBottomBanner scrolledToBotttom={scrolledToBotttom} />
      </TransitionContainer>
    </BottomPortionContainer>
  );
};

export default BottomPortion;
