import React, { Component } from 'react';
import {
  Container,
  ContactSection,
  ContactHeading,
  ContactSub,
  SupportBlock,
  SupportDiv,
  SupportTitle,
  SupportMail,
  AddressDiv,
  AddressText
} from './styles';
import LocationIcon from './Location';
import ZoomHeader from '../ZoomHeader';
import ZoomFaqsContent from './ZoomFaqsContent';
class ZoomFaqs extends Component {
  render() {
    const heading = 'Frequently Asked Questions';
    const bannerInfo = `Capture important meeting moments, add to enterprise workflows and track results`;
    return (
      <>
        <Container>
          <>
            <ZoomHeader heading={heading} info={bannerInfo} />
            <ZoomFaqsContent />
          </>
        </Container>
        <ContactSection>
          <ContactHeading>Contact Us</ContactHeading>
          <ContactSub>
            We’d love to hear from you. We are here to help.
          </ContactSub>

          <SupportBlock>
            <SupportDiv>
              <SupportTitle>General Inquiries</SupportTitle>
              <SupportMail
                href="mailto:info@huddl.ai?subject=General Inquiries"
                target="_blank"
              >
                info@huddl.ai
              </SupportMail>
            </SupportDiv>
            <SupportDiv>
              <SupportTitle>Support</SupportTitle>
              <SupportMail
                href="mailto:support@huddl.ai?subject=Support"
                target="_blank"
              >
                support@huddl.ai
              </SupportMail>
            </SupportDiv>
            <SupportDiv>
              <SupportTitle>Careers</SupportTitle>
              <SupportMail
                href="mailto:careers@huddl.ai?subject=Careers"
                target="_blank"
              >
                careers@huddl.ai
              </SupportMail>
            </SupportDiv>
          </SupportBlock>
          <AddressDiv>
            <LocationIcon />
            <AddressText>321 Castro St. Mountain View CA 94041</AddressText>
          </AddressDiv>
        </ContactSection>
      </>
    );
  }
}

export default ZoomFaqs;
