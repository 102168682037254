import React from 'react';

const ExpandIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
        stroke="gray"
      />
      <path
        d="M12 7c.295 0 .539.217.58.5l.007.087v3.825h3.826l.087.007a.587.587 0 01-.087 1.168h-3.826v3.826a.587.587 0 01-1.168.087l-.006-.087-.001-3.826H7.587l-.087-.006a.587.587 0 01.087-1.168l3.825-.001V7.587c0-.324.264-.587.588-.587z"
        fill="gray"
      />
    </g>
  </svg>
);

export default ExpandIcon;
