import React from 'react';
import {
  ContactImage,
  ContactBanner,
  StreetViewImage,
  ImageContainer,
  AddressAndContactInfoWrapper,
  ImageAndAddressWrapper,
  ContactImageContainer,
  ContactBannerContainer
} from './styles';
import { AddressText } from './Info/styles';
import Info from './Info';
import { data } from '../../Shared/BannerAndFooterData';
import { BASE_IMG_URL } from '../../Shared/Config';

const GOOGLE_MAPS_IMG = 'google-maps.png';
const CONTACT_MAIN_IMAGE = 'Contact-main-svg.svg';

const Contact = () => {
  const {
    contact: {
      banner: { title, description }
    }
  } = data;

  return (
    <>
      <ContactBannerContainer>
        <ContactBanner title={title} description={description} />
      </ContactBannerContainer>
      <ContactImageContainer>
        <ContactImage src={`${BASE_IMG_URL}/${CONTACT_MAIN_IMAGE}`} />
      </ContactImageContainer>
      <AddressAndContactInfoWrapper>
        <ImageAndAddressWrapper>
          <ImageContainer>
            <StreetViewImage src={`${BASE_IMG_URL}/${GOOGLE_MAPS_IMG}`} />
          </ImageContainer>
          <AddressText>20375, Leutar Ct, Saratoga, CA, 95070</AddressText>
        </ImageAndAddressWrapper>
        <Info />
      </AddressAndContactInfoWrapper>
    </>
  );
};

export default Contact;
