import { slackLinks } from '../docsList';
const faqs = [
  {
    id: 1,
    question: 'How to integrate huddl.ai for Slack?',
    answer: `You can integrate huddl.ai and slack by clicking the button Get huddl.ai above, or you can also visit the <a href=${slackLinks.get(
      origin
    )} target="_blank" rel="noopener noreferrer">Slack app directory</a>.
    `
  },
  {
    id: 2,
    question: 'How to use huddl.ai app in Slack?',
    answer: `After you integrate huddl.ai with slack.<br>
    <strong>Using /huddl command</strong> - Use this command to call out features like creating an instant meeting, checking out meetings for a day, pending tasks, and many more.<br>`
  },
  //!! SLACK REMINDER (Delete above and ENABLE WHEN REQUIRED)
  // {
  //   id: 2,
  //   question: 'How to use huddl.ai app in Slack?',
  //   answer: `There are 2 ways you can use huddl from your slack app, after you integrate huddl.ai with slack.<br>
  //   1. <strong>Using /huddl command</strong> - Use this command to call out features like creating an instant meeting, checking out meetings for a day, pending tasks, and many more.<br>
  //   2. <strong>Enable slack reminder for a meeting</strong> - You can get reminders from Huddl webclient before a meeting, to update task status, get notified once meeting data is analyzed, etc…`
  // },
  {
    id: 3,
    question: `How to remove huddl.ai app from Slack?`,
    answer: `You can remove / uninstall huddl in two ways: <br>
    - From huddl, in integrations click on disconnect button beside slack integration <br>
    - Directly from Slack, right click huddl app in slack and choose to open    configuration. In this page click on revoke authentication button on your name.`
  },
  {
    id: 4,
    question: 'How do I use the huddl commands to control huddl from Slack?',
    answer: `You can use the following commands on slack to make your life simpler <br>
      Show list of commands	&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;		/huddl <br>
      Create an instant meeting	&emsp;&emsp;&emsp;&emsp;&emsp;		/huddl create meeting "title"  <br>
      All meetings for the day	&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;		/huddl all meetings  <br>
      List down my tasks (Action items)	&emsp;	/huddl my tasks  <br>
      Suggest agendas for a meeting	&emsp;&emsp;	/huddl suggest agendas  <br>`
  },
  //!! SLACK REMINDER (ENABLE WHEN REQUIRED)
  // {
  //   id: 5,
  //   question:
  //     'How to enable meeting reminders on Slack for upcoming meetings from huddl.ai',
  //   answer: `Click the huddl meeting card (from huddl client) and navigate to the meeting info section by clicking the<br>
  //     “ i “ button. Turn on Slack Reminder. You will be asked to choose a channel where you want the reminder to be sent. Confirm the slack channel and click done.<br>
  //     <i>NOTE - You will find this option only if you are the organiser of that meeting.</i>
  //     `
  // },
  // {
  //   id: 6,
  //   question: 'How do I stop huddl bot from sending reminders on Slack?',
  //   answer: `Click the huddl meeting card (from huddl client) for which you want huddl bot to stop sending reminders. Navigate to the meeting info section by clicking the “ i “ button and click the toggle button off.  If you want to turn it on again just toggle the switch back again.<br>
  //     <i>NOTE - You will find this option only if you are the organiser of that meeting.</i>
  //     `
  // },
  {
    id: 7,
    question: `“Command not found”, what could be the issue?
    `,
    answer: `When you type other than huddl supported commands with /huddl, you will get <br>“Command not found” error.<br>
      For supported commands, check /huddl help command.
      `
  }
  // {
  //   id: 8,
  //   question: `Unable to visit tasks from the Slack reminder message.
  //   `,
  //   answer: `You can only visit the task to view it if you are a participant of that meeting. You can ask the organiser to add you to the meeting to visit the task.
  //     `
  // }
];

export default faqs;
