import React, { useRef } from 'react';
import descriptionList from './descriptionList';
import Slider from 'react-slick';
import {
  Body,
  GetStartedWrapper,
  GetStartedTitle,
  GetStartedList,
  GetStartedListItem,
  GettingStartedTitle,
  CircleDiv
} from '../../Zoom/ZoomDocs/styles';
import {
  DescriptionText,
  Slide,
  GetStartedInnerDiv,
  StyledTableContainer,
  StyledTableCell,
  StyledTableHeading,
  StyledCommand
} from './styles';
import {
  ButtonBase,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Paper
} from '@material-ui/core';
import NextArrow from '../../Webex/images/nextArrow.svg';
import PrevArrow from '../../Webex/images/prevArrow.svg';
import {
  slideFive,
  slideFour,
  slideOne,
  slideThree,
  slideTwo
} from './constants';

const Description = ({ isIntegration }) => {
  const slider = useRef();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Body isIntegration={isIntegration}>
      <GetStartedWrapper isIntegration={isIntegration}>
        <GettingStartedTitle>Description</GettingStartedTitle>
        <CircleDiv />
        <DescriptionText>{`huddl.ai is a next-generation meeting tool that increases user's productivity by easily getting more things done. huddl.ai for Slack lets the users keep a track of all their tasks, intelligently pulls agendas based on previous interactions, and boosts team engagement.`}</DescriptionText>
        <div style={{ bottom: '10rem' }} className="slider-arrow">
          <ButtonBase
            className="arrow-btn prev"
            onClick={() => slider.current.slickPrev()}
          >
            <img alt="next" src={PrevArrow} />
          </ButtonBase>
          <ButtonBase
            className="arrow-btn next"
            onClick={() => slider.current.slickNext()}
          >
            <img alt="next" src={NextArrow} />
          </ButtonBase>
        </div>
        <Slider {...settings} ref={slider}>
          <div>
            <Slide src={slideOne}></Slide>
          </div>
          <div>
            <Slide src={slideTwo}></Slide>
          </div>
          <div>
            <Slide src={slideThree}></Slide>
          </div>
          <div>
            <Slide src={slideFour}></Slide>
          </div>
          <div>
            <Slide src={slideFive}></Slide>
          </div>
        </Slider>
        {descriptionList.map(
          ({ heading, list, tableHeading, table }, index) => (
            <GetStartedInnerDiv key={`docsList_${index}`}>
              <GetStartedTitle>
                <CircleDiv />
                {heading}
              </GetStartedTitle>
              <GetStartedList>
                {list &&
                  list.length > 0 &&
                  list.map((point, inx) => (
                    <GetStartedListItem
                      key={`listem_item_${inx}`}
                      dangerouslySetInnerHTML={{ __html: point }}
                    />
                  ))}
              </GetStartedList>
              {index === 1 && (
                <>
                  <GetStartedTitle>
                    <CircleDiv />
                    {tableHeading}
                  </GetStartedTitle>
                  <StyledTableContainer component={Paper}>
                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableHeading align="center">
                            SNO
                          </StyledTableHeading>
                          <StyledTableHeading align="center">
                            Description
                          </StyledTableHeading>
                          <StyledTableHeading align="center">
                            Command
                          </StyledTableHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {table &&
                          table.length > 0 &&
                          table.map(({ sno, description, command }) => (
                            <TableRow
                              key={description}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <StyledTableCell component="th" scope="row">
                                {sno}
                              </StyledTableCell>
                              <StyledTableCell>{description}</StyledTableCell>
                              <StyledCommand>{command}</StyledCommand>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </>
              )}
            </GetStartedInnerDiv>
          )
        )}
      </GetStartedWrapper>
    </Body>
  );
};

export default Description;
