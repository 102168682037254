import React from 'react';
import {
  Body,
  GetStartedWrapper,
  GetStartedTitle,
  GetStartedList,
  GetStartedListItem,
  GetStartedInner,
  GettingStartedTitle,
  GettingStartedDesc,
  CircleDiv
} from '../../Zoom/ZoomDocs/styles';
import {
  SubHeading,
  SubSection,
  SubSectionTitle,
  GetStartedSubList
} from './styles';
import docsList from '../docsList';

const TeamsDocs = ({ isIntegration }) => {
  return (
    <Body isIntegration={isIntegration}>
      <GetStartedWrapper isIntegration={isIntegration}>
        {isIntegration ? (
          <>
            <GettingStartedTitle>Getting Started</GettingStartedTitle>
            <GettingStartedDesc>
              An overview about how huddl.ai works and how to get started
            </GettingStartedDesc>
          </>
        ) : null}
        {docsList.map(({ heading, list, subHeading, subSections }, index) => (
          <GetStartedInner key={`docsList_${index}`}>
            <GetStartedTitle>
              <CircleDiv />
              {heading}
            </GetStartedTitle>
            <GetStartedList>
              {list.map((point, inx) => (
                <GetStartedListItem
                  key={`listem_item_${inx}`}
                  dangerouslySetInnerHTML={{ __html: point }}
                />
              ))}
            </GetStartedList>
            {subSections && subSections.length ? (
              <>
                <SubHeading
                  style={{ marginBottom: '2em', paddingLeft: '10px' }}
                >
                  {subHeading}
                </SubHeading>
                <SubSection>
                  {subSections.map(({ title, subTitle, subList }, sIndex) => (
                    <div key={`Sublist-${sIndex}`}>
                      <SubSectionTitle>{title}</SubSectionTitle>
                      <SubHeading
                        dangerouslySetInnerHTML={{ __html: subTitle }}
                      />
                      <GetStartedSubList>
                        {subList.map((subpoint, subInx) => (
                          <GetStartedListItem
                            key={`sub_list_item_${subInx}`}
                            dangerouslySetInnerHTML={{ __html: subpoint }}
                          />
                        ))}
                      </GetStartedSubList>
                    </div>
                  ))}
                </SubSection>
              </>
            ) : null}
          </GetStartedInner>
        ))}
      </GetStartedWrapper>
    </Body>
  );
};

export default TeamsDocs;
