import React from 'react';
import queryString from 'query-string';
import { BASE_IMG_URL } from '../../Shared/Config';

// returns true for ios devices, false otherwise
const showAppStore = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
};

// returns the apple app store or google play store link based on user agent info
export const getStoreComponent = () => {
  if (showAppStore()) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://itunes.apple.com/us/app/huddl-ai/id1324897393?mt=8"
      >
        <img
          src={`${BASE_IMG_URL}/icons/App_store.svg`}
          alt="Apple App Store"
        />
      </a>
    );
  } else {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=com.gohuddl.huddlapp"
      >
        <img
          src={`${BASE_IMG_URL}/icons/Play_Store.svg`}
          alt="Google Play Store"
        />
      </a>
    );
  }
};

export const getMeetingIdFromRedirectQueryParam = redirectDecodedString => {
  if (!redirectDecodedString || !redirectDecodedString.length) {
    return;
  }

  let meetingId;
  // check if there is a query param of meeting_id in case of guest login
  const foundQuestionIndex = redirectDecodedString.indexOf('?');
  if (foundQuestionIndex > -1) {
    const searchStr = redirectDecodedString.slice(foundQuestionIndex);
    const { meeting_id } = queryString.parse(searchStr);
    if (meeting_id) {
      meetingId = meeting_id;
    }
  }

  // check if meeting id is part of url in the form meeting/<meeting_id>/motes
  const splitArr = redirectDecodedString.split('/');
  const foundMeetingIndex = splitArr.indexOf('meeting');
  if (foundMeetingIndex > -1) {
    meetingId = splitArr[foundMeetingIndex + 1];
    return meetingId && meetingId.length ? meetingId : undefined;
  }

  // return undefined for other url's
  return meetingId;
};
