import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  BannerContainer,
  BannerTextContainer,
  BannerTitle,
  BannerDescription,
  BannerBackgroundCircles,
  HighLightTag
} from './styles';

const Banner = ({
  className,
  title,
  description,
  children,
  highlight,
  features,
  isWebex,
  link
}) => {
  return (
    <BannerContainer
      hasTwoElements={title && description}
      className={className}
      id="main-banner"
    >
      <BannerBackgroundCircles top="58%" left="10%" className="banner-circle" />
      <BannerBackgroundCircles
        opacity="0.6"
        left="38%"
        className="banner-circle"
      />
      <BannerTextContainer>
        <BannerTitle className="banner-title" features={features}>
          {title}
        </BannerTitle>
        <BannerDescription className="banner-description" isWebex={isWebex}>
          {description}{' '}
          {highlight ? <HighLightTag>{highlight}</HighLightTag> : null}
        </BannerDescription>
        {link && link}
      </BannerTextContainer>
      {children}
    </BannerContainer>
  );
};

export default withRouter(Banner);
